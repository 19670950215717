export type Nullable<T> = T | null | undefined;
export type valueof<T> = T[keyof T];

export interface Option {
  label: string;
  id: string;
  value: string;
}

export enum Month {
  JANUARY = 'tammikuu',
  FEBRUARY = 'helmikuu',
  MARCH = 'maaliskuu',
  APRIL = 'huhtikuu',
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}
