import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Option } from '../../../types/commonTypes';
import { WoodMaterialType, WoodRecyclingMethod } from '../../../types/materialTypes';
import DatePickerField from '../field/DatePickerField';
import DropdownField from '../field/DropdownField';
import InputField from '../field/InputField';
import { ItemWeight, Term, VolumeSchema, VolumeType } from './volumeFormTypes';

interface useVolumeFormProps {
  control: Control<any, any>;
  facilities: string[];
  recyclingMethods: WoodRecyclingMethod[];
  itemWeights: ItemWeight[];
  woodMaterialType?: WoodMaterialType;
  onItemTypeOrAmountChange?: (value: any) => void;
}

interface getComponentProps {
  name?: string;
}

const useVolumeForm = ({
  control,
  facilities,
  recyclingMethods,
  itemWeights,
  onItemTypeOrAmountChange,
}: useVolumeFormProps) => {
  const { t } = useTranslation();
  const tyyppiOptions: Array<Option> = Object.values(VolumeType).map((type, index) => {
    return {
      id: `tyyppi${index}`,
      label: t(`woodVolumeForm.itemTypes.${type}`),
      value: type,
    };
  });
  const nimikeOptions: Array<Option> = [];
  const kappaletyyppiOptions: Array<Option> = itemWeights.map((weight, index) => {
    return {
      id: `kappaletyyppi${index}`,
      label: t(`woodVolumeForm.itemTypes.${weight.name}`),
      value: weight.name,
    };
  });
  const kumppaniOptions: Array<Option> = facilities.map((facility, index) => {
    return { id: `kumppani${index}`, label: facility, value: facility };
  });
  const kuntatoimijaOptions: Array<Option> = [];
  const hyotykayttomenetelmaOptions: Array<Option> = recyclingMethods.map((method, index) => {
    return {
      id: `hyotykayttomenetelma${index}`,
      label: t(`recyclingMethods.${method}`),
      value: method,
    };
  });
  const kausiOptions: Array<Option> = Object.values(Term).map((term, index) => {
    return { id: `kausi${index}`, label: term, value: term };
  });

  const volumeFormFields = {
    [VolumeSchema.ALKU_PVM]: {
      getComponent: (props?: getComponentProps) => (
        <DatePickerField
          name={props?.name || VolumeSchema.ALKU_PVM}
          label={t(`volumeFields.${VolumeSchema.ALKU_PVM}`)}
          control={control}
          required={true}
        />
      ),
    },
    [VolumeSchema.PAATTYMIS_PVM]: {
      getComponent: (props?: getComponentProps) => (
        <DatePickerField
          name={props?.name || VolumeSchema.PAATTYMIS_PVM}
          label={t(`volumeFields.${VolumeSchema.PAATTYMIS_PVM}`)}
          control={control}
          required={true}
        />
      ),
    },
    [VolumeSchema.TYYPPI]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.TYYPPI}
          label={t(`volumeFields.${VolumeSchema.TYYPPI}`)}
          control={control}
          required={true}
          options={tyyppiOptions}
        />
      ),
    },
    [VolumeSchema.NIMIKE]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.NIMIKE}
          label={t(`volumeFields.${VolumeSchema.NIMIKE}`)}
          control={control}
          options={nimikeOptions}
        />
      ),
    },
    [VolumeSchema.PAINO]: {
      getComponent: (props?: getComponentProps) => (
        <InputField
          name={props?.name || VolumeSchema.PAINO}
          label={t(`volumeFields.${VolumeSchema.PAINO}`)}
          control={control}
          type="number"
          required={true}
        />
      ),
    },
    [VolumeSchema.KAPPALEMAARA]: {
      getComponent: (props?: getComponentProps) => (
        <InputField
          name={props?.name || VolumeSchema.KAPPALEMAARA}
          label={t(`volumeFields.${VolumeSchema.KAPPALEMAARA}`)}
          control={control}
          type="number"
          onChange={onItemTypeOrAmountChange}
        />
      ),
    },
    [VolumeSchema.KAPPALETYYPPI]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.KAPPALETYYPPI}
          label={t(`volumeFields.${VolumeSchema.KAPPALETYYPPI}`)}
          control={control}
          options={kappaletyyppiOptions}
          onChange={onItemTypeOrAmountChange}
          required
        />
      ),
    },
    [VolumeSchema.HYOTYKAYTTOMENETELMA]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.HYOTYKAYTTOMENETELMA}
          label={t(`volumeFields.${VolumeSchema.HYOTYKAYTTOMENETELMA}`)}
          control={control}
          options={hyotykayttomenetelmaOptions}
          required={true}
        />
      ),
    },
    [VolumeSchema.LAHTOPISTE]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.LAHTOPISTE}
          label={t(`volumeFields.${VolumeSchema.LAHTOPISTE}`)}
          control={control}
          options={kumppaniOptions}
        />
      ),
    },
    [VolumeSchema.KOHDEPISTE]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.KOHDEPISTE}
          label={t(`volumeFields.${VolumeSchema.KOHDEPISTE}`)}
          control={control}
          required={true}
          options={kumppaniOptions}
          textFieldsSx={{ label: { fontWeight: 600 } }}
        />
      ),
    },
    [VolumeSchema.KUNTATOIMIJA]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.KUNTATOIMIJA}
          label={t(`volumeFields.${VolumeSchema.KUNTATOIMIJA}`)}
          control={control}
          options={kuntatoimijaOptions}
        />
      ),
    },
    [VolumeSchema.KAUSI]: {
      getComponent: (props?: getComponentProps) => (
        <DropdownField
          name={props?.name || VolumeSchema.KAUSI}
          label={t(`volumeFields.${VolumeSchema.KAUSI}`)}
          control={control}
          required={true}
          textFieldsSx={{ label: { fontWeight: 600 } }}
          options={kausiOptions}
        />
      ),
    },
    [VolumeSchema.VUOSI]: {
      getComponent: (props?: getComponentProps) => (
        <InputField
          name={props?.name || VolumeSchema.VUOSI}
          label={t(`volumeFields.${VolumeSchema.VUOSI}`)}
          control={control}
          type="number"
          required={true}
        />
      ),
    },
  };

  return {
    ...volumeFormFields,
  };
};

export default useVolumeForm;
