import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { utils, WorkSheet } from 'xlsx';
import { materialTypeIcon } from '../../common/svg/recyclingIcons';
import { materialColor } from '../../theme/theme';
import { MaterialType } from '../../types/materialTypes';
import { ExcelRow } from '../../types/multiMaterialFormTypes';
import ActionCard from '../ActionCard';
import DataFileImport from './DataFileImport';
import ExcelImportPreview from './ExcelImportPreview';

import {
  UserData,
  useSendRecyclingDataMutation,
  useSendVolumeDataMutation,
} from '../../app/services/user';
import FormSection from './FormSection';
import DualButtonSelection from './DualButtonSelection';
import { useAppSelector } from '../../app/hooks';
import { selectUserData } from '../../app/services/userSlice';
import SuccessSnackbar from '../SuccessSnackbar';

export interface FormState {
  // TODO: The ExcelRow datatype is messed up and needs a look-through.
  // Albeit, funnily enough, it doesn't seem to matter, because we don't
  // have any actual run-time format validation for excel sheets yet.
  rows: ExcelRow[];
  material: MaterialType | null;
  isVolume: boolean; // volume or recycling data
  isAccepted: boolean; // accepted or rejected
  isIncoming: boolean; // incoming or outgoing
  files: File[];
}

const ExcelImportForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [sendVolumeData] = useSendVolumeDataMutation();
  const [sendRecyclingData] = useSendRecyclingDataMutation();

  // Snackbar for showing success/failure after file import
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [importSuccessful, setImportSuccessful] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [isSaving, setIsSaving] = useState(false);
  const [formState, setFormState] = useState<FormState>({
    rows: [],
    files: [],
    material: null, // materials[0],
    // Default to which ever terminal/recycling is active.
    // If both are active, volume is a good default.
    isVolume: true, // hasTerminals,
    // These don't really even matter, as they are only counted for volume imports.
    isAccepted: true,
    isIncoming: true,
  });

  const updateFormState = (newState: Partial<FormState>) => {
    setFormState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  // Notice: At this point we know userData is not null, because of login routing.
  // Notice2: Backend database must have the exact same material definitions as we have.
  const { materials, hasRecycling, hasTerminals } = useAppSelector(selectUserData) as UserData;

  useEffect(() => {
    updateFormState({ material: materials[0], isVolume: hasTerminals });
  }, [materials, hasTerminals]);

  if (!materials || !materials.length) {
    return <Box>{t('excelImport.errors.noMaterials')}</Box>;
  }

  if (!hasRecycling && !hasTerminals) {
    return <Box>{t('excelImport.errors.noTerminals')}</Box>;
  }

  if (!formState.material) {
    return <Box>{t('excelImport.errors.noTerminals')}</Box>;
  }

  const onImport = (ws: WorkSheet, files: File[]) => {
    updateFormState({ rows: utils.sheet_to_json<ExcelRow>(ws), files });
  };

  const saveData = async () => {
    if (!formState.rows.length) {
      return;
    }
    setIsSaving(true);

    const response = formState.isVolume
      ? await sendVolumeData(createFormData())
      : await sendRecyclingData(createFormData());

    if ((response as any).error) {
      setErrorMessage((response as any).error.data?.message);
      setImportSuccessful(false);
    } else {
      setImportSuccessful(true);
      setErrorMessage('');
      updateFormState({
        rows: [],
        files: [],
        isAccepted: true,
        isIncoming: true,
      });
      window.scrollTo(0, 0);
    }
    setIsSaving(false);
    setShowSnackbar(true);
  };

  // Don't allow choosing of import type, if you only have a single type of facilities.
  const canChooseImportType = hasTerminals && hasRecycling;

  // Show different titles according to entities configured for the user.
  const materialTitle = materials.length === 1 ? 'cantChooseMaterial' : 'chooseMaterial';

  const createFormData = (): FormData => {
    const formData = new FormData();
    formData.append('rows', JSON.stringify(formState.rows));
    formData.append('material', formState.material as MaterialType);
    formData.append('isAccepted', formState.isAccepted.toString());
    formData.append('isIncoming', formState.isIncoming.toString());
    formState.files.forEach((file) => {
      formData.append('files', file);
    });

    return formData;
  };

  return (
    <Box>
      {/* 1. Valitse materiaali */}
      <FormSection title={t(`excelImport.title.${materialTitle}`)}>
        <Grid container flexWrap="nowrap" spacing={2}>
          <SuccessSnackbar
            open={showSnackbar}
            setOpen={setShowSnackbar}
            successful={importSuccessful}
            successMessage={t('excelImport.alert.success')}
            errorMessage={`${t('excelImport.alert.error')} : ${errorMessage}`}
          />
          {materials
            .filter((m) => m !== MaterialType.WOOD)
            .map((material) => {
              const isAnySelected = Boolean(formState.material);
              const selected = formState.material === material;
              const color = materialColor[material];
              return (
                <Grid item key={material}>
                  <ActionCard
                    maxWidth="12rem"
                    iconMaxHeight="7rem"
                    icon={materialTypeIcon[material]}
                    title={t(`material.shortNames.${material}`)}
                    color={color}
                    disabled={!selected && isAnySelected}
                    onClick={() => updateFormState({ material })}
                  />
                </Grid>
              );
            })}
        </Grid>
      </FormSection>

      {/* 2. Valitse keräys / kierrätys */}
      <FormSection title={t('excelImport.title.chooseFileType')} show={canChooseImportType}>
        <DualButtonSelection
          titles={{
            first: t('excelImport.buttons.collecting'),
            second: t('excelImport.buttons.recycling'),
          }}
          value={Boolean(formState.isVolume)}
          updateValue={(value: boolean) => {
            if (value !== formState.isVolume) {
              updateFormState({ isVolume: value, rows: [], files: [] });
            }
          }}
        />
      </FormSection>

      {/* 3. Valitse hylätyt/materiaali */}
      <FormSection title={t('excelImport.title.chooseVolumeType')} show={formState.isVolume}>
        <DualButtonSelection
          titles={{
            first: t('excelImport.buttons.accepted'),
            second: t('excelImport.buttons.rejected'),
          }}
          value={Boolean(formState.isAccepted)}
          updateValue={(value: boolean) => updateFormState({ isAccepted: value })}
        />
      </FormSection>

      {/* 4. Valitse suunta */}
      <FormSection
        title={t('excelImport.title.chooseVolumeDirection')}
        show={formState.isVolume}
      >
        <DualButtonSelection
          titles={{
            first: t('excelImport.buttons.incoming'),
            second: t('excelImport.buttons.outgoing'),
          }}
          value={Boolean(formState.isIncoming)}
          updateValue={(value: boolean) => updateFormState({ isIncoming: value })}
        />
      </FormSection>

      {/* 5. Lisää tiedosto */}
      <FormSection title={t('excelImport.title.addFile')}>
        <Grid container>
          {formState.isVolume && (
            <>
              <Grid item xs={12}>
                <Link href="https://portaali.blob.core.windows.net/mallipohjat/Saapuvat kuormat.xlsx">
                  {t('excelImport.templates.incoming')}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="https://portaali.blob.core.windows.net/mallipohjat/Lähtevät kuormat.xlsx">
                  {t('excelImport.templates.outgoing')}
                </Link>
              </Grid>
            </>
          )}
          {!formState.isVolume && (
            <Grid item xs={12}>
              <Link href="https://portaali.blob.core.windows.net/mallipohjat/Kierrätys.xlsx">
                {t('excelImport.templates.recycling')}
              </Link>
            </Grid>
          )}
          <Grid item xs={6} mt={3}>
            <DataFileImport onImport={onImport} files={formState.files} />
          </Grid>
        </Grid>
      </FormSection>

      {/* 6. Show preview */}
      <FormSection
        title={t('excelImport.title.preview')}
        show={Boolean(formState.rows.length)}
      >
        <ExcelImportPreview rows={formState.rows} />
      </FormSection>

      {/* 7. Save */}
      <FormSection show={Boolean(formState.rows.length)}>
        <Grid container justifyContent="center">
          <Button
            onClick={saveData}
            variant="contained"
            disabled={!formState.rows}
            sx={{ minWidth: '12em', justifySelf: 'center', height: theme.spacing(6) }}
          >
            {isSaving && (
              <CircularProgress sx={{ mr: 2, svg: { color: 'white!important' } }} size={15} />
            )}
            {t('actions.submit')}
          </Button>
        </Grid>
      </FormSection>
    </Box>
  );
};

export default ExcelImportForm;
