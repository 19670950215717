import React from 'react';
import { MaterialType } from '../../types/materialTypes';

export const cardboardIcon = (
  <svg className="card-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184 127.735">
    <path
      data-name="Path 300"
      d="M91.982 127.735a1.786 1.786 0 0 1-.865-.169l-72.31-29.887a2.387 2.387 0 0 1-1.477-2.216V53.525l-15.7-5.319a2.435 2.435 0 0 1-1.52-1.562 2.351 2.351 0 0 1 .38-2.132s17.1-22.837 17.328-23.154a2.872 2.872 0 0 1 1.266-.865L91.349.084a2.578 2.578 0 0 1 1.309 0l72.268 20.41a2.183 2.183 0 0 1 1.2.781l17.394 23.238a2.415 2.415 0 0 1 .38 2.132 2.482 2.482 0 0 1-1.52 1.562l-15.7 5.34v41.937a2.387 2.387 0 0 1-1.48 2.216l-72.268 29.866a2.588 2.588 0 0 1-.908.169zm2.385-5.952 67.5-27.9V55.15l-47.723 16.189a2.377 2.377 0 0 1-2.617-.76l-17.16-21.148v72.331zM22.1 93.88l67.5 27.9V49.452L72.438 70.6a2.382 2.382 0 0 1-1.857.886 2.3 2.3 0 0 1-.76-.127L22.1 55.172V93.88zm74.04-49.831 18.046 22.246 63.509-21.549-14.374-19.207-67.181 18.51zm-89.87.7 63.509 21.546 18.045-22.246-67.181-18.51L6.27 44.745zM28.6 22.774l63.382 17.454 63.382-17.455L91.982 4.876z"
      transform="translate(.001)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const glassIcon = (
  <svg data-name="Group 299" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.567 184">
    <path
      data-name="Path 305"
      d="M111.432 166.191h-87.4A23.564 23.564 0 0 1 .45 142.678V34.473A23.564 23.564 0 0 1 24.036 10.96h87.4a23.564 23.564 0 0 1 23.586 23.513v108.2a23.564 23.564 0 0 1-23.59 23.518zM24.036 16A18.486 18.486 0 0 0 5.53 34.446v108.232a18.469 18.469 0 0 0 18.506 18.449h87.4a18.486 18.486 0 0 0 18.506-18.449V34.473a18.486 18.486 0 0 0-18.506-18.449h-87.4z"
      transform="translate(-.45 17.809)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 306"
      d="M101.2 34.255H12.93c-3.515 0-6.37-3.394-6.37-7.555V8c0-4.183 2.856-7.55 6.37-7.55h88.27c3.514 0 6.37 3.394 6.37 7.555V26.7c.005 4.189-2.851 7.555-6.37 7.555zm-85.748-4.79h83.227c2.114 0 3.844-2.026 3.844-4.544V9.784c0-2.518-1.73-4.544-3.844-4.544H15.456c-2.114 0-3.844 2.026-3.844 4.544v15.137c0 2.518 1.73 4.544 3.844 4.544z"
      transform="translate(10.216 -.45)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 307"
      d="M111.651 10.837H7.616a2.518 2.518 0 1 1 0-5.037h104.035a2.518 2.518 0 1 1 0 5.037z"
      transform="translate(7.65 8.844)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 308"
      d="M71.35 78.525H23.986A15.128 15.128 0 0 1 8.83 63.415V48.278a2.526 2.526 0 0 1 5.052 0v15.137a10.05 10.05 0 0 0 10.077 10.046H71.35a2.518 2.518 0 1 1 0 5.037z"
      transform="translate(14.179 78.266)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 309"
      d="M11.366 54.82A2.516 2.516 0 0 1 8.84 52.3v-6.322a2.526 2.526 0 0 1 5.052 0V52.3a2.516 2.516 0 0 1-2.526 2.52z"
      transform="translate(14.196 74.27)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const metalIcon = (
  <svg data-name="Group 294" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102.222 184">
    <path
      data-name="Path 301"
      d="M50.72 54.446c-21.931 0-45.081-5.54-50.12-15.89a3 3 0 0 1 2.71-4.263 2.945 2.945 0 0 1 2.645 1.687C8.778 41.657 25.288 48.45 50.7 48.45c27.983 0 45.171-8.252 45.171-14.157 0-3.967-7.837-9.278-22.879-12.151a2.978 2.978 0 0 1-2.349-3.42 2.987 2.987 0 0 1 3.523-2.462c17.368 3.328 27.645 9.985 27.645 18.032 0 13.085-26.335 20.152-51.111 20.152z"
      transform="translate(.391 20.745)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 302"
      d="M10.682 62.946c-4.675 0-7.9-1.368-9.576-4.1-2.778-4.514-.294-11.33 7.182-19.674 6.595-7.363 16.42-15.274 27.69-22.318C47.271 9.829 58.676 4.472 68.14 1.8 78.89-1.227 86.027-.43 88.805 4.084s.294 11.33-7.182 19.674c-6.595 7.363-16.42 15.274-27.69 22.318S31.257 58.455 21.771 61.122a41.731 41.731 0 0 1-11.067 1.8zm68.57-56.97a36.289 36.289 0 0 0-9.509 1.6C60.8 10.1 49.936 15.186 39.117 21.957 28.321 28.7 18.926 36.251 12.715 43.2c-6.685 7.455-7.2 11.444-6.55 12.515s4.427 2.371 14-.342c8.944-2.53 19.808-7.614 30.626-14.385C61.59 34.245 70.985 26.7 77.2 19.746 83.882 12.291 84.4 8.3 83.746 7.23c-.361-.593-1.716-1.277-4.517-1.277z"
      transform="translate(.001 .003)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 303"
      d="M51.111 153.1C26.335 153.1 0 146.035 0 132.949V24.14h5.94c0 5.9 17.188 14.157 45.171 14.157s45.171-8.253 45.171-14.157h5.94v108.809c0 13.085-26.335 20.152-51.111 20.152zM5.94 33.965v98.984c0 5.9 17.188 14.157 45.171 14.157s45.171-8.252 45.171-14.157V33.965c-9.17 6.771-27.554 10.327-45.171 10.327s-36-3.556-45.171-10.327z"
      transform="translate(0 30.898)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 304"
      d="M40.438 76.143a117.7 117.7 0 0 1-29.745-3.556A2.994 2.994 0 0 1 8.615 68.8a2.943 2.943 0 0 1 3.568-2 111.3 111.3 0 0 0 28.277 3.37 113.681 113.681 0 0 0 26.74-2.986 2.96 2.96 0 0 1 3.523 2.1 3 3 0 0 1-2.168 3.739 118.653 118.653 0 0 1-28.1 3.123z"
      transform="translate(10.673 85.357)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const plasticIcon = (
  <svg data-name="Group 302" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.745 184">
    <path
      data-name="Path 310"
      d="M65.111 164.324c-11.2 0-25.523-.039-36.684-.079-6.478 0-11.649-.039-14.049-.039-9.483 0-14.283-6.019-14.283-17.879 0-2.006.039-5.429.1-9.756.156-13.06.429-34.912-.1-50.1-.7-20.436 2.79-29.208 5.581-36.269l.117-.315C8.583 42.851 19.529 23.025 20 22.179l4.429 2.5c-.117.2-11.239 20.377-13.913 27.123l-.137.315C7.763 58.723 4.485 66.945 5.148 86.3c.527 15.3.254 37.233.1 50.332-.059 4.288-.1 7.69-.1 9.677 0 9.067 2.673 12.765 9.21 12.765 2.42 0 7.59 0 14.069.039 19.025.059 50.85.138 54.246-.02 4.839-.236 8.156-3.521 10.147-10.051 1.366-4.465 4.664-20.573 5.6-39.318.82-16.463 0-39.652-8.547-56.685C79.2 31.738 63.14 25.188 62.984 25.129l1.834-4.779c.722.275 17.971 7.218 29.581 30.368 17.113 34.125 6.029 90.81 3.278 99.818-3.317 10.837-9.854 13.394-14.752 13.65-1.581.079-8.664.1-17.835.1z"
      transform="translate(-.003 19.676)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 311"
      d="M49.2 35.813H17.3a8.173 8.173 0 0 1-5.132-1.77 8.537 8.537 0 0 1-3.358-6.786v-7.081a8.524 8.524 0 0 1 8.49-8.536h31.9a8.524 8.524 0 0 1 8.488 8.536v7.081a8.3 8.3 0 0 1-.644 3.245 8.536 8.536 0 0 1-7.844 5.311zM17.3 16.754a3.424 3.424 0 0 0-3.415 3.4v7.081a3.419 3.419 0 0 0 1.346 2.714 3.229 3.229 0 0 0 2.049.728H49.2a3.409 3.409 0 0 0 3.142-2.164 3.249 3.249 0 0 0 .254-1.278v-7.078a3.4 3.4 0 0 0-3.415-3.4z"
      transform="translate(8.375 11.254)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 312"
      d="M45.7 28.008H12.94V7.867A7.855 7.855 0 0 1 20.784 0h17.074A7.88 7.88 0 0 1 45.7 7.867zm-27.667-5.114h22.6V7.867a2.793 2.793 0 0 0-2.751-2.754H20.8a2.78 2.78 0 0 0-2.751 2.754v15.027z"
      transform="translate(12.303)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 313"
      d="M46.755 89.079a13.886 13.886 0 0 1-8.449-2.872 14.049 14.049 0 0 1-5.464-9.343L29.8 54.954a14.187 14.187 0 0 1 2.712-10.5 13.9 13.9 0 0 1 9.269-5.507c11.317-1.3 17.132 11.88 18.693 23.563 2.01 14.987-2.361 24.861-11.669 26.415h-.059a13.519 13.519 0 0 1-1.971.157zM43.242 44a5.991 5.991 0 0 0-.839.039 8.911 8.911 0 0 0-5.854 3.521 9.1 9.1 0 0 0-1.737 6.707l3.044 21.911a9.03 9.03 0 0 0 3.493 5.96 8.826 8.826 0 0 0 6.615 1.751c9.249-1.554 8.02-16.207 7.434-20.672C54.423 55.918 50.774 44 43.223 44z"
      transform="translate(28.207 37.567)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const woodIcon = (
  <svg data-name="Group 284" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184 87">
    <path
      data-name="Path 292"
      d="M23.9 45.839H3.808a2.38 2.38 0 0 1-2.378-2.382V32.582A2.38 2.38 0 0 1 3.808 30.2H23.9a2.38 2.38 0 0 1 2.378 2.382v10.875a2.38 2.38 0 0 1-2.378 2.382zM6.164 41.075h15.361v-6.111H6.185v6.111z"
      transform="translate(1.526 32.399)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 293"
      d="M66.583 45.839H46.468a2.38 2.38 0 0 1-2.378-2.382V32.582a2.38 2.38 0 0 1 2.378-2.382h20.115a2.38 2.38 0 0 1 2.378 2.382v10.875a2.38 2.38 0 0 1-2.378 2.382zm-17.738-4.764h15.361v-6.111H48.845z"
      transform="translate(47.228 32.399)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 294"
      d="M45.253 45.839H25.138a2.38 2.38 0 0 1-2.378-2.382V32.582a2.38 2.38 0 0 1 2.378-2.382h20.115a2.38 2.38 0 0 1 2.378 2.382v10.875a2.38 2.38 0 0 1-2.378 2.382zm-17.738-4.764h15.361v-6.111H27.515v6.111z"
      transform="translate(24.38 32.399)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 295"
      d="M120.451 81.375H2.381a2.383 2.383 0 0 1 0-4.767h117.242l59.615-46.943V7.633a2.381 2.381 0 1 1 4.763 0V30.8a2.337 2.337 0 0 1-.911 1.865l-61.169 48.171a2.354 2.354 0 0 1-1.47.518z"
      transform="translate(0 5.625)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 296"
      d="M120.262 30.714H2.378a2.382 2.382 0 0 1 0-4.764h117.884a2.382 2.382 0 0 1 0 4.764z"
      transform="translate(0 27.831)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 297"
      d="M29.373 49.694H4.813a2.359 2.359 0 0 1-2.253-1.636 2.4 2.4 0 0 1 .868-2.672L65.057.456A2.423 2.423 0 0 1 66.463 0h22.473a2.4 2.4 0 0 1 2.253 1.636 2.342 2.342 0 0 1-.827 2.651L30.8 49.217a2.364 2.364 0 0 1-1.427.477zm-17.242-4.765h16.436L81.824 4.764h-14.6z"
      transform="translate(2.609)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 298"
      d="M50.683 49.7h-24.54a2.359 2.359 0 0 1-2.253-1.636 2.4 2.4 0 0 1 .868-2.672L86.387.466A2.423 2.423 0 0 1 87.793.01h22.473a2.4 2.4 0 0 1 2.253 1.636 2.342 2.342 0 0 1-.827 2.651l-59.583 44.93a2.364 2.364 0 0 1-1.427.476zm-17.242-4.761h16.435l53.278-40.165h-14.6z"
      transform="translate(25.527 .011)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
    <path
      data-name="Path 299"
      d="M72.013 49.694h-24.54a2.359 2.359 0 0 1-2.253-1.636 2.4 2.4 0 0 1 .868-2.672L107.7.456A2.423 2.423 0 0 1 109.1 0h22.473a2.4 2.4 0 0 1 2.253 1.636A2.342 2.342 0 0 1 133 4.288L73.419 49.217a2.364 2.364 0 0 1-1.427.476zm-17.242-4.765h16.435l53.257-40.165h-14.6L54.75 44.929z"
      transform="translate(48.435)"
      stroke="#fff"
      style={{ fill: '#fff' }}
    />
  </svg>
);

export const materialTypeIcon = {
  [MaterialType.CARD]: cardboardIcon,
  [MaterialType.GLASS]: glassIcon,
  [MaterialType.METAL]: metalIcon,
  [MaterialType.PLASTIC]: plasticIcon,
  [MaterialType.WOOD]: woodIcon,
};
