import { Alert, Snackbar } from '@mui/material';

interface SuccessSnackbarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  successful: boolean;
  successMessage: string;
  errorMessage: string;
}

const SuccessSnackbar = ({
  open,
  setOpen,
  successful,
  successMessage,
  errorMessage,
}: SuccessSnackbarProps) => {
  const severity = successful ? 'success' : 'error';

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={severity} variant="filled" sx={{ maxWidth: '28rem' }}>
        {successful ? successMessage : errorMessage}
      </Alert>
    </Snackbar>
  );
};

export default SuccessSnackbar;
