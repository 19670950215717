import { Grid, List, ListItem, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { read, WorkSheet } from 'xlsx';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return props.theme.palette.sptGreen.main;
  }
  if (props.isDragReject) {
    return props.theme.palette.sptPurple.main;
  }
  if (props.isFocused) {
    return props.theme.palette.primary.main;
  }
  return props.theme.palette.sptGrey.main;
};

const Dropzone = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props: any) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

interface DataImport {
  onImport: (ws: WorkSheet, files: File[]) => void;
  files: File[];
}

const DataFileImport = ({ onImport, files }: DataImport) => {
  const { t } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        const workbook = read(binaryStr, { cellDates: true });
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        onImport(ws, acceptedFiles);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
  });

  const hasFiles = Boolean(files.length);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Dropzone
            style={{
              minHeight: '8rem',
              borderRadius: '0.7rem',
              color: !hasFiles ? '#9d9d9d' : 'black',
              cursor: 'pointer',
            }}
            {...getRootProps({ isFocused, isDragAccept, isDragReject })}
          >
            <input {...getInputProps()} />
            {!hasFiles && <p>{t('excelImport.fileImport.text')}</p>}
            {hasFiles && (
              <Grid item xs={12}>
                <List>
                  {files.map((file: File) => (
                    <ListItem sx={{ pl: 0, flexDirection: 'column' }} key={file.name}>
                      <Typography>{file.name}</Typography>
                      <Typography>{file.size} bytes</Typography>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Dropzone>
        </Grid>
      </Grid>
    </>
  );
};

export default DataFileImport;
