import { Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { CreateVolumeType } from '../../app/services/user';
import { FormField } from '../form/formUtil';

const CustomizedTableCell = styled(TableCell)(() => ({
  border: 'none',
  verticalAlign: 'top',
  paddingTop: 0,
  '.MuiTypography-body1': {
    fontWeight: '400 !important',
  },
}));

const CustomiedTableHeadCell = styled(TableCell)(() => ({
  fontSize: '0.9rem',
  fontWeight: 600,
}));

const CustomizedTableContainer = styled(Paper)(() => ({
  '.spt-logo': {
    width: '48px',
  },
}));

const CellGrid = styled(Grid)(() => ({
  alignItems: 'flex-start!important',
  flexDirection: 'column',
}));

const CellValue = ({ children, sx }: { children: any; sx?: any }) => (
  <CellGrid item sx={sx}>
    <FormField>
      <Typography
        sx={{
          paddingBottom: 0.5,
          borderRadius: 1,
          textAlign: !children ? 'center' : 'inherit',
          width: children ? 'inherit' : '5rem',
        }}
        whiteSpace="nowrap"
      >
        {children || '-'}
      </Typography>
    </FormField>
  </CellGrid>
);

const MAX_ROW_DISPLAY_COUNT = 5;

const ExcelImportPreview = (props: CreateVolumeType) => {
  const theme = useTheme();
  const { rows } = props;

  if (!rows.length) {
    return null;
  }

  const repr = (value?: any): string => {
    return typeof value === 'undefined'
      ? '-'
      : value instanceof Date
      ? moment(value).add(1, 'days').format('DD.MM.YYYY')
      : String(value);
  };

  const columnNames = Object.keys(rows[0]);

  const titles = columnNames.map((columnName: string, i) => (
    <CustomiedTableHeadCell key={i}>{columnName}</CustomiedTableHeadCell>
  ));

  const rowCount = rows.length;
  const displayRows = rows.slice(0, MAX_ROW_DISPLAY_COUNT);

  return (
    <>
      <CustomizedTableContainer>
        <Table sx={{ height: '1px' }} aria-label="excel-import-table">
          <TableHead>
            <TableRow>{titles}</TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ height: theme.spacing(2) }}></TableRow>
            <TableRow>
              {columnNames.map((columnName: string) => (
                <CustomizedTableCell key={`table-cell-${columnName}`}>
                  {displayRows.map((row: any, rowIndex: number) => {
                    return (
                      <CellValue key={`table-cell-${columnName}-${rowIndex}`}>
                        {repr(row[columnName])}
                      </CellValue>
                    );
                  })}
                </CustomizedTableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </CustomizedTableContainer>
      {`Rivejä näytetään ${Math.min(rowCount, MAX_ROW_DISPLAY_COUNT)} / ${rowCount}`}
    </>
  );
};

export default ExcelImportPreview;
