import { Box, CircularProgress, Container, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();
  return (
    <Container sx={{ textAlign: 'center' }}>
      <Typography variant="h3" mt={6}>
        {t('common.loading')}
      </Typography>
      <Box mt={3}>
        <CircularProgress />
      </Box>
    </Container>
  );
};

export default Loading;
