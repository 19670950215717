import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationFi from './locales/fi/translation';
import translationEn from './locales/en/translation';
import translationSv from './locales/sv/translation';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  fi: { translation: translationFi },
  en: { translation: translationEn },
  sv: { translation: translationSv },
};

i18n.use(initReactI18next).init({
  resources,
  lng: window.localStorage.getItem('lang') ?? 'fi',
  fallbackLng: 'fi',
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
