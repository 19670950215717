import React from 'react';
import Box from '@mui/material/Box';

interface DisabledOverlayProps {
  children: React.ReactNode;
  visible?: boolean;
}

const Overlay: React.FC<DisabledOverlayProps> = ({ children, visible = false }) => {
  return (
    <Box position="relative" width="100%" height="100%">
      {children}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0,0,0,0.45)',
          opacity: Number(visible),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          transition: 'opacity 0.2s ease-in-out',
        }}
      />
    </Box>
  );
};

export default Overlay;
