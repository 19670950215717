import { Box, CardActionArea, Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router';
import { UnstyledLink } from '../elements/CustomElements';
import Overlay from './Overlay';

export const CardWrapper = styled(Card, {
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'small' && prop !== 'maxWidth' && prop !== 'minWidth',
})<{ color: string; small?: boolean; maxWidth: string; minWidth: string }>(
  ({ theme, color, small, maxWidth, minWidth }) => ({
    maxWidth: small ? 'initial' : maxWidth,
    width: small ? theme.spacing(8) : minWidth,
    // FIXME: Typing mess
    backgroundColor: (theme.palette as any)[color].main,
    marginRight: theme.spacing(small ? 2 : 0),
  }),
);

const CardIcon = styled(CardMedia, {
  shouldForwardProp: (prop) => prop !== 'small' && prop !== 'maxHeight',
})<{ small?: boolean; maxHeight: string }>(({ theme, small, maxHeight }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(small ? 0.5 : 3),
  maxHeight,
  svg: {
    '&.excel-icon': { width: theme.spacing(15) },
    '&.card-icon': { width: small ? theme.spacing(3.5) : 'initial' },
    width: theme.spacing(small ? 3 : 18),
    aspectRatio: '1/1',
    height: small ? theme.spacing(3) : 'auto',
    color: 'white',
  },
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'small',
})<{ small?: boolean; component?: string }>(({ theme, small }) => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  fontSize: small ? '0.5rem' : '1.3rem',
  lineHeight: small ? '0.5rem' : '1.3rem',
  height: small ? 'auto' : theme.spacing(3),
  svg: {
    marginLeft: theme.spacing(1),
  },
}));

const CardContentWrapper = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'small',
})<{ small?: boolean; component?: string }>(({ theme, small }) => ({
  backgroundColor: 'white',
  margin: theme.spacing(small ? 0.5 : 2),
  padding: '0 !important',
  height: theme.spacing(5),
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '85%',
  borderRadius: theme.spacing(0.5),
}));

interface ActionCardProps {
  icon: JSX.Element;
  color: string;
  title: string;
  maxWidth?: string;
  minWidth?: string;
  iconMaxHeight?: string;
  href?: string;
  onClick?: () => void;
  smallSize?: boolean;
  disabled: boolean;
}

const ActionCard = ({
  color,
  icon,
  title,
  href,
  maxWidth,
  minWidth,
  iconMaxHeight,
  onClick,
  smallSize,
  disabled,
}: ActionCardProps) => {
  const { lang } = useParams();

  const content = (
    <CardActionArea disableRipple onClick={() => (onClick ? onClick() : {})}>
      <Overlay visible={disabled}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardIcon maxHeight={iconMaxHeight || '10rem'} small={smallSize}>
            {icon}
          </CardIcon>
          <CardContentWrapper small={smallSize} className="card-content">
            <Text component="div" variant="body2" small={smallSize}>
              {title}
            </Text>
          </CardContentWrapper>
        </Box>
      </Overlay>
    </CardActionArea>
  );

  return (
    <CardWrapper
      color={color}
      small={smallSize}
      className="action-card"
      maxWidth={maxWidth || '14rem'}
      minWidth={minWidth || '12rem'}
    >
      {href && (
        <Link
          component={UnstyledLink}
          to={!lang || lang === 'fi' ? href : `/${lang}${href}`}
          underline="none"
        >
          {content}
        </Link>
      )}
      {!href && content}
    </CardWrapper>
  );
};
export default ActionCard;
