import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

/*
 * Strip all styles and custom elements from an accordion, preserving only
 * the nice sliding transition for easier use.
 */
const UnstyledAccordion = ({ children, show }: { show: boolean; children: JSX.Element }) => {
  return (
    <Accordion
      disableGutters
      square
      elevation={0}
      expanded={show}
      sx={{
        background: 'none',
        '&:before': {
          // MUI Accordions add a secret border-bottom to collapsed elements. Remove that.
          display: 'none',
        },
      }}
    >
      {/* Required components for transitions to work */}
      <AccordionSummary sx={{ display: 'none' }} />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default UnstyledAccordion;
