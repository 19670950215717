import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import UnstyledAccordion from '../UnstyledAccordion';

const FormSection = (props: { title?: string; show?: boolean; children: JSX.Element }) => {
  const theme = useTheme();
  const { title, children, show } = props;

  return (
    <UnstyledAccordion show={show ?? true}>
      <Box
        sx={{
          marginBottom: theme.spacing(2),
          borderBottom: 'none',
        }}
      >
        {title && (
          <Typography variant="h4" sx={{ marginBottom: theme.spacing(2) }}>
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </UnstyledAccordion>
  );
};

export default FormSection;
