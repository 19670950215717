import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/Loading';
import Router  from './route/routes';

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
