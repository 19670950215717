import ExcelImport from '../components/dataimport/ExcelImport';
import WoodVolumeForm from '../components/form/volume/WoodVolumeForm';
import LoginPage from '../components/LoginPage';

export type RouteType = {
  href: string;
  header?: string;
  title: string;
  element: JSX.Element;
};

export enum DataInput {
  FORM = 'FORM',
  EXCEL = 'EXCEL',
}

export const RouteConfig = {
  login: {
    login: {
      href: '/login',
      element: <LoginPage />,
    },
    loginError: {
      href: '/loginError',
      element: <LoginPage error />,
    },
  },
  dataInput: {
    [DataInput.FORM]: {
      href: '/form',
      title: `url.${DataInput.FORM}`,
      header: 'volumeForm.header',
      element: <WoodVolumeForm />,
    },
    [DataInput.EXCEL]: {
      href: '/excel',
      title: `url.${DataInput.EXCEL}`,
      header: 'excelImport.title.import',
      element: <ExcelImport />,
    },
  },
};
