import { PalletType, VolumeSchema } from '../../components/form/volume/volumeFormTypes';
import { DataInput } from '../../route/routeConfig';
import { Month } from '../../types/commonTypes';
import { MaterialType, WoodRecyclingMethod } from '../../types/materialTypes';

const translation = {
  frontpage: {
    title: 'Valitse tiedon syöttötapa',
    form: 'Lomake',
    excel: 'Excel',
    instructions: 'Käyttöohjeet',
    privacyStatement: 'Tietosuojaseloste',
  },
  notFoundPage: {
    title: 'Sivua ei löydy',
  },
  loginPage: {
    loginTitle: 'Kirjaudu sisään',
    useCreatedAccount: 'Käytä tähän sinulle luotua Microsoft-tiliä.',
    passwordForgotten:
      'Mikäli olet unohtanut salasanasi tai sinulla ei vielä ole Microsoft-tiliä, ota yhteyttä Suomen Pakkaustuottajiin:',
    login: 'Kirjaudu',
    error: 'Virhe sisäänkirjautumisessa',
  },
  options: {
    month: {
      label: 'Kuukausi',
      valueMapper: {
        [Month.JANUARY]: 'tammikuu',
        [Month.FEBRUARY]: 'helmikuu',
        [Month.MARCH]: 'maaliskuu',
        [Month.APRIL]: 'huhtikuu',
        [Month.MAY]: 'toukokuu',
        [Month.JUNE]: 'kesäkuu',
        [Month.JULY]: 'heinäkuu',
        [Month.AUGUST]: 'elokuu',
        [Month.SEPTEMBER]: 'syyskuu',
        [Month.OCTOBER]: 'lokakuu',
        [Month.NOVEMBER]: 'marraskuu',
        [Month.DECEMBER]: 'joulukuu',
      },
    },
  },
  excelImport: {
    title: {
      import: 'Tuo excel-taulukko',
      chooseMaterial: 'Valitse materiaali',
      cantChooseMaterial: 'Tuotava materiaali',
      chooseFileType: 'Valitse tuontitiedoston tyyppi',
      chooseVolumeType: 'Valitse keräystiedon tyyppi',
      chooseVolumeDirection: 'Valitse keräystiedon suunta',
      addFile: 'Lisää tiedosto',
      preview: 'Esikatselu',
    },
    buttons: {
      collecting: 'Keräys',
      recycling: 'Kierrätys',
      accepted: 'Hyväksytyt',
      rejected: 'Hylätyt',
      incoming: 'Saapuva',
      outgoing: 'Lähtevä',
    },
    fileImport: {
      text: 'Pudota tai valitse tiedosto tästä',
    },
    alert: {
      success: 'Tiedoston tallennus onnistui',
      error: 'Tiedoston tallennus epäonnistui',
    },
    templates: {
      incoming: 'Saapuvat kuormat -mallipohja',
      outgoing: 'Lähtevät kuormat -mallipohja',
      recycling: 'Kierrätys-mallipohja',
    },
    errors: {
      noMaterials: 'Ei määriteltyjä materiaaleja',
      noTerminals: 'Ei määriteltyjä laitoksia',
    },
  },
  volumeForm: {
    header: 'Tuo lomakkeella',
    alert: {
      success: 'Tiedot tallennettu onnistuneesti',
      error: 'Tallennus epäonnistui',
    },
  },
  woodVolumeForm: {
    title: 'Puupakkaukset',
    recycling: 'Muu kierrätys',
    repairing: 'Korjaus',
    itemTypes: {
      [PalletType.EUR]: 'EUR-lava',
      [PalletType.FIN]: 'FIN-lava',
      [PalletType.TEHO]: 'Teho-lava',
      [PalletType.CABLE]: 'Kaapelikelat',
      [PalletType.OTHER]: 'Muu lava',
    },
    notDefined: 'Kumppanille ei ole määritelty materiaalia Puu',
  },
  actions: {
    cancel: 'Tyhjennä',
    submit: 'Lähetä',
    addRow: 'Lisää rivi',
  },
  material: {
    [MaterialType.GLASS]: 'Lasipakkaukset',
    [MaterialType.PLASTIC]: 'Muovipakkaukset',
    [MaterialType.METAL]: 'Metallipakkaukset',
    [MaterialType.CARD]: 'Kartonki- ja paperipakkaukset',
    [MaterialType.WOOD]: 'Puupakkaukset',
    shortNames: {
      [MaterialType.GLASS]: 'Lasi',
      [MaterialType.PLASTIC]: 'Muovi',
      [MaterialType.METAL]: 'Metalli',
      [MaterialType.CARD]: 'Kuitu',
      [MaterialType.WOOD]: 'Puu',
    },
  },

  url: {
    [DataInput.FORM]: 'Lomake',
    [DataInput.EXCEL]: 'Excel import',
  },
  errorMessages: {
    weightRequired: 'Paino on pakollinen',
    termRequired: 'Kausi on pakollinen',
    terminalRequired: 'Terminaali on pakollinen',
    monthRequired: 'Kuukausi on pakollinen',
    yearRequired: 'Vuosi on pakollinen',
    recyclingMethodRequired: 'Hyötykäyttömenetelmä on pakollinen',
    itemTypeRequired: 'Kappaletyyppi on pakollinen',
    rowRequired: 'Puuttuu rivitieto',
    invalidYear: 'Vuosi ei kelpaa',
    negativeValue: 'Arvon oltava positiivinen',
  },
  volumeFields: {
    [VolumeSchema.ALKU_PVM]: 'Alkupäivä',
    [VolumeSchema.PAATTYMIS_PVM]: 'Päättymispäivä',
    [VolumeSchema.TYYPPI]: 'Tyyppi',
    [VolumeSchema.NIMIKE]: 'Nimike',
    [VolumeSchema.PAINO]: 'Paino tn',
    [VolumeSchema.KAPPALEMAARA]: 'Kappalemäärä',
    [VolumeSchema.KAPPALETYYPPI]: 'Kappaletyyppi',
    [VolumeSchema.HYOTYKAYTTOMENETELMA]: 'Hyötykäyttömenetelmä',
    [VolumeSchema.LAHTOPISTE]: 'Lähtöpiste',
    [VolumeSchema.KOHDEPISTE]: 'Terminaali',
    [VolumeSchema.KUNTATOIMIJA]: 'Kuntatoimija',
    [VolumeSchema.KAUSI]: 'Kausi',
    [VolumeSchema.VUOSI]: 'Vuosi',
  },
  recyclingMethods: {
    [WoodRecyclingMethod.COMPOSTING]: 'Kompostointi',
    [WoodRecyclingMethod.REPAIRING]: 'Korjaus',
    [WoodRecyclingMethod.LANDSCAPING]: 'Maisemointi',
    [WoodRecyclingMethod.RECYCLING]: 'Kierrätys materiaalina Puu',
    [WoodRecyclingMethod.ENERGY]: 'Energiahyödyntäminen',
  },
  common: {
    loading: 'Ladataan...',
  },
};
export default translation;
