import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { materialColor } from '../../../theme/theme';
import { MaterialType } from '../../../types/materialTypes';

export const CardWrapper = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme }) => ({
  backgroundColor: 'initial',
  width: theme.spacing(7),
  boxShadow: 'none',
}));

const CustomizedAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  //backgroundColor: theme.palette.gray.main,
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const CustomizedAccordion = styled(Accordion)(() => ({
  //backgroundColor: theme.palette.gray.main,
  borderRadius: '6px',
  '&:before': { background: 'none' },
}));

const CustomizedAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  color: 'white',
  paddingLeft: theme.spacing(2),
  borderRadius: '6px',
  minHeight: `${theme.spacing(7)} !important`,
  '.MuiAccordionSummary-content': {
    marginTop: `${theme.spacing(1)}!important`,
    marginBottom: `${theme.spacing(1)}!important`,
    alignItems: 'center',
  },
}));

interface VolumeFormAccordionProps {
  materialType: MaterialType;
  accordionSummary?: {
    icon?: JSX.Element;
    text?: string;
  };
  children: JSX.Element[];
}

const VolumeFormAccordion = ({
  materialType,
  accordionSummary,
  children,
}: VolumeFormAccordionProps) => {
  const theme = useTheme();
  const summaryIcon = accordionSummary?.icon;
  const summaryText = accordionSummary?.text;
  return (
    <CustomizedAccordion defaultExpanded={true}>
      <CustomizedAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
        sx={{
          backgroundColor: alpha(
            (theme.palette as any)[materialColor[materialType]].main,
            0.9,
          ).toString(),
        }}
      >
        <>
          {summaryIcon}
          {summaryText && (
            <Typography variant="h6" ml={1}>
              {summaryText}
            </Typography>
          )}
        </>
      </CustomizedAccordionSummary>
      <CustomizedAccordionDetails>{children}</CustomizedAccordionDetails>
    </CustomizedAccordion>
  );
};

export default VolumeFormAccordion;
