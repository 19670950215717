import React from 'react';
import { Button, useTheme } from '@mui/material';

interface Props {
  value: boolean;
  updateValue: (value: boolean) => void;
  titles: { first: string; second: string };
}

const DualButtonSelection = (props: Props) => {
  const { value, updateValue, titles } = props;
  const theme = useTheme();

  const isSelected = (expectedValue: boolean) => {
    return value === expectedValue ? 'contained' : 'outlined';
  };

  const styles = {
    minWidth: '9em',
    marginRight: theme.spacing(2),
  };
  return (
    <>
      <Button sx={styles} variant={isSelected(true)} onClick={() => updateValue(true)}>
        {titles.first}
      </Button>
      <Button sx={styles} variant={isSelected(false)} onClick={() => updateValue(false)}>
        {titles.second}
      </Button>
    </>
  );
};

export default DualButtonSelection;
