import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import * as R from 'ramda';
import { Controller } from 'react-hook-form';
import { FormField } from '../../../types/formTypes';
import { Nullable, Option } from '../../../types/commonTypes';
import { formFieldVariant } from '../formUtil';
import { useTranslation } from 'react-i18next';

export interface DropdownFieldProps extends FormField {
  options: Array<Option>;
  defaultValue?: Option;
  disableClearable?: boolean;
  textFieldsSx?: any;
}

const DropdownField = ({
  name,
  label,
  options,
  control,
  placeholder,
  onChange,
  disabled,
  disableClearable,
  required,
  textFieldsSx,
}: DropdownFieldProps) => {
  const _onChange = onChange;
  const { t } = useTranslation();
  const translateErrorMsg = (errorMsg: Nullable<string>) =>
    errorMsg ? t(`errorMessages.${errorMsg}`) : ' ';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const fieldWithoutValue = R.omit(['value'], field);
        return (
          <Autocomplete
            {...fieldWithoutValue}
            disablePortal
            id={name}
            value={field.value ? field.value : null}
            disabled={disabled}
            selectOnFocus={true}
            disableClearable={disableClearable}
            onChange={(_, item) => {
              field.onChange(!R.isNil(item) ? item : null);
              if (_onChange) {
                _onChange(field.name);
              }
            }}
            getOptionLabel={(option) => option.label}
            defaultValue={control._defaultValues[name] || undefined}
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={textFieldsSx}
                required={required}
                variant={formFieldVariant}
                placeholder={placeholder}
                label={label}
                error={!!error}
                helperText={error ? translateErrorMsg(error.message) : ' '}
              />
            )}
          />
        );
      }}
    />
  );
};

export default DropdownField;
