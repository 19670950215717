import { TextField } from '@mui/material';
import * as R from 'ramda';
import { Controller } from 'react-hook-form';
import { FormField } from '../../../types/formTypes';
import { Nullable } from '../../../types/commonTypes';
import { formFieldVariant } from '../formUtil';
import { useTranslation } from 'react-i18next';
import { HTMLInputTypeAttribute } from 'react';

const convertToValue =
  (type: Nullable<HTMLInputTypeAttribute>) =>
  (
    e:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const _value = e.target.value;
    if (R.isNil(_value) || R.isEmpty(_value)) {
      return null;
    }
    return type === 'number' ? Number(_value) : _value;
  };

const InputField = ({
  name,
  label,
  placeholder,
  control,
  type,
  disabled,
  required,
  onChange,
}: FormField) => {
  const convert = convertToValue(type);
  const { t } = useTranslation();
  const translateErrorMsg = (errorMsg: Nullable<string>) =>
    errorMsg ? t(`errorMessages.${errorMsg}`) : ' ';
  const _onChange = onChange;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...R.omit(['value'], field)}
          label={label}
          value={field.value ?? ''}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(event) => {
            field.onChange(!R.isNil(event) ? event : null);
            convert(event);
            if (_onChange) {
              _onChange(!R.isNil(event) ? event.target.name : null);
            }
          }}
          error={!!error}
          helperText={error ? translateErrorMsg(error.message) : ' '}
          type={type || 'text'}
          variant={formFieldVariant}
          required={required}
        />
      )}
    />
  );
};

export default InputField;
