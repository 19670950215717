import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';

export const CustomizedLink = styled(RouterLink)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.gray.main,
  },
}));

export const UnstyledLink = styled(RouterLink)(() => ({
  color: 'inherit!important',
}));
