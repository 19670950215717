import { Cancel } from '@mui/icons-material';
import { Box, Container, Grid, IconButton } from '@mui/material';
import * as R from 'ramda';
import { styled } from '@mui/material/styles';
import { UseFieldArrayRemove } from 'react-hook-form';
import { FormField } from './volumeFormUtils';

const CustomizedContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

interface WoodUtilizationFormProps {
  fields: any;
  columns: {
    base: any;
    rows: any;
  };
  remove: UseFieldArrayRemove;
  removeRowBtnColor?: string;
  volumeForm: any;
  rest?: JSX.Element;
}

const VolumeFormAccordionContent = ({
  fields,
  remove,
  columns,
  volumeForm,
  removeRowBtnColor,
  rest,
}: WoodUtilizationFormProps) => {
  return (
    <CustomizedContainer maxWidth={false}>
      <Box component="form" noValidate={true}>
        <Grid>
          {R.map(
            (column) => (
              <FormField key={column}>{volumeForm[column].getComponent()}</FormField>
            ),
            columns.base,
          )}
        </Grid>
        {fields.map((field: any, index: number) => (
          <Grid key={field.id} display="flex" alignItems="center" flexWrap="wrap">
            {R.map(
              (rowColumn) => (
                <FormField key={`${fields}-form-fields-${index}-${rowColumn}`}>
                  {volumeForm[rowColumn].getComponent({
                    name: `rows.${index}.${rowColumn}`,
                  })}
                </FormField>
              ),
              columns.rows,
            )}

            <IconButton
              sx={{ ml: 2 }}
              onClick={() => remove(index)}
              color={removeRowBtnColor as any}
            >
              <Cancel />
            </IconButton>
          </Grid>
        ))}
        {rest && (
          <Grid>
            <FormField>{rest}</FormField>
          </Grid>
        )}
      </Box>
    </CustomizedContainer>
  );
};

export default VolumeFormAccordionContent;
