import { PalletType, VolumeSchema } from '../../components/form/volume/volumeFormTypes';
import { DataInput } from '../../route/routeConfig';
import { Month } from '../../types/commonTypes';
import { MaterialType, WoodRecyclingMethod } from '../../types/materialTypes';

const translation = {
  frontpage: {
    title: 'Select data input method',
    form: 'Form',
    excel: 'Excel',
    instructions: 'Instructions',
    privacyStatement: 'Privacy statement',
  },
  notFoundPage: {
    title: 'Page not found',
  },
  loginPage: {
    loginTitle: 'Logging in',
    useCreatedAccount: 'Use the Microsoft account created for you.',
    passwordForgotten:
      'If you have forgotten your password or do not have a Microsoft account yet, contact Finnish Packaging Producers Ltd:',
    login: 'Login',
    error: 'Error in logging',
  },
  options: {
    month: {
      label: 'Month',
      valueMapper: {
        [Month.JANUARY]: 'January',
        [Month.FEBRUARY]: 'February',
        [Month.MARCH]: 'March',
        [Month.APRIL]: 'April',
        [Month.MAY]: 'April',
        [Month.JUNE]: 'June',
        [Month.JULY]: 'July',
        [Month.AUGUST]: 'August',
        [Month.SEPTEMBER]: 'September',
        [Month.OCTOBER]: 'October',
        [Month.NOVEMBER]: 'November',
        [Month.DECEMBER]: 'December',
      },
    },
  },
  excelImport: {
    title: {
      import: 'Import excel sheet',
      chooseMaterial: 'Select material',
      cantChooseMaterial: 'Material',
      chooseFileType: 'Select the import file type',
      chooseVolumeType: 'Select type of collection data',
      chooseVolumeDirection: 'Select the direction of collection data',
      addFile: 'Add file',
      preview: 'Preview',
    },
    buttons: {
      collecting: 'Collecting',
      recycling: 'Recycling',
      accepted: 'Accepted',
      rejected: 'Rejected',
      incoming: 'Incoming',
      outgoing: 'Outgoing',
    },
    fileImport: {
      text: 'Drop or select a file',
    },
    alert: {
      success: 'File upload successful',
      error: 'File upload failed',
    },
    templates: {
      incoming: 'Incoming loads template',
      outgoing: 'Outgoing loads template',
      recycling: 'Recycling template',
    },
    errors: {
      noMaterials: 'Materials not defined',
      noTerminals: 'Recycling plants not defined',
    },
  },
  volumeForm: {
    header: 'Import materials',
    alert: {
      success: 'Save successful',
      error: 'Save failed',
    },
  },
  woodVolumeForm: {
    title: 'Import materials',
    recycling: 'Other Recycling',
    repairing: 'Repairing',
    itemTypes: {
      [PalletType.EUR]: 'EUR pallet',
      [PalletType.FIN]: 'FIN pallet',
      [PalletType.TEHO]: 'Teho pallet',
      [PalletType.CABLE]: 'Cable reels',
      [PalletType.OTHER]: 'Other',
    },
    notDefined: 'Material "wood" not defined for this partner',
  },
  actions: {
    cancel: 'Clear',
    submit: 'Submit',
    addRow: 'Add row',
  },
  material: {
    [MaterialType.GLASS]: 'Glass packaging',
    [MaterialType.PLASTIC]: 'Plastic packaging',
    [MaterialType.METAL]: 'Metal packaging',
    [MaterialType.CARD]: 'Carton packaging',
    [MaterialType.WOOD]: 'Wood packaging',
    shortNames: {
      [MaterialType.GLASS]: 'Glass',
      [MaterialType.PLASTIC]: 'Plastic',
      [MaterialType.METAL]: 'Metal',
      [MaterialType.CARD]: 'Carton',
      [MaterialType.WOOD]: 'Wood',
    },
  },
  url: {
    [DataInput.FORM]: 'Form',
    [DataInput.EXCEL]: 'Excel import',
  },
  errorMessages: {
    weightRequired: 'Weight is required',
    termRequired: 'Term is required',
    terminalRequired: 'Terminal is required',
    monthRequired: 'Month is required',
    yearRequired: 'Year is required',
    recyclingMethodRequired: 'Utilization method is required',
    itemTypeRequired: 'Item type is required',
    rowRequired: 'Row required',
    invalidYear: 'Invalid year',
    negativeValue: 'Value must be positive',
  },
  volumeFields: {
    [VolumeSchema.ALKU_PVM]: 'Start date',
    [VolumeSchema.PAATTYMIS_PVM]: 'End date',
    [VolumeSchema.TYYPPI]: 'Type',
    [VolumeSchema.NIMIKE]: 'Title',
    [VolumeSchema.PAINO]: 'Weight in tons',
    [VolumeSchema.KAPPALEMAARA]: 'Quantity (pcs)',
    [VolumeSchema.KAPPALETYYPPI]: 'Item type',
    [VolumeSchema.HYOTYKAYTTOMENETELMA]: 'Utilization method',
    [VolumeSchema.LAHTOPISTE]: 'Starting point',
    [VolumeSchema.KOHDEPISTE]: 'Terminal',
    [VolumeSchema.KUNTATOIMIJA]: 'Municipal official',
    [VolumeSchema.KAUSI]: 'Period',
    [VolumeSchema.VUOSI]: 'Year',
  },
  recyclingMethods: {
    [WoodRecyclingMethod.COMPOSTING]: 'Composting',
    [WoodRecyclingMethod.REPAIRING]: 'Repairing',
    [WoodRecyclingMethod.LANDSCAPING]: 'Landscaping',
    [WoodRecyclingMethod.RECYCLING]: 'Material recycling Wood',
    [WoodRecyclingMethod.ENERGY]: 'Energy recovery',
  },
  common: {
    loading: 'Loading...',
  },
};
export default translation;
