import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createRoot } from 'react-dom/client';
import './i18n';
import App from './App';
import moment from 'moment';
import { CssBaseline, ThemeProvider } from '@mui/material';
import customTheme from './theme/theme';
import './styles.css';
import store, { persistor } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { isDev, isProd, isStag } from './common/constants';

if (isDev) {
  console.log('Looks like we are in development mode');
}

if (isStag) {
  console.log('Looks like we are in staging mode');
}

if (isProd) {
  console.log('Looks like we are in PRODUCTION mode');
}

moment.locale('fi');

const root = createRoot(document.getElementById('root') as HTMLElement);
const useStrict = true;
const StickModeWrapper = useStrict ? React.StrictMode : React.Fragment;

root.render(
  <StickModeWrapper>
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fi'}>
            <App />
          </LocalizationProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </StickModeWrapper>,
);
