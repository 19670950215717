import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditNoteOutlined, Recycling } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as R from 'ramda';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import moment from 'moment';
import { woodIcon } from '../../../common/svg/recyclingIcons';
import { materialColor } from '../../../theme/theme';
import {
  MaterialType,
  WoodMaterialType,
  WoodRecyclingMethod,
} from '../../../types/materialTypes';
import MiniActionCard from '../../MiniActionCard';
import {
  optionSchemaRequired,
  positiveNumberSchema,
  positiveNumberSchemaRequired,
  yearSchemaRequired,
} from '../yupUtils';
import useVolumeForm from './useVolumeForm';
import VolumeForm from './VolumeForm';
import {
  Accordion,
  RepairingInputs,
  ItemWeight,
  PalletType,
  RecyclingInputs,
  Term,
  VolumeSchema,
  isRepairingInput,
} from './volumeFormTypes';
import {
  UserData,
  VolumeFormData,
  useGetItemWeightsMutation,
  useGetRecyclingMethodsMutation,
  useSendFormDataMutation,
} from '../../../app/services/user';
import { useAppSelector } from '../../../app/hooks';
import { selectUserData } from '../../../app/services/userSlice';
import { Option } from '../../../types/commonTypes';

const rowSchema = {
  [WoodMaterialType.RECYCLING]: {
    [VolumeSchema.KOHDEPISTE]: optionSchemaRequired('terminalRequired'),
    [VolumeSchema.PAINO]: positiveNumberSchemaRequired('weightRequired'),
    [VolumeSchema.HYOTYKAYTTOMENETELMA]: optionSchemaRequired('recyclingMethodRequired'),
  },
  [WoodMaterialType.REPAIRING]: {
    [VolumeSchema.KOHDEPISTE]: optionSchemaRequired('terminalRequired'),
    [VolumeSchema.KAPPALETYYPPI]: optionSchemaRequired('itemTypeRequired'),
    [VolumeSchema.KAPPALEMAARA]: positiveNumberSchema,
    [VolumeSchema.PAINO]: positiveNumberSchemaRequired('weightRequired'),
  },
};

const baseSchema = {
  [VolumeSchema.KAUSI]: optionSchemaRequired('termRequired'),
  [VolumeSchema.VUOSI]: yearSchemaRequired('yearRequired'),
};

const getSchema = (woodMaterialType: WoodMaterialType) => ({
  ...baseSchema,
  rows: yup
    .array()
    .of(yup.object().shape(rowSchema[woodMaterialType]))
    .required('rowRequired')
    .min(1, 'rowRequired'),
});

const columnsByWoodMaterialType = R.fromPairs(
  R.map(
    (woodMaterialType) => [
      woodMaterialType,
      {
        base: R.keys(baseSchema),
        rows: R.keys(rowSchema[woodMaterialType]),
      },
    ],
    R.values(WoodMaterialType),
  ),
);

const emptyRows = {
  [WoodMaterialType.RECYCLING]: {
    [VolumeSchema.KOHDEPISTE]: null,
    [VolumeSchema.PAINO]: null,
    [VolumeSchema.HYOTYKAYTTOMENETELMA]: null,
  },
  [WoodMaterialType.REPAIRING]: {
    [VolumeSchema.KOHDEPISTE]: null,
    [VolumeSchema.KAPPALETYYPPI]: null,
    [VolumeSchema.KAPPALEMAARA]: null,
    [VolumeSchema.PAINO]: null,
  },
};

const getDefaultValues = (woodMaterialType: WoodMaterialType) => ({
  [VolumeSchema.KAUSI]: null,
  [VolumeSchema.VUOSI]: null,
  rows: [emptyRows[woodMaterialType]],
});

const WoodVolumeForm = () => {
  const { t } = useTranslation();

  const [sendFormData] = useSendFormDataMutation();
  const [getRecyclingMethods] = useGetRecyclingMethodsMutation();
  const [getItemWeights] = useGetItemWeightsMutation();

  const { facilities, materials } = useAppSelector(selectUserData) as UserData;
  const [recyclingMethods, setRecyclingMethods] = useState<WoodRecyclingMethod[]>([]);
  const [itemWeights, setItemWeights] = useState<ItemWeight[]>([]);

  // Snackbar for showing success/failure after form submit
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [saveSuccessful, setSaveSuccessful] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [isRecyclingSaving, setIsRecyclingSaving] = useState(false);
  const [isRepairingSaving, setIsRepairingSaving] = useState(false);

  // Fetch recycling methods and item weights from backend when loading the form
  useEffect(() => {
    getRecyclingMethods(MaterialType.WOOD)
      .then((response) => {
        const methods: WoodRecyclingMethod[] = (response as any).data.body.recyclingMethods;
        setRecyclingMethods(
          methods.filter((method) => method !== WoodRecyclingMethod.REPAIRING),
        );
      })
      .catch((error) => console.error(`Recycling method fetching failed: ${error}`));

    getItemWeights(MaterialType.WOOD)
      .then((response) => {
        const weights = (response as any).data.body.itemWeights;
        setItemWeights(weights);
      })
      .catch((error) => console.error(`Item weight fetching failed: ${error}`));
  }, [getRecyclingMethods, getItemWeights]);

  const {
    control: recyclingControl,
    handleSubmit: handleSubmitRecycling,
    reset: resetRecycling,
  } = useForm<RecyclingInputs>({
    mode: 'all',
    resolver: yupResolver(yup.object().shape(getSchema(WoodMaterialType.RECYCLING))),
    defaultValues: getDefaultValues(WoodMaterialType.RECYCLING),
  });

  const {
    fields: recyclingFields,
    append: recyclingAppend,
    remove: recyclingRemove,
  } = useFieldArray({
    control: recyclingControl,
    name: 'rows',
  });

  const {
    control: repairingControl,
    handleSubmit: handleSubmitRepairing,
    getValues: getRepairingValues,
    setValue: setRepairingValue,
    reset: resetRepairing,
  } = useForm<RepairingInputs>({
    mode: 'all',
    resolver: yupResolver(yup.object().shape(getSchema(WoodMaterialType.REPAIRING))),
    defaultValues: getDefaultValues(WoodMaterialType.REPAIRING),
  });

  // Repairing rows: weight is calculated when item type or amount is changed and both have values
  const onItemTypeOrAmountChange = (fieldId: string) => {
    try {
      const [_, index, field] = fieldId.split('.');
      if (field === VolumeSchema.KAPPALEMAARA || field === VolumeSchema.KAPPALETYYPPI) {
        const itemType: Option = getRepairingValues(
          `rows.${parseInt(index)}.${VolumeSchema.KAPPALETYYPPI}`,
        );
        const amount = getRepairingValues(
          `rows.${parseInt(index)}.${VolumeSchema.KAPPALEMAARA}`,
        );
        if (itemType && amount) {
          const weight = itemWeights.find(
            (itemWeight) => itemWeight.name === itemType.value,
          )?.weight;
          if (weight) {
            const weightAsTons = (weight * amount) / 1000;
            setRepairingValue(
              `rows.${parseInt(index)}.${VolumeSchema.PAINO}` as any,
              weightAsTons,
            );
          }
        }
      }
    } catch (error) {
      console.error(`Weight calculation failed: ${error}`);
    }
  };

  const {
    fields: repairingFields,
    append: repairingAppend,
    remove: repairingRemove,
  } = useFieldArray({
    control: repairingControl,
    name: 'rows',
  });

  const onSubmitRepairing = () => {
    handleSubmitRepairing((val) => {
      setIsRepairingSaving(true);
      sendFormData(convertToApiFormData(val))
        .then((response) => {
          if ((response as any).error) {
            handleSubmitError((response as any).error.data?.message);
          } else {
            setSaveSuccessful(true);
            resetRepairing();
          }
          setIsRepairingSaving(false);
          setShowSnackbar(true);
        })
        .catch((error) => {
          handleSubmitError(error);
          setIsRepairingSaving(false);
          setShowSnackbar(true);
        });
    })();
  };

  const onSubmitRecycling = () => {
    handleSubmitRecycling((val) => {
      setIsRecyclingSaving(true);
      sendFormData(convertToApiFormData(val))
        .then((response) => {
          if ((response as any).error) {
            handleSubmitError((response as any).error.data?.message);
          } else {
            setSaveSuccessful(true);
            resetRecycling();
          }
          setIsRecyclingSaving(false);
          setShowSnackbar(true);
        })
        .catch((error) => {
          handleSubmitError(error);
          setIsRecyclingSaving(false);
          setShowSnackbar(true);
        });
    })();
  };

  const handleSubmitError = (message: string) => {
    setErrorMessage(message);
    setSaveSuccessful(false);
  };

  const convertToApiFormData = (inputs: RepairingInputs | RecyclingInputs): VolumeFormData => {
    // Create Date as UTC to avoid time zone conversions messing up the date
    const year = inputs.Vuosi as number;
    const startDayAndMonth = inputs.Kausi?.value === Term.FIRST ? '01-01' : '07-01';
    const endDayAndMonth = inputs.Kausi?.value === Term.FIRST ? '06-30' : '12-31';
    const startDate = moment.utc(`${year}-${startDayAndMonth}`, 'YYYY-MM-DD').toDate();
    const endDate = moment.utc(`${year}-${endDayAndMonth}`, 'YYYY-MM-DD').toDate();
    const rows = isRepairingInput(inputs)
      ? inputs.rows.map((row) => {
          return {
            facility: row.Kohdepiste?.value as string,
            weight: row.Paino as number,
            recyclingMethod: WoodRecyclingMethod.REPAIRING,
            itemType: row.Kappaletyyppi?.value as PalletType,
            amount: row.Kappalemäärä ?? undefined,
          };
        })
      : inputs.rows.map((row) => {
          return {
            facility: row.Kohdepiste?.value as string,
            weight: row.Paino as number,
            recyclingMethod: row.Hyötykäyttömenetelmä?.value as WoodRecyclingMethod,
          };
        });
    return {
      startDate,
      endDate,
      material: MaterialType.WOOD,
      rows,
    };
  };

  const recyclingVolumeForm = useVolumeForm({
    control: recyclingControl,
    facilities,
    recyclingMethods,
    itemWeights,
  });

  const repairingVolumeForm = useVolumeForm({
    control: repairingControl,
    facilities,
    recyclingMethods,
    itemWeights,
    onItemTypeOrAmountChange,
  });

  if (!materials.includes(MaterialType.WOOD)) {
    return <Box>{t('woodVolumeForm.notDefined')}</Box>;
  }

  const accordions: Array<Accordion> = [
    {
      materialType: MaterialType.WOOD,
      accordionSummary: {
        icon: <EditNoteOutlined />,
        text: t('woodVolumeForm.repairing'),
      },
      form: {
        formProps: {
          fields: repairingFields,
          remove: repairingRemove,
          columns: columnsByWoodMaterialType[WoodMaterialType.REPAIRING],
          volumeForm: repairingVolumeForm,
          removeRowBtnColor: materialColor[MaterialType.WOOD],
        },
        appendFn: () => repairingAppend(emptyRows[WoodMaterialType.REPAIRING]),
      },
      onSubmit: onSubmitRepairing,
      onClear: resetRepairing,
      isSaving: isRepairingSaving,
    },
    {
      materialType: MaterialType.WOOD,
      accordionSummary: {
        icon: <Recycling />,
        text: t('woodVolumeForm.recycling'),
      },
      form: {
        formProps: {
          fields: recyclingFields,
          remove: recyclingRemove,
          columns: columnsByWoodMaterialType[WoodMaterialType.RECYCLING],
          volumeForm: recyclingVolumeForm,
          removeRowBtnColor: materialColor[MaterialType.WOOD],
        },
        appendFn: () => recyclingAppend(emptyRows[WoodMaterialType.RECYCLING]),
      },
      onSubmit: onSubmitRecycling,
      onClear: resetRecycling,
      isSaving: isRecyclingSaving,
    },
  ];

  const title = (
    <>
      <MiniActionCard
        icon={woodIcon}
        title={t(`material.shortNames.${MaterialType.WOOD}`)}
        color={materialColor[MaterialType.WOOD]}
      />
      <Typography ml={2} variant="h4">
        {t('woodVolumeForm.title')}
      </Typography>
    </>
  );

  return (
    <VolumeForm
      title={title}
      accordions={accordions}
      snackbarProps={{
        showSnackbar,
        setShowSnackbar,
        successful: saveSuccessful,
        error: errorMessage,
      }}
    />
  );
};

export default WoodVolumeForm;
