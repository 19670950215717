import { Button, Container, Link as MaterialLink, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API_URL } from '../common/constants';

const CustomizedContainer = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '10vh',
}));

const SPT_EMAIL = 'raportointi@suomenpakkaustuottajat.fi';
const marginTopValue = 2;

/*
 * TODO: Add proper error toasts to different login problem cases
 */
const LoginPage = ({ error = false }) => {
  const { t } = useTranslation();
  return (
    <CustomizedContainer maxWidth="xs">
      <Typography variant="h2">{t('loginPage.loginTitle')}</Typography>

      <Typography mt={marginTopValue}>{t('loginPage.useCreatedAccount')}</Typography>

      <Typography mt={marginTopValue}>{t('loginPage.passwordForgotten')}</Typography>

      <MaterialLink
        mt={marginTopValue}
        component={RouterLink}
        to={`mailto: ${SPT_EMAIL}`}
        underline="none"
      >
        {SPT_EMAIL}
      </MaterialLink>

      <MaterialLink
        mt={marginTopValue}
        component={RouterLink}
        to={`${API_URL}/login`}
        underline="none"
      >
        <Button variant="contained">{t('loginPage.login')}</Button>
      </MaterialLink>

      {error && <b>{t('loginPage.error')}</b>}
    </CustomizedContainer>
  );
};
export default LoginPage;
