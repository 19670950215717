import { AddCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  typographyClasses,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { materialColor } from '../../../theme/theme';
import { MaterialType } from '../../../types/materialTypes';
import VolumeFormAccordion from './VolumeFormAccordion';
import VolumeFormAccordionContent from './VolumeFormAccordionContent';
import { Accordion } from './volumeFormTypes';
import { CustomizedFormRow } from './volumeFormUtils';
import SuccessSnackbar from '../../SuccessSnackbar';

const FormWrapper = styled(Container)(({ theme }) => ({
  width: 'fit-content',
  marginTop: theme.spacing(6),
  padding: '0 !important',
}));

const IconButtonTextWrapper = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  width: 'fit-content',
  marginLeft: theme.spacing(-1),
  [`.${typographyClasses.body1}`]: {
    color: theme.palette[materialColor[MaterialType.WOOD]].main,
  },
}));

interface VolumeFormProps {
  title: JSX.Element;
  accordions: Array<Accordion>;
  snackbarProps: FormSnackbarProps;
}

interface FormSnackbarProps {
  showSnackbar: boolean;
  setShowSnackbar: (show: boolean) => void;
  successful: boolean;
  error: string;
}

const VolumeForm = ({ title, accordions, snackbarProps }: VolumeFormProps) => {
  const { t } = useTranslation();
  const { showSnackbar, setShowSnackbar, successful, error } = snackbarProps;

  return (
    <Box>
      <SuccessSnackbar
        open={showSnackbar}
        setOpen={setShowSnackbar}
        successful={successful}
        successMessage={t('volumeForm.alert.success')}
        errorMessage={`${t('volumeForm.alert.error')}: ${error}`}
      />
      <Grid display="flex" alignItems="center">
        {title}
      </Grid>
      <FormWrapper maxWidth={false}>
        {accordions.map((accordion: Accordion, index: number) => (
          <VolumeFormAccordion
            materialType={accordion.materialType}
            accordionSummary={accordion.accordionSummary}
            key={`volume-form-accordion-${index}`}
          >
            <VolumeFormAccordionContent
              {...accordion.form.formProps}
              rest={
                <IconButtonTextWrapper container item onClick={accordion.form.appendFn}>
                  <IconButton color={materialColor[MaterialType.WOOD] as any}>
                    <AddCircle />
                  </IconButton>
                  <Typography sx={{ cursor: 'pointer' }}>{t('actions.addRow')}</Typography>
                </IconButtonTextWrapper>
              }
            />
            <CustomizedFormRow display="flex" justifyContent="space-between">
              <Button onClick={accordion.onClear}>{t('actions.cancel')}</Button>
              <Button onClick={accordion.onSubmit} variant="contained">
                {accordion.isSaving && (
                  <CircularProgress
                    sx={{ mr: 2, svg: { color: 'white!important' } }}
                    size={15}
                  />
                )}
                {t('actions.submit')}
              </Button>
            </CustomizedFormRow>
          </VolumeFormAccordion>
        ))}
      </FormWrapper>
    </Box>
  );
};

export default VolumeForm;
