import { FormControl, FormGroup, Grid } from '@mui/material';
import { styled } from '@mui/system';

export const CustomizedFormControl = styled(FormControl)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
}));

interface FormFieldProps {
  children: JSX.Element;
  sx?: any;
}

export const FormField = ({ children, sx }: FormFieldProps) => {
  return (
    <CustomizedFormControl sx={sx}>
      <FormGroup>{children}</FormGroup>
    </CustomizedFormControl>
  );
};

export const CustomizedFormRow = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'flex',
}));
