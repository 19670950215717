import { ExpandMore } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

const CustomizedSelect = styled(MuiSelect)(({ theme }) => ({
  width: 'fit-content!important',
  '*': {
    fontFamily: "'Work Sans',sans-serif !important",
    fontSize: '.9rem !important',
  },
  '&:before': {
    border: 'none!important',
  },
  '.MuiSelect-select': {
    paddingRight: `${theme.spacing(1.5)} !important`,
    paddingTop: '5px',
    marginRight: '6px',
    '&:focus': {
      background: 'none',
    },
  },
})) as unknown as typeof MuiSelect;

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    const newLang = event.target.value;
    window.localStorage.setItem('lang', newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <FormControl sx={{ mr: 2 }}>
      <CustomizedSelect
        renderValue={(val) => (R.type(val) === 'String' ? R.toUpper(val) : val)}
        value={i18n.language}
        onChange={handleChange}
        variant="standard"
        IconComponent={ExpandMore}
      >
        <MenuItem value="fi">Suomi</MenuItem>
        <MenuItem value="sv">Svenska</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </CustomizedSelect>
    </FormControl>
  );
};

export default LanguageSelector;
