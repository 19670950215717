import { PalletType, VolumeSchema } from '../../components/form/volume/volumeFormTypes';
import { DataInput } from '../../route/routeConfig';
import { Month } from '../../types/commonTypes';
import { MaterialType, WoodRecyclingMethod } from '../../types/materialTypes';

const translation = {
  frontpage: {
    title: 'Välj dataingångsmetod',
    form: 'Form',
    excel: 'Excel',
    instructions: 'Instruktioner',
    privacyStatement: 'Sekretesspolicy',
  },
  notFoundPage: {
    title: 'Sidan hittas inte',
  },
  loginPage: {
    loginTitle: 'Logga in',
    useCreatedAccount: 'Använd ditt Microsoft-konto.',
    passwordForgotten:
      'Om du har glömt ditt lösenord eller inte har ett Microsoft-konto ännu, kontakta Finlands Förpackningsproducenter Ab:',
    login: 'Logga in',
    error: 'Inloggning misslyckades',
  },
  options: {
    month: {
      label: 'Månad',
      valueMapper: {
        [Month.JANUARY]: 'januari',
        [Month.FEBRUARY]: 'februari',
        [Month.MARCH]: 'mars',
        [Month.APRIL]: 'april',
        [Month.MAY]: 'maj',
        [Month.JUNE]: 'juni',
        [Month.JULY]: 'juli',
        [Month.AUGUST]: 'augusti',
        [Month.SEPTEMBER]: 'september',
        [Month.OCTOBER]: 'oktober',
        [Month.NOVEMBER]: 'november',
        [Month.DECEMBER]: 'december',
      },
    },
  },
  excelImport: {
    title: {
      import: 'Importera excel-tabell',
      chooseMaterial: 'Välj material',
      cantChooseMaterial: 'Material',
      chooseFileType: 'Välj importfiltypen',
      chooseVolumeType: 'Välj typ av insamlingsdata',
      chooseVolumeDirection: 'Välj riktning av insamlingsdata',
      addFile: 'Lägg till datafil',
      preview: 'Förhandsvisning',
    },
    buttons: {
      collecting: 'Insamling',
      recycling: 'Återvinning',
      accepted: 'Accepterade',
      rejected: 'Diskvalificerade',
      incoming: 'Inkommande',
      outgoing: 'Utgående',
    },
    fileImport: {
      text: 'Släpp eller välj en datafil',
    },
    alert: {
      success: 'Uppladningen lyckades',
      error: 'Uppladningen misslyckades',
    },
    templates: {
      incoming: 'Inkommande laster -mall',
      outgoing: 'Utgående laster -mall',
      recycling: 'Återvinning -mall',
    },
    errors: {
      noMaterials: 'Materialer inte definierade',
      noTerminals: 'Återvinningsanläggningar inte definierade',
    },
  },
  volumeForm: {
    header: 'Importera material',
    alert: {
      success: 'Uppladningen lyckades',
      error: 'Uppladningen misslyckades',
    },
  },
  woodVolumeForm: {
    title: 'Träförpackingar',
    recycling: 'Annan återvinning',
    repairing: 'Reparering',
    itemTypes: {
      [PalletType.EUR]: 'EUR-pall',
      [PalletType.FIN]: 'FIN-pall',
      [PalletType.TEHO]: 'Teho-pall',
      [PalletType.CABLE]: 'Kabelvindor',
      [PalletType.OTHER]: 'Andra pall',
    },
    notDefined: 'Materialet "Trä" inte definierat för denna partner',
  },
  actions: {
    cancel: 'Tömma',
    submit: 'Skicka in',
    addRow: 'Lägg till en rad',
  },
  material: {
    [MaterialType.GLASS]: 'Glasförpackningar',
    [MaterialType.PLASTIC]: 'Plastförpackningar',
    [MaterialType.METAL]: 'Metallförpackningar',
    [MaterialType.CARD]: 'Kartongförpackningar',
    [MaterialType.WOOD]: 'Träförpackningar',
    shortNames: {
      [MaterialType.GLASS]: 'Glas',
      [MaterialType.PLASTIC]: 'Plast',
      [MaterialType.METAL]: 'Metall',
      [MaterialType.CARD]: 'Kartong',
      [MaterialType.WOOD]: 'Trä',
    },
  },

  url: {
    [DataInput.FORM]: 'Form',
    [DataInput.EXCEL]: 'Excel import',
  },
  errorMessages: {
    weightRequired: 'Vikt är oblikatorisk',
    termRequired: 'Period är oblikatorisk',
    terminalRequired: 'Terminal är oblikatorisk',
    monthRequired: 'Månad är oblikatorisk',
    yearRequired: 'År är oblikatoriskt',
    recyclingMethodRequired: 'Återvinningsmetod är oblikatorisk',
    itemTypeRequired: 'Artikeltyp är oblikatorisk',
    rowRequired: 'Puuttuu rivitieto',
    invalidYear: 'Ogiltigt år',
    negativeValue: 'Värdet måste vara positivt',
  },
  volumeFields: {
    [VolumeSchema.ALKU_PVM]: 'Alkupäivä',
    [VolumeSchema.PAATTYMIS_PVM]: 'Päättymispäivä',
    [VolumeSchema.TYYPPI]: 'Tyyppi',
    [VolumeSchema.NIMIKE]: 'Nimike',
    [VolumeSchema.PAINO]: 'Vikt i ton',
    [VolumeSchema.KAPPALEMAARA]: 'Antal (st)',
    [VolumeSchema.KAPPALETYYPPI]: 'Artikel typ',
    [VolumeSchema.HYOTYKAYTTOMENETELMA]: 'Återvinningsmetod',
    [VolumeSchema.LAHTOPISTE]: 'Lähtöpiste',
    [VolumeSchema.KOHDEPISTE]: 'Terminal',
    [VolumeSchema.KUNTATOIMIJA]: 'Kuntatoimija',
    [VolumeSchema.KAUSI]: 'Period',
    [VolumeSchema.VUOSI]: 'År',
  },
  recyclingMethods: {
    [WoodRecyclingMethod.COMPOSTING]: 'Kompostering',
    [WoodRecyclingMethod.REPAIRING]: 'Reparering',
    [WoodRecyclingMethod.LANDSCAPING]: 'Markbyggnad',
    [WoodRecyclingMethod.RECYCLING]: 'Material återvinning Trä',
    [WoodRecyclingMethod.ENERGY]: 'Energiutvinning',
  },
  common: {
    loading: 'Laddar...',
  },
};
export default translation;
