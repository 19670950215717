import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export const CardWrapper = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ theme, color }) => ({
  backgroundColor: (theme.palette as any)[color].main,
  width: theme.spacing(6),
}));

const CustomizedCardActionArea = styled(CardActionArea)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: theme.spacing(0.5, 0),
}));

const CardIcon = styled(CardMedia)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
  marginTop: 'auto',
  svg: {
    '&.excel-icon': { width: theme.spacing(15) },
    '&.card-icon': { width: theme.spacing(3.5) },
    width: theme.spacing(3),
    aspectRatio: '1/1',
    height: theme.spacing(3),
    color: 'white',
  },
}));

const Text = styled(Typography)<{ component?: string }>(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  height: 'auto',
  svg: {
    marginLeft: theme.spacing(1),
  },
}));

interface ActionCardProps {
  icon: JSX.Element;
  color: string;
  title?: string;
  onClick?: () => void;
}

const MiniActionCard = ({ color, icon, title, onClick }: ActionCardProps) => {
  return (
    <CardWrapper color={color} className="action-card">
      <CustomizedCardActionArea onClick={() => (onClick ? onClick() : {})}>
        <CardIcon>{icon}</CardIcon>
        {title && (
          <Text variant="h7" component="div" sx={{ color: 'white' }}>
            {title}
          </Text>
        )}
      </CustomizedCardActionArea>
    </CardWrapper>
  );
};
export default MiniActionCard;
