import { MaterialType } from './../types/materialTypes';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { createTheme } from '@mui/material/styles';

const SPT_BEIGE = 'sptBeige';
const SPT_GREEN = 'sptGreen';
const SPT_GREY = 'sptGrey';
const SPT_PURPLE = 'sptPurple';
const SPT_BROWN = 'sptBrown';

export const materialColor = {
  [MaterialType.CARD]: SPT_BEIGE,
  [MaterialType.GLASS]: SPT_GREEN,
  [MaterialType.METAL]: SPT_GREY,
  [MaterialType.PLASTIC]: SPT_PURPLE,
  [MaterialType.WOOD]: SPT_BROWN,
};

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    gray: Palette['primary'];
    [SPT_BEIGE]: Palette['primary'];
    [SPT_GREEN]: Palette['primary'];
    [SPT_GREY]: Palette['primary'];
    [SPT_PURPLE]: Palette['primary'];
    [SPT_BROWN]: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    gray?: PaletteOptions['primary'];
    [SPT_BEIGE]: PaletteOptions['primary'];
    [SPT_GREEN]: PaletteOptions['primary'];
    [SPT_GREY]: PaletteOptions['primary'];
    [SPT_PURPLE]: PaletteOptions['primary'];
    [SPT_BROWN]: PaletteOptions['primary'];
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
    gray: true;
    [SPT_BEIGE]: true;
    [SPT_GREEN]: true;
    [SPT_GREY]: true;
    [SPT_PURPLE]: true;
    [SPT_BROWN]: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    gray: true;
    [SPT_BEIGE]: true;
    [SPT_GREEN]: true;
    [SPT_GREY]: true;
    [SPT_PURPLE]: true;
    [SPT_BROWN]: true;
  }
}

const _theme = createTheme({});
const customTheme = createTheme(_theme, {
  typography: {
    fontFamily: ['Lato'].join(','),
    body1: {
      fontFamily: ['Lato'].join(','),
      fontWeight: 500,
    },
    h1: {
      fontSize: '4rem',
      fontFamily: "'Work Sans',sans-serif !important",
      fontWeight: 600,
      [_theme.breakpoints.down('lg')]: {
        fontSize: '2.2rem',
      },
    },
    h2: {
      fontSize: '3rem',
      fontFamily: "'Work Sans',sans-serif !important",
      fontWeight: 600,
      [_theme.breakpoints.down('lg')]: {
        fontSize: '1.8rem',
      },
    },
    h3: {
      fontSize: '2rem',
      fontFamily: "'Work Sans',sans-serif !important",
      fontWeight: 600,
      [_theme.breakpoints.down('lg')]: {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      fontFamily: "'Work Sans',sans-serif !important",
      fontWeight: 600,
      [_theme.breakpoints.down('lg')]: {
        fontSize: '1.2rem',
      },
    },
    h5: {
      fontSize: '1.2rem',
      fontFamily: "'Work Sans',sans-serif !important",
      fontWeight: 600,
      [_theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
    },
    h6: {
      fontSize: '1rem',
      [_theme.breakpoints.down('lg')]: {
        fontSize: '0.8rem',
      },
      fontFamily: "'Work Sans',sans-serif !important",
      fontWeight: 600,
    },
    h7: {
      fontSize: '0.5rem',
      fontFamily: "'Work Sans',sans-serif !important",
      fontWeight: 600,
    },
  },
  palette: {
    primary: {
      light: '#0071bcc7',
      main: '#0071bc',
      dark: '#0368aa',
      contrastText: '#fff',
    },
    unselected: {
      main: '#666',
    },
    secondary: {
      light: '#972a87',
      main: '#972a87',
      dark: '#972a87',
      contrastText: '#fff',
    },
    black: {
      main: '#222',
    },
    gray: {
      main: '#f2f2f2',
    },
    [SPT_BEIGE]: {
      main: '#D1AC71',
    },
    [SPT_GREEN]: {
      main: '#0FB694',
    },
    [SPT_GREY]: {
      main: '#5E7590',
    },
    [SPT_PURPLE]: {
      main: '#972A87',
    },
    [SPT_BROWN]: {
      main: '#7D5C3B',
    },
    action: {
      hoverOpacity: 0.061,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Lato',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '13rem',
          [_theme.breakpoints.down('lg')]: {
            width: '10rem',
          },
        },
        input: {
          [_theme.breakpoints.up('lg')]: {
            paddingBottom: '0!important',
          },
          [_theme.breakpoints.down('lg')]: {
            height: '1rem!important',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          width: 'auto!important',
          minWidth: 'unset!important',
          [_theme.breakpoints.down('lg')]: {
            fontSize: '0.8rem!important',
          },
        },
        option: {
          [_theme.breakpoints.down('lg')]: {
            fontSize: '0.8rem!important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem!important',
          [_theme.breakpoints.down('lg')]: {
            fontSize: '0.8rem!important',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          [`&.${formHelperTextClasses.error}`]: {
            marginLeft: 0,
          },
        },
      },
    },
  },
});

export default customTheme;
