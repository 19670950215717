import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      height={'4rem'}
      paddingTop={2}
      marginTop={'auto'}
      bgcolor={'#222'}
      color="white"
      display={'flex'}
      justifyContent={'center'}
    >
      <Link color="inherit" href="https://suomenpakkaustuottajat.fi/tietosuojaseloste">
        {t('frontpage.privacyStatement')}
      </Link>
    </Box>
  );
};

export default Footer;
