import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Container, useTheme } from '@mui/system';

import { useAppSelector } from '../app/hooks';
import { selectUserLoggedIn } from '../app/services/userSlice';
import Header from './Header';
import Footer from './Footer';

interface LayoutProps {
  children: JSX.Element;
  title?: string;
  noBg?: boolean;
  center?: boolean;
  isLoginPage?: boolean;
}

const Layout = ({ children, title, noBg, center, isLoginPage }: LayoutProps) => {
  const theme = useTheme();
  const userLoggedIn = useAppSelector(selectUserLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userLoggedIn && !isLoginPage) {
      navigate('/login');
      return;
    }

    if (userLoggedIn && isLoginPage) {
      navigate('/');
      return;
    }
  }, [userLoggedIn, navigate, isLoginPage]);

  // Try to prevent flashing of the previous page upon redirect.
  // Note: This might still be a bit wonky, so thorough look throughout the whole RouteConfig
  // code might be a good idea.
  if ((!userLoggedIn && !isLoginPage) || (userLoggedIn && isLoginPage)) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Header />
      <Container
        sx={{
          marginTop: theme.spacing(4),
        }}
      >
        {title && (
          <Typography
            variant="h3"
            sx={{
              textAlign: center ? 'center' : 'left',
            }}
          >
            {title}
          </Typography>
        )}

        <Container
          sx={{
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(4),
            backgroundColor: noBg ? 'initial' : theme.palette.gray.main,
            minHeight: '50vh',
            alignContent: 'center',
          }}
        >
          {children}
        </Container>
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
