import React from 'react';

export const sptLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="349.875"
    height="72"
    className="spt-logo"
    viewBox="0 0 349.875 72"
  >
    <g data-name="Layer_1-2">
      <path
        data-name="Path 203"
        d="M74.772 32.422c-.262.935-1.871 5.61-8.84 10.473A33.37 33.37 0 0 1 52.18 48.5l5.445-18.374a35.6 35.6 0 0 0-1-18.935 31.814 31.814 0 0 0-4.118-8.411 36.154 36.154 0 0 1 22.265 29.642z"
        transform="translate(-3.368 -.179)"
        style={{ fill: '#0071bc' }}
      ></path>
      <path
        data-name="Path 204"
        d="M52.034 26.64 36.458 15.9c-1.16-.767-7.484-4.675-17.5-4.9h-.992A30.4 30.4 0 0 0 9.6 12.053 35.066 35.066 0 0 1 36.4 0a36.1 36.1 0 0 1 6.726.636A12.893 12.893 0 0 1 46.2 3.488a26.971 26.971 0 0 1 4.472 8.565 33.521 33.521 0 0 1 1.362 14.587z"
        transform="translate(-.621 .002)"
        style={{ fill: '#0071bc' }}
      ></path>
      <path
        data-name="Path 205"
        d="M73.314 41.89A35.963 35.963 0 0 1 50.4 71.952c-1.3 0-6.034-.411-12.4-5.788A33.438 33.438 0 0 1 29.29 55.1l20.057-.524c.337 0 8.288-.3 17.045-6.368a32.46 32.46 0 0 0 6.923-6.321z"
        transform="translate(-1.891 -2.281)"
        style={{ fill: '#0071bc' }}
      ></path>
      <path
        data-name="Path 206"
        d="M42.431 73.382a36.69 36.69 0 0 1-6.071.5A35.7 35.7 0 0 1 8.753 60.9c-.318-.935-1.6-5.695 1.5-13.624A33.829 33.829 0 0 1 17.6 35.83l6.271 17.77c.094.327 2.479 7.948 10.6 14.774a31.5 31.5 0 0 0 7.961 5.012z"
        transform="translate(-.532 -1.887)"
        style={{ fill: '#0071bc' }}
      ></path>
      <path
        data-name="Path 207"
        d="M31.8 18.524 15.9 30.942a35.9 35.9 0 0 0-9.361 13.782 32.187 32.187 0 0 0-2.208 9.108 35.753 35.753 0 0 1 .6-35.168c.73-.58 4.537-3.254 12.414-3.254h.935a33.439 33.439 0 0 1 13.52 3.114z"
        transform="translate(-.002 -.809)"
        style={{ fill: '#0071bc' }}
      ></path>
      <path
        data-name="Path 208"
        d="M99.272 11.568a.934.934 0 0 1-.215.252.572.572 0 0 1-.281.075.752.752 0 0 1-.393-.15 8.4 8.4 0 0 0-.543-.337 4.657 4.657 0 0 0-.767-.337 3.059 3.059 0 0 0-1.048-.15 3.149 3.149 0 0 0-.935.14 2.167 2.167 0 0 0-.72.393 1.536 1.536 0 0 0-.43.6 1.83 1.83 0 0 0-.15.739 1.323 1.323 0 0 0 .271.851 2.2 2.2 0 0 0 .72.589 7.992 7.992 0 0 0 1.01.43c.384.112.776.243 1.169.383s.786.29 1.16.468a3.908 3.908 0 0 1 1.02.664 2.975 2.975 0 0 1 .711.982 3.2 3.2 0 0 1 .272 1.408 4.668 4.668 0 0 1-.309 1.683 3.791 3.791 0 0 1-.935 1.365 4.078 4.078 0 0 1-1.441.935 5.381 5.381 0 0 1-1.955.337 6.511 6.511 0 0 1-2.423-.486 6.217 6.217 0 0 1-1.048-.552 5.45 5.45 0 0 1-.87-.729l.636-1.047a.645.645 0 0 1 .215-.206.637.637 0 0 1 .29-.075.807.807 0 0 1 .477.2 5.917 5.917 0 0 0 .636.439 4.752 4.752 0 0 0 .936.439 3.363 3.363 0 0 0 1.254.206 2.7 2.7 0 0 0 1.871-.58 2.03 2.03 0 0 0 .645-1.6 1.447 1.447 0 0 0-.271-.935 2.1 2.1 0 0 0-.711-.6 5.674 5.674 0 0 0-1.02-.411l-1.16-.365a8.741 8.741 0 0 1-1.16-.449 3.668 3.668 0 0 1-1.01-.673 3.054 3.054 0 0 1-.72-1.029 3.971 3.971 0 0 1 .019-2.936 3.614 3.614 0 0 1 .833-1.216 4.3 4.3 0 0 1 1.347-.842 4.927 4.927 0 0 1 1.874-.313 6.127 6.127 0 0 1 2.133.365 4.759 4.759 0 0 1 1.665 1.038l-.645 1.038z"
      ></path>
      <path
        data-name="Path 209"
        d="M109.865 13.289v9.538h-1.216a.481.481 0 0 1-.515-.383l-.15-.888a6.234 6.234 0 0 1-.636.58 3.442 3.442 0 0 1-.7.449 3.81 3.81 0 0 1-1.74.393 3.348 3.348 0 0 1-1.385-.262 2.911 2.911 0 0 1-1.01-.739 3.13 3.13 0 0 1-.608-1.141 4.945 4.945 0 0 1-.206-1.477v-6.07h2.058v6.069a2.26 2.26 0 0 0 .44 1.5 1.676 1.676 0 0 0 1.356.533 2.6 2.6 0 0 0 1.235-.309 4.093 4.093 0 0 0 1.085-.832v-6.961h1.993z"
      ></path>
      <path
        data-name="Path 210"
        d="M116.21 13.138a5.143 5.143 0 0 1 1.871.346 4.188 4.188 0 0 1 1.459.982 4.585 4.585 0 0 1 .936 1.543 6.6 6.6 0 0 1 0 4.077 4.585 4.585 0 0 1-.936 1.543 4.213 4.213 0 0 1-1.459.991 4.954 4.954 0 0 1-1.871.346 5.057 5.057 0 0 1-1.927-.346 4.212 4.212 0 0 1-1.459-.991 4.149 4.149 0 0 1-.936-1.543 6.6 6.6 0 0 1 0-4.077 4.153 4.153 0 0 1 2.395-2.525 5.057 5.057 0 0 1 1.927-.346zm0 8.257a2.209 2.209 0 0 0 1.871-.87 4.113 4.113 0 0 0 .636-2.469 4.153 4.153 0 0 0-.636-2.487 2.209 2.209 0 0 0-1.871-.87 2.239 2.239 0 0 0-1.946.87 4.153 4.153 0 0 0-.636 2.487 4.032 4.032 0 0 0 .636 2.469 2.239 2.239 0 0 0 1.946.87z"
      ></path>
      <path
        data-name="Path 211"
        d="M122.538 22.825v-9.537h1.216a.5.5 0 0 1 .524.383l.131.832a7.547 7.547 0 0 1 .543-.552 3.412 3.412 0 0 1 .608-.43 2.985 2.985 0 0 1 .692-.281 3.254 3.254 0 0 1 .8-.1 2.829 2.829 0 0 1 .842.131 2.073 2.073 0 0 1 .674.374 2.382 2.382 0 0 1 .515.589 3.424 3.424 0 0 1 .355.776 3.134 3.134 0 0 1 .543-.851 2.735 2.735 0 0 1 .73-.58 3.578 3.578 0 0 1 .861-.337 4.48 4.48 0 0 1 .935-.1 3.842 3.842 0 0 1 1.375.243 2.845 2.845 0 0 1 1.02.7 3.173 3.173 0 0 1 .627 1.141 4.983 4.983 0 0 1 .215 1.534v6.069h-2.021v-6.072a2.329 2.329 0 0 0-.43-1.524 1.633 1.633 0 0 0-1.291-.5 2.131 2.131 0 0 0-.72.131 1.648 1.648 0 0 0-.589.383 1.692 1.692 0 0 0-.393.626 2.4 2.4 0 0 0-.15.888v6.069h-2.039v-6.073a2.309 2.309 0 0 0-.421-1.534 1.5 1.5 0 0 0-1.235-.5 2.037 2.037 0 0 0-1.038.281 3.707 3.707 0 0 0-.879.785v7.032h-2z"
      ></path>
      <path
        data-name="Path 212"
        d="M139.345 18.215a4.865 4.865 0 0 0 .243 1.412 2.854 2.854 0 0 0 .58.935 2.161 2.161 0 0 0 .861.58 2.873 2.873 0 0 0 1.123.2 3.4 3.4 0 0 0 1.02-.14 4.218 4.218 0 0 0 .739-.3 6.129 6.129 0 0 0 .543-.3.965.965 0 0 1 .421-.131.432.432 0 0 1 .365.187l.58.739a3.706 3.706 0 0 1-.814.711 4.374 4.374 0 0 1-.935.468 5.057 5.057 0 0 1-1.048.262 6.28 6.28 0 0 1-1.029.084 4.945 4.945 0 0 1-1.871-.337 3.983 3.983 0 0 1-1.469-.935 4.531 4.531 0 0 1-.936-1.6 6.284 6.284 0 0 1-.346-2.2 5.524 5.524 0 0 1 .309-1.87 4.285 4.285 0 0 1 2.32-2.506 4.574 4.574 0 0 1 1.871-.365 4.846 4.846 0 0 1 1.628.281 3.718 3.718 0 0 1 1.291.842 3.947 3.947 0 0 1 .842 1.346 5.043 5.043 0 0 1 .3 1.823 1.3 1.3 0 0 1-.094.626.43.43 0 0 1-.384.159l-6.109.028zm4.8-1.234a3.153 3.153 0 0 0-.15-.935 2.18 2.18 0 0 0-.43-.757 1.869 1.869 0 0 0-.692-.5 2.482 2.482 0 0 0-.935-.178 2.293 2.293 0 0 0-1.721.626 3.053 3.053 0 0 0-.8 1.758h4.734z"
      ></path>
      <path
        data-name="Path 213"
        d="M149.488 14.55a6.237 6.237 0 0 1 .636-.58 4.282 4.282 0 0 1 .711-.439 3.529 3.529 0 0 1 .8-.29 3.931 3.931 0 0 1 .935-.1 3.305 3.305 0 0 1 1.375.262 2.793 2.793 0 0 1 1 .739 3.3 3.3 0 0 1 .617 1.141 5.267 5.267 0 0 1 .206 1.477v6.069h-2v-6.072a2.217 2.217 0 0 0-.449-1.5 1.669 1.669 0 0 0-1.347-.533 2.646 2.646 0 0 0-1.254.309 4.276 4.276 0 0 0-1.066.851v6.948h-2.016v-9.544h1.216a.5.5 0 0 1 .524.383l.112.879z"
      ></path>
      <path
        data-name="Path 214"
        d="M166.972 9.25a7.237 7.237 0 0 1 2.283.309 4.5 4.5 0 0 1 1.6.86 3.537 3.537 0 0 1 .936 1.337 4.566 4.566 0 0 1 .309 1.73 4.726 4.726 0 0 1-.327 1.758 3.746 3.746 0 0 1-.936 1.375 4.491 4.491 0 0 1-1.609.935 7.175 7.175 0 0 1-2.226.327h-2v4.872h-2.24V9.25zm0 6.863a4.253 4.253 0 0 0 1.282-.187 2.461 2.461 0 0 0 .935-.533 2.085 2.085 0 0 0 .561-.823 3.042 3.042 0 0 0 .187-1.085 2.892 2.892 0 0 0-.187-1.057 1.949 1.949 0 0 0-.543-.795 2.38 2.38 0 0 0-.936-.5 4.084 4.084 0 0 0-1.299-.173h-2v5.152z"
      ></path>
      <path
        data-name="Path 215"
        d="M173.343 14.615a5.538 5.538 0 0 1 3.854-1.534 3.749 3.749 0 0 1 1.469.262 3 3 0 0 1 1.076.757 3.625 3.625 0 0 1 .664 1.15 4.447 4.447 0 0 1 .225 1.487v6.059h-.935a.8.8 0 0 1-.44-.094.736.736 0 0 1-.253-.355l-.2-.776a7.23 7.23 0 0 1-.72.58 4.085 4.085 0 0 1-.73.439 3.594 3.594 0 0 1-.814.262 4.472 4.472 0 0 1-.935.094 3.792 3.792 0 0 1-1.076-.159 2.292 2.292 0 0 1-.87-.486 2.213 2.213 0 0 1-.6-.8 2.725 2.725 0 0 1-.215-1.122 2.1 2.1 0 0 1 .3-1.075 2.8 2.8 0 0 1 .936-.935 6.477 6.477 0 0 1 1.787-.683 13.44 13.44 0 0 1 2.732-.309v-.636a2.245 2.245 0 0 0-.44-1.534 1.591 1.591 0 0 0-1.291-.5 3.15 3.15 0 0 0-.936.14 5.682 5.682 0 0 0-.683.318l-.515.309a.872.872 0 0 1-.477.15.588.588 0 0 1-.346-.112.724.724 0 0 1-.243-.262l-.327-.626zm5.332 4.077a14.709 14.709 0 0 0-1.815.168 4.9 4.9 0 0 0-1.2.355 1.5 1.5 0 0 0-.655.5 1.119 1.119 0 0 0-.206.636 1.477 1.477 0 0 0 .112.57.989.989 0 0 0 .3.4 1.175 1.175 0 0 0 .449.215 2.315 2.315 0 0 0 .561.075 3.216 3.216 0 0 0 1.356-.29 4.24 4.24 0 0 0 1.095-.86v-1.769z"
      ></path>
      <path
        data-name="Path 216"
        d="M184.777 8.894v8.023h.458a1.018 1.018 0 0 0 .337-.056.661.661 0 0 0 .271-.234l2.675-3.058a1.107 1.107 0 0 1 .309-.281.853.853 0 0 1 .43-.094h1.824l-3.218 3.628a2.435 2.435 0 0 1-.617.58 1.662 1.662 0 0 1 .327.3 2.05 2.05 0 0 1 .271.365l3.433 4.675h-1.8a1.046 1.046 0 0 1-.412-.084.62.62 0 0 1-.309-.29l-2.806-3.74a.806.806 0 0 0-.281-.281 1.091 1.091 0 0 0-.421-.056h-.5v4.451h-2.001V8.894z"
      ></path>
      <path
        data-name="Path 217"
        d="M194.386 8.894v8.023h.43a1.018 1.018 0 0 0 .337-.056.66.66 0 0 0 .271-.234l2.676-3.058a1.107 1.107 0 0 1 .309-.281.853.853 0 0 1 .43-.094h1.826l-3.256 3.628a2.434 2.434 0 0 1-.617.58 1.662 1.662 0 0 1 .327.3 2.05 2.05 0 0 1 .271.365l3.433 4.675h-1.8a1.046 1.046 0 0 1-.412-.084.691.691 0 0 1-.271-.318l-2.76-3.74a.806.806 0 0 0-.281-.281 1.091 1.091 0 0 0-.421-.056h-.5v4.451h-2.003V8.894h2.011z"
      ></path>
      <path
        data-name="Path 218"
        d="M201.683 14.615a5.48 5.48 0 0 1 3.854-1.534 3.733 3.733 0 0 1 1.459.262 2.891 2.891 0 0 1 1.076.757 3.481 3.481 0 0 1 .674 1.15 4.447 4.447 0 0 1 .225 1.487v6.059h-.935a.8.8 0 0 1-.44-.094.646.646 0 0 1-.253-.355l-.2-.776a9.44 9.44 0 0 1-.72.58 4.53 4.53 0 0 1-.739.439 3.181 3.181 0 0 1-.814.262 4.472 4.472 0 0 1-.936.094 3.907 3.907 0 0 1-1.085-.159 2.385 2.385 0 0 1-.861-.486 2.561 2.561 0 0 1-.58-.795 2.878 2.878 0 0 1-.206-1.122 2.18 2.18 0 0 1 .29-1.075 2.8 2.8 0 0 1 .935-.935 6.477 6.477 0 0 1 1.787-.683 13.455 13.455 0 0 1 2.741-.309v-.636a2.294 2.294 0 0 0-.44-1.534 1.591 1.591 0 0 0-1.291-.5 3.149 3.149 0 0 0-.935.14 5.68 5.68 0 0 0-.683.318l-.515.309a.872.872 0 0 1-.477.15.6.6 0 0 1-.355-.112.89.89 0 0 1-.234-.262l-.346-.636zm5.332 4.039a14.711 14.711 0 0 0-1.815.168 4.9 4.9 0 0 0-1.2.355 1.522 1.522 0 0 0-.664.5 1.11 1.11 0 0 0-.2.636 1.476 1.476 0 0 0 .112.57 1.033 1.033 0 0 0 .29.4 1.494 1.494 0 0 0 .421.215 2.33 2.33 0 0 0 .571.075 3.04 3.04 0 0 0 1.347-.29 4.285 4.285 0 0 0 1.1-.86l.028-1.767z"
      ></path>
      <path
        data-name="Path 219"
        d="M219.133 13.289v9.538h-1.216a.5.5 0 0 1-.515-.383l-.15-.888a6.137 6.137 0 0 1-.627.58 3.508 3.508 0 0 1-.711.449 4.05 4.05 0 0 1-.8.29 4.48 4.48 0 0 1-.936.1 3.348 3.348 0 0 1-1.385-.262 2.793 2.793 0 0 1-1-.739 3.3 3.3 0 0 1-.617-1.141 5.265 5.265 0 0 1-.206-1.477v-6.067h2.011v6.069a2.217 2.217 0 0 0 .449 1.5 1.669 1.669 0 0 0 1.347.533 2.637 2.637 0 0 0 1.244-.309 4.049 4.049 0 0 0 1.076-.832v-6.961h2.03z"
      ></path>
      <path
        data-name="Path 220"
        d="M227.037 15.018a.652.652 0 0 1-.168.187.507.507 0 0 1-.234.056.651.651 0 0 1-.346-.1l-.44-.215a3.564 3.564 0 0 0-.6-.224 2.849 2.849 0 0 0-.8-.094 2.408 2.408 0 0 0-.683.094 1.325 1.325 0 0 0-.514.252 1.114 1.114 0 0 0-.318.374 1.052 1.052 0 0 0-.112.477.816.816 0 0 0 .206.561 1.474 1.474 0 0 0 .543.383 4.5 4.5 0 0 0 .767.3l.879.281c.3.1.589.215.879.346a3.624 3.624 0 0 1 .767.477 2.282 2.282 0 0 1 .543.692 2.245 2.245 0 0 1 .206.991 3.309 3.309 0 0 1-.243 1.244 2.849 2.849 0 0 1-.711.991 3.519 3.519 0 0 1-1.169.655 4.822 4.822 0 0 1-1.59.234 4.472 4.472 0 0 1-.935-.094 5.232 5.232 0 0 1-1.656-.608 3.835 3.835 0 0 1-.636-.468l.468-.767a.6.6 0 0 1 .206-.224.615.615 0 0 1 .318-.075.691.691 0 0 1 .384.122l.449.281a3.732 3.732 0 0 0 .627.271 2.867 2.867 0 0 0 .935.131 2.736 2.736 0 0 0 .776-.112 1.524 1.524 0 0 0 .543-.29 1.235 1.235 0 0 0 .421-.935.922.922 0 0 0-.3-.58 1.783 1.783 0 0 0-.552-.4 4.593 4.593 0 0 0-.776-.3l-.879-.281c-.3-.1-.6-.215-.935-.346a3.258 3.258 0 0 1-.776-.5 2.1 2.1 0 0 1-.543-.739 2.423 2.423 0 0 1-.206-1.057 2.715 2.715 0 0 1 .225-1.085 2.659 2.659 0 0 1 .674-.935 3.545 3.545 0 0 1 1.095-.626 4.563 4.563 0 0 1 1.506-.224 4.859 4.859 0 0 1 1.749.309 3.833 3.833 0 0 1 1.319.842l-.365.739z"
      ></path>
      <path
        data-name="Path 221"
        d="M232.471 22.883a2.393 2.393 0 0 1-1.871-.673 2.582 2.582 0 0 1-.636-1.87v-5.564h-1.049a.4.4 0 0 1-.412-.43v-.814l1.544-.224.44-2.758a.538.538 0 0 1 .159-.262.426.426 0 0 1 .3-.094h1.113v3.132h2.647v1.449h-2.647v5.419a1.039 1.039 0 0 0 .253.785.948.948 0 0 0 .674.271 1.442 1.442 0 0 0 .4-.056l.29-.14a1.539 1.539 0 0 0 .206-.131.287.287 0 0 1 .44.131l.608.982a3.246 3.246 0 0 1-1.1.626 4.211 4.211 0 0 1-1.356.224z"
      ></path>
      <path
        data-name="Path 222"
        d="M244.026 13.289v9.538h-1.216a.481.481 0 0 1-.514-.383l-.15-.888a6.239 6.239 0 0 1-.636.58 3.788 3.788 0 0 1-1.506.739 3.931 3.931 0 0 1-.935.1 3.348 3.348 0 0 1-1.385-.262 2.911 2.911 0 0 1-1.01-.739 3.129 3.129 0 0 1-.608-1.141 4.944 4.944 0 0 1-.206-1.477v-6.067h2.011v6.069a2.313 2.313 0 0 0 .44 1.5 1.676 1.676 0 0 0 1.356.533 2.6 2.6 0 0 0 1.235-.309 4.093 4.093 0 0 0 1.085-.832v-6.961h2.039z"
      ></path>
      <path
        data-name="Path 223"
        d="M250.371 13.138a4.978 4.978 0 0 1 1.918.346 4.142 4.142 0 0 1 2.395 2.525 6.6 6.6 0 0 1 0 4.077 4.15 4.15 0 0 1-.936 1.543 4.213 4.213 0 0 1-1.459.991 5.523 5.523 0 0 1-3.836 0 4.171 4.171 0 0 1-2.4-2.534 6.6 6.6 0 0 1 0-4.077 4.123 4.123 0 0 1 2.4-2.525 4.978 4.978 0 0 1 1.918-.346zm0 8.257a2.215 2.215 0 0 0 1.927-.87 4.106 4.106 0 0 0 .627-2.469 4.248 4.248 0 0 0-.627-2.487 2.215 2.215 0 0 0-1.927-.87 2.239 2.239 0 0 0-1.946.87 4.154 4.154 0 0 0-.636 2.487 4.113 4.113 0 0 0 .636 2.469 2.239 2.239 0 0 0 1.946.87z"
      ></path>
      <path
        data-name="Path 224"
        d="M259.584 22.883a2.393 2.393 0 0 1-1.871-.673 2.582 2.582 0 0 1-.636-1.87v-5.564h-1.048a.4.4 0 0 1-.412-.43v-.814l1.544-.224.44-2.758a.538.538 0 0 1 .159-.262.426.426 0 0 1 .3-.094h1.024v3.132h4.21l.449-2.805a.463.463 0 0 1 .15-.262.426.426 0 0 1 .3-.094h1.029v3.131h2.647v1.449h-2.647v5.442a1.091 1.091 0 0 0 .253.785.948.948 0 0 0 .674.271 1.441 1.441 0 0 0 .4-.056l.3-.14a1.535 1.535 0 0 0 .206-.131.281.281 0 0 1 .168-.056.232.232 0 0 1 .159 0l.122.15.6.982a3.2 3.2 0 0 1-1.113.626 3.882 3.882 0 0 1-1.3.224 2.393 2.393 0 0 1-1.871-.673 2.582 2.582 0 0 1-.636-1.87v-5.523h-4.135v5.414a1.091 1.091 0 0 0 .253.785.948.948 0 0 0 .674.271 1.442 1.442 0 0 0 .4-.056l.29-.14a1.54 1.54 0 0 0 .206-.131.308.308 0 0 1 .178-.056.294.294 0 0 1 .271.187l.6.982a3.246 3.246 0 0 1-1.1.626 3.949 3.949 0 0 1-1.235.224z"
      ></path>
      <path
        data-name="Path 225"
        d="M269.293 14.615a5.424 5.424 0 0 1 3.854-1.534 3.734 3.734 0 0 1 1.459.262 2.891 2.891 0 0 1 1.076.757 3.3 3.3 0 0 1 .664 1.15 4.455 4.455 0 0 1 .234 1.487v6.059h-.935a.8.8 0 0 1-.44-.094.645.645 0 0 1-.253-.355l-.2-.776a9.44 9.44 0 0 1-.72.58 4.527 4.527 0 0 1-.739.439 3.593 3.593 0 0 1-.814.262 4.472 4.472 0 0 1-.935.094 3.908 3.908 0 0 1-1.085-.159 2.386 2.386 0 0 1-.861-.486 2.133 2.133 0 0 1-.58-.795 2.878 2.878 0 0 1-.206-1.122 2.181 2.181 0 0 1 .29-1.075 2.8 2.8 0 0 1 .935-.935 6.477 6.477 0 0 1 1.787-.683 13.455 13.455 0 0 1 2.741-.309v-.636a2.338 2.338 0 0 0-.365-1.515 1.633 1.633 0 0 0-1.291-.5 3.15 3.15 0 0 0-.935.14 4.533 4.533 0 0 0-.683.318l-.514.309a.872.872 0 0 1-.477.15.6.6 0 0 1-.355-.112.788.788 0 0 1-.234-.262l-.421-.655zm5.332 4.039a14.711 14.711 0 0 0-1.815.168 4.9 4.9 0 0 0-1.2.355 1.521 1.521 0 0 0-.664.5 1.11 1.11 0 0 0-.2.636 1.469 1.469 0 0 0 .1.57 1.133 1.133 0 0 0 .3.4 1.654 1.654 0 0 0 .449.215 2.33 2.33 0 0 0 .571.075 3.04 3.04 0 0 0 1.347-.29 4.284 4.284 0 0 0 1.1-.86v-1.768z"
      ></path>
      <path
        data-name="Path 226"
        d="M280.758 13.195v10.084a4.115 4.115 0 0 1-.159 1.122 2.321 2.321 0 0 1-1.394 1.543 3.578 3.578 0 0 1-1.328.224 3.1 3.1 0 0 1-.589 0 3.692 3.692 0 0 1-.533-.131l.075-1.094a.211.211 0 0 1 .15-.215 2.116 2.116 0 0 1 .486 0 1.278 1.278 0 0 0 .992-.318 1.533 1.533 0 0 0 .29-1.057V13.195h2.011zm.327-2.88a1.266 1.266 0 0 1-.4.935 1.343 1.343 0 0 1-.43.29 1.288 1.288 0 0 1-.524.112 1.173 1.173 0 0 1-.505-.112 1.411 1.411 0 0 1-.711-.711 1.338 1.338 0 0 1 .29-1.468 1.611 1.611 0 0 1 .421-.29 1.318 1.318 0 0 1 .505-.1 1.451 1.451 0 0 1 .524.1 1.658 1.658 0 0 1 .43.29 1.545 1.545 0 0 1 .3.43 1.44 1.44 0 0 1 .094.524z"
      ></path>
      <path
        data-name="Path 227"
        d="M282.799 14.615a5.424 5.424 0 0 1 3.854-1.534 3.734 3.734 0 0 1 1.459.262 2.891 2.891 0 0 1 1.076.757 3.3 3.3 0 0 1 .664 1.15 4.456 4.456 0 0 1 .234 1.487v6.059h-.935a.8.8 0 0 1-.44-.094.645.645 0 0 1-.253-.355l-.2-.776a9.44 9.44 0 0 1-.72.58 4.529 4.529 0 0 1-.739.439 3.594 3.594 0 0 1-.814.262 4.472 4.472 0 0 1-.935.094 3.907 3.907 0 0 1-1.085-.159 2.386 2.386 0 0 1-.861-.486 2.133 2.133 0 0 1-.58-.795 2.878 2.878 0 0 1-.206-1.122 2.181 2.181 0 0 1 .29-1.075 2.8 2.8 0 0 1 .936-.935 6.476 6.476 0 0 1 1.787-.683 13.455 13.455 0 0 1 2.741-.309v-.636a2.294 2.294 0 0 0-.44-1.534 1.633 1.633 0 0 0-1.291-.5 3.15 3.15 0 0 0-.935.14 4.533 4.533 0 0 0-.683.318l-.515.309a.872.872 0 0 1-.477.15.6.6 0 0 1-.355-.112.788.788 0 0 1-.234-.262l-.346-.636zm5.332 4.077a14.711 14.711 0 0 0-1.815.168 4.9 4.9 0 0 0-1.2.355 1.521 1.521 0 0 0-.664.5 1.109 1.109 0 0 0-.2.636 1.469 1.469 0 0 0 .1.57 1.057 1.057 0 0 0 .3.4 1.654 1.654 0 0 0 .449.215 2.33 2.33 0 0 0 .571.075 3.04 3.04 0 0 0 1.347-.29 4.285 4.285 0 0 0 1.1-.86v-1.769z"
      ></path>
      <path
        data-name="Path 228"
        d="M295.026 22.883a2.393 2.393 0 0 1-1.871-.673 2.623 2.623 0 0 1-.636-1.87v-5.564h-1.048a.418.418 0 0 1-.29-.112.4.4 0 0 1-.122-.318v-.814l1.544-.224.44-2.758a.538.538 0 0 1 .159-.262.426.426 0 0 1 .3-.094h1.029v3.132h2.647v1.449h-2.647v5.419a1.091 1.091 0 0 0 .253.785.948.948 0 0 0 .674.271 1.441 1.441 0 0 0 .4-.056l.29-.14a1.542 1.542 0 0 0 .206-.131.308.308 0 0 1 .178-.056.294.294 0 0 1 .271.187l.6.982a3.246 3.246 0 0 1-1.1.626 3.722 3.722 0 0 1-1.272.224z"
      ></path>
      <path
        data-name="Path 229"
        d="M316.121 16.001a7.772 7.772 0 0 1-.486 2.805 6.477 6.477 0 0 1-1.375 2.179 6.339 6.339 0 0 1-2.124 1.356 7.644 7.644 0 0 1-5.5 0 6.2 6.2 0 0 1-3.5-3.6 8.1 8.1 0 0 1 0-5.517 6.269 6.269 0 0 1 3.5-3.609 7.644 7.644 0 0 1 5.5 0 6.306 6.306 0 0 1 2.124 1.431 6.477 6.477 0 0 1 1.375 2.179 7.825 7.825 0 0 1 .486 2.776zm-2.236 0a6.534 6.534 0 0 0-.309-2.076 4.336 4.336 0 0 0-.936-1.571 3.949 3.949 0 0 0-1.413-.991 5.232 5.232 0 0 0-3.742 0 4.059 4.059 0 0 0-1.394.982 4.374 4.374 0 0 0-.936 1.58 7.074 7.074 0 0 0 0 4.18 4.3 4.3 0 0 0 .936 1.562 4.005 4.005 0 0 0 1.422.935 5.232 5.232 0 0 0 3.742 0 3.774 3.774 0 0 0 1.413-.935 4.3 4.3 0 0 0 .935-1.562 6.181 6.181 0 0 0 .281-2.1z"
      ></path>
      <path
        data-name="Path 230"
        d="m326.477 13.294-5.257 12.249a1.271 1.271 0 0 1-.253.337.622.622 0 0 1-.44.122h-1.487l1.759-3.818-3.892-8.89h1.75a.617.617 0 0 1 .384.112.871.871 0 0 1 .206.281l2.273 5.414c.056.159.1.309.15.458l.14.477c.047-.159.094-.318.15-.477a3.951 3.951 0 0 1 .168-.468l2.18-5.4a.6.6 0 0 1 .561-.393h1.609z"
      ></path>
      <path
        data-name="Path 231"
        d="M99.068 31.206v1.253h-5.473v3.983h4.677v1.253h-4.677v4.9h-1.553V31.206h7.026z"
      ></path>
      <path
        data-name="Path 232"
        d="M102.509 32.018a.914.914 0 0 1-.084.383 1.089 1.089 0 0 1-.225.318.949.949 0 0 1-.327.215.916.916 0 0 1-.767 0 .834.834 0 0 1-.318-.215 1.089 1.089 0 0 1-.225-.318.9.9 0 0 1-.075-.383.947.947 0 0 1 .075-.393 1.132 1.132 0 0 1 .225-.327 1.09 1.09 0 0 1 .318-.224 1.021 1.021 0 0 1 .767 0 1.083 1.083 0 0 1 .552.552.957.957 0 0 1 .084.392zm-.321 2.525v8.046h-1.422v-8.046z"
      ></path>
      <path
        data-name="Path 233"
        d="M104.376 42.606v-8.051h.842a.358.358 0 0 1 .384.29l.112.879a4.519 4.519 0 0 1 1.169-.935 3.212 3.212 0 0 1 2.666-.14 2.121 2.121 0 0 1 .842.617 3.051 3.051 0 0 1 .515.935 4.757 4.757 0 0 1 .168 1.253v5.124h-1.413v-5.1a2.207 2.207 0 0 0-.412-1.421 1.572 1.572 0 0 0-1.282-.5 2.417 2.417 0 0 0-1.169.3 3.9 3.9 0 0 0-1.01.823v5.928z"
      ></path>
      <path data-name="Path 234" d="M114.58 30.887v11.71h-1.459v-11.71z"></path>
      <path
        data-name="Path 235"
        d="M122.631 42.606h-.636a.788.788 0 0 1-.327-.065.355.355 0 0 1-.168-.271l-.159-.748c-.215.2-.421.365-.617.514a3.962 3.962 0 0 1-.636.383 4.046 4.046 0 0 1-.72.234 4.123 4.123 0 0 1-.833.084 2.792 2.792 0 0 1-.879-.14 1.9 1.9 0 0 1-.711-.393 1.8 1.8 0 0 1-.486-.664 2.479 2.479 0 0 1-.178-.935 1.831 1.831 0 0 1 .262-.935 2.293 2.293 0 0 1 .851-.785 5.273 5.273 0 0 1 1.525-.57 10.774 10.774 0 0 1 2.32-.215v-.635a2.147 2.147 0 0 0-.4-1.421 1.467 1.467 0 0 0-1.188-.486 2.473 2.473 0 0 0-.907.112 3.718 3.718 0 0 0-.617.29l-.44.3a.784.784 0 0 1-.374.131.471.471 0 0 1-.253-.075.652.652 0 0 1-.168-.187l-.253-.458a4.718 4.718 0 0 1 1.441-.935 4.453 4.453 0 0 1 1.712-.318 3.27 3.27 0 0 1 1.2.215 2.446 2.446 0 0 1 .879.626 2.611 2.611 0 0 1 .543.935 3.951 3.951 0 0 1 .187 1.234l.037 5.18zm-3.676-.87a2.781 2.781 0 0 0 .683-.075 2.543 2.543 0 0 0 .58-.215 3.194 3.194 0 0 0 .533-.337 5.532 5.532 0 0 0 .486-.449v-1.663a9.909 9.909 0 0 0-1.665.122 5.079 5.079 0 0 0-1.113.327 1.626 1.626 0 0 0-.627.468 1.083 1.083 0 0 0-.187.608 1.408 1.408 0 0 0 .1.552.929.929 0 0 0 .271.383 1.062 1.062 0 0 0 .421.215 1.894 1.894 0 0 0 .515.065z"
      ></path>
      <path
        data-name="Path 236"
        d="M124.633 42.606v-8.051h.851a.349.349 0 0 1 .374.29l.112.879a4.563 4.563 0 0 1 1.179-.935 2.982 2.982 0 0 1 1.5-.365 2.882 2.882 0 0 1 1.16.224 2.12 2.12 0 0 1 .842.617 2.546 2.546 0 0 1 .515.935 4.06 4.06 0 0 1 .178 1.253v5.124h-1.366v-5.1a2.215 2.215 0 0 0-.425-1.413 1.565 1.565 0 0 0-1.272-.5 2.48 2.48 0 0 0-1.179.3 4.02 4.02 0 0 0-1 .823v5.928h-1.469z"
      ></path>
      <path
        data-name="Path 237"
        d="M139.143 42.597a.367.367 0 0 1-.384-.29l-.122-.935a4 4 0 0 1-1.169.963 3.06 3.06 0 0 1-1.516.374 2.923 2.923 0 0 1-1.263-.271 2.688 2.688 0 0 1-.936-.776 3.807 3.807 0 0 1-.608-1.29 6.7 6.7 0 0 1-.215-1.777 5.809 5.809 0 0 1 .243-1.655 3.983 3.983 0 0 1 .683-1.328 3.194 3.194 0 0 1 1.095-.935 3.287 3.287 0 0 1 1.459-.318 2.723 2.723 0 0 1 2.208.935v-4.4h1.413v11.703zm-2.741-1.038a2.288 2.288 0 0 0 1.207-.318 3.184 3.184 0 0 0 .936-.935v-3.855a2.194 2.194 0 0 0-.861-.739 2.44 2.44 0 0 0-1.029-.206 2.017 2.017 0 0 0-1.731.8 3.784 3.784 0 0 0-.608 2.282 5.932 5.932 0 0 0 .14 1.346 2.843 2.843 0 0 0 .393.935 1.626 1.626 0 0 0 .645.533 2.157 2.157 0 0 0 .907.15z"
      ></path>
      <path
        data-name="Path 238"
        d="M146.748 35.883a.335.335 0 0 1-.3.178.59.59 0 0 1-.271-.094l-.365-.2a5.021 5.021 0 0 0-.524-.2 2.605 2.605 0 0 0-.711-.094 2.157 2.157 0 0 0-.646.094 1.608 1.608 0 0 0-.486.252 1.082 1.082 0 0 0-.318.365 1.093 1.093 0 0 0-.1.458.825.825 0 0 0 .178.524 1.982 1.982 0 0 0 .477.355 3.383 3.383 0 0 0 .664.262l.767.252a7.59 7.59 0 0 1 .767.29 2.7 2.7 0 0 1 .674.393 1.889 1.889 0 0 1 .468.589 1.714 1.714 0 0 1 .178.832 2.754 2.754 0 0 1-.2 1.029 2.3 2.3 0 0 1-.589.814 2.758 2.758 0 0 1-.936.542 4.279 4.279 0 0 1-2.835-.075 3.668 3.668 0 0 1-1.16-.7l.337-.542a.605.605 0 0 1 .15-.159.507.507 0 0 1 .234-.056.492.492 0 0 1 .3.112l.384.252a4.125 4.125 0 0 0 .552.243 2.36 2.36 0 0 0 .8.112 2.163 2.163 0 0 0 .73-.112 1.407 1.407 0 0 0 .515-.29 1.259 1.259 0 0 0 .309-.421 1.342 1.342 0 0 0 .094-.5.848.848 0 0 0-.178-.552 1.5 1.5 0 0 0-.468-.374 3.5 3.5 0 0 0-.674-.271l-.776-.243a7.592 7.592 0 0 1-.767-.29 2.77 2.77 0 0 1-.674-.411 1.886 1.886 0 0 1-.477-.608 2.054 2.054 0 0 1-.178-.879 2.173 2.173 0 0 1 .748-1.627 2.548 2.548 0 0 1 .936-.514 3.385 3.385 0 0 1 1.216-.2 3.949 3.949 0 0 1 1.431.252 3.348 3.348 0 0 1 1.095.692l-.374.514z"
      ></path>
      <path
        data-name="Path 239"
        d="M159.363 31.206v1.253h-5.473v3.983h4.677v1.253h-4.677v4.9h-1.553V31.206h7.026z"
      ></path>
      <path
        data-name="Path 240"
        d="M164.021 34.415a4.227 4.227 0 0 1 1.6.3 3.489 3.489 0 0 1 1.207.832 3.752 3.752 0 0 1 .758 1.309 5.654 5.654 0 0 1 0 3.432 3.712 3.712 0 0 1-.758 1.3 3.367 3.367 0 0 1-1.207.832 4.253 4.253 0 0 1-1.6.29 4.205 4.205 0 0 1-1.59-.29 3.4 3.4 0 0 1-1.216-.832 3.6 3.6 0 0 1-.767-1.3 5.655 5.655 0 0 1 0-3.432 3.558 3.558 0 0 1 .767-1.309 3.4 3.4 0 0 1 1.216-.832 4.04 4.04 0 0 1 1.59-.3zm0 7.191a2.071 2.071 0 0 0 1.787-.8 3.739 3.739 0 0 0 .58-2.225 3.8 3.8 0 0 0-.58-2.244 2.071 2.071 0 0 0-1.787-.8 2.684 2.684 0 0 0-1.048.206 1.907 1.907 0 0 0-.739.6 2.546 2.546 0 0 0-.449.935 4.946 4.946 0 0 0-.15 1.281 5.387 5.387 0 0 0 .15 1.281 2.346 2.346 0 0 0 .449.935 1.907 1.907 0 0 0 .739.6 2.685 2.685 0 0 0 1.048.243zm-.524-9.257a.721.721 0 0 1-.075.346.952.952 0 0 1-.2.281 1.184 1.184 0 0 1-.29.187.886.886 0 0 1-.346.075.841.841 0 0 1-.337-.075 1.131 1.131 0 0 1-.281-.187 1.505 1.505 0 0 1-.2-.281.947.947 0 0 1 0-.692 1.577 1.577 0 0 1 .2-.29.953.953 0 0 1 .281-.2.841.841 0 0 1 .337-.075.886.886 0 0 1 .346.075.949.949 0 0 1 .486.486.746.746 0 0 1 .075.346zm2.853 0a.875.875 0 0 1-.065.346 1.166 1.166 0 0 1-.2.281 1.184 1.184 0 0 1-.29.187.838.838 0 0 1-.692 0 .86.86 0 0 1-.468-.468.721.721 0 0 1-.075-.346.746.746 0 0 1 .075-.346.86.86 0 0 1 .187-.29.953.953 0 0 1 .281-.2.893.893 0 0 1 .982.2.994.994 0 0 1 .2.29.875.875 0 0 1 .065.346z"
      ></path>
      <path
        data-name="Path 241"
        d="M169.474 42.605v-8.051h.776a.482.482 0 0 1 .318.084.619.619 0 0 1 .112.309l.1 1.253a3.713 3.713 0 0 1 1.02-1.318 2.324 2.324 0 0 1 1.431-.468 2.146 2.146 0 0 1 .6.075 2.386 2.386 0 0 1 .505.206l-.187 1.057a.242.242 0 0 1-.243.206 1.1 1.1 0 0 1-.346-.084 2.142 2.142 0 0 0-.645-.075 1.861 1.861 0 0 0-1.235.43 3.24 3.24 0 0 0-.833 1.253v5.124h-1.373z"
      ></path>
      <path
        data-name="Path 242"
        d="M175.685 45.346V34.565h.842a.358.358 0 0 1 .384.29l.122.935a4.361 4.361 0 0 1 1.179-1.01 3.1 3.1 0 0 1 1.525-.374 3.057 3.057 0 0 1 1.263.262 2.714 2.714 0 0 1 .936.785 3.807 3.807 0 0 1 .608 1.29 6.7 6.7 0 0 1 .215 1.777 5.809 5.809 0 0 1-.243 1.655 3.9 3.9 0 0 1-.683 1.328 3.089 3.089 0 0 1-1.085.879 3.455 3.455 0 0 1-2.741.084 3 3 0 0 1-.936-.7v3.563l-1.385.019zm3.583-9.781a2.288 2.288 0 0 0-1.207.318 3.7 3.7 0 0 0-.936.935v3.9a2.345 2.345 0 0 0 .861.739 2.492 2.492 0 0 0 1.038.215 2.048 2.048 0 0 0 1.721-.8 3.731 3.731 0 0 0 .608-2.291 6.014 6.014 0 0 0-.14-1.356 2.6 2.6 0 0 0-.4-.935 1.669 1.669 0 0 0-.645-.524 2.129 2.129 0 0 0-.9-.2z"
      ></path>
      <path
        data-name="Path 243"
        d="M190.222 42.606h-.627a.832.832 0 0 1-.337-.065.355.355 0 0 1-.168-.271l-.159-.748c-.215.2-.421.365-.617.514a3.959 3.959 0 0 1-.636.383 3.724 3.724 0 0 1-.711.234 4.285 4.285 0 0 1-.842.084 2.791 2.791 0 0 1-.879-.14 1.9 1.9 0 0 1-.711-.393 1.615 1.615 0 0 1-.477-.664 2.319 2.319 0 0 1-.187-.935 1.831 1.831 0 0 1 .262-.935 2.293 2.293 0 0 1 .851-.785 5.619 5.619 0 0 1 1.534-.57 10.634 10.634 0 0 1 2.311-.215v-.635a2.147 2.147 0 0 0-.4-1.421 1.467 1.467 0 0 0-1.188-.486 2.727 2.727 0 0 0-.87.131 3.717 3.717 0 0 0-.617.29l-.44.3a.783.783 0 0 1-.374.131.471.471 0 0 1-.253-.075.651.651 0 0 1-.168-.187l-.215-.477a4.719 4.719 0 0 1 1.441-.935 4.453 4.453 0 0 1 1.712-.318 3.27 3.27 0 0 1 1.2.215 2.446 2.446 0 0 1 .879.626 2.611 2.611 0 0 1 .543.935 3.952 3.952 0 0 1 .187 1.234l-.037 5.18zm-3.676-.87a2.85 2.85 0 0 0 1.272-.29 3.116 3.116 0 0 0 .524-.337 5.53 5.53 0 0 0 .486-.449v-1.663a9.909 9.909 0 0 0-1.665.122 5.079 5.079 0 0 0-1.113.327 1.626 1.626 0 0 0-.627.468 1.083 1.083 0 0 0-.187.608 1.408 1.408 0 0 0 .1.552.95.95 0 0 0 .281.383 1.026 1.026 0 0 0 .412.215 1.894 1.894 0 0 0 .514.065z"
      ></path>
      <path
        data-name="Path 244"
        d="M197.818 35.986a.437.437 0 0 1-.131.131.288.288 0 0 1-.178.056.458.458 0 0 1-.262-.1 2.835 2.835 0 0 0-.355-.224 3.521 3.521 0 0 0-.515-.215 2.6 2.6 0 0 0-.758-.1 2.491 2.491 0 0 0-1.038.215 2.005 2.005 0 0 0-.758.608 2.725 2.725 0 0 0-.458.935 4.766 4.766 0 0 0-.159 1.262 4.678 4.678 0 0 0 .168 1.3 2.748 2.748 0 0 0 .468.935 2.066 2.066 0 0 0 .739.589 2.276 2.276 0 0 0 .936.206 2.614 2.614 0 0 0 .851-.131 2.33 2.33 0 0 0 .552-.271 3.693 3.693 0 0 0 .365-.271.454.454 0 0 1 .29-.122.28.28 0 0 1 .271.131l.393.514a3.05 3.05 0 0 1-1.31.935 4.811 4.811 0 0 1-1.665.3 3.581 3.581 0 0 1-1.4-.281 3.215 3.215 0 0 1-1.123-.8 3.69 3.69 0 0 1-.748-1.3 5.091 5.091 0 0 1-.281-1.758 5.611 5.611 0 0 1 .253-1.664 3.767 3.767 0 0 1 .73-1.309 3.349 3.349 0 0 1 1.2-.87 4.021 4.021 0 0 1 1.614-.319 3.736 3.736 0 0 1 1.5.281 3.427 3.427 0 0 1 1.151.776l-.337.561z"
      ></path>
      <path
        data-name="Path 245"
        d="M201.026 30.887v6.891h.365a.566.566 0 0 0 .486-.224l2.545-2.721a1.336 1.336 0 0 1 .243-.215.615.615 0 0 1 .318-.075h1.292l-2.975 3.154a1.336 1.336 0 0 1-.215.243 1.47 1.47 0 0 1-.243.178 1.509 1.509 0 0 1 .262.224 2.22 2.22 0 0 1 .215.281l3.156 3.974h-1.272a.665.665 0 0 1-.3-.065.856.856 0 0 1-.234-.215l-2.647-3.3a.48.48 0 0 0-.6-.215h-.393v3.843h-1.434V30.887z"
      ></path>
      <path
        data-name="Path 246"
        d="M207.714 42.606v-8.051h.842a.367.367 0 0 1 .384.29l.112.879a4.519 4.519 0 0 1 1.169-.935 3.212 3.212 0 0 1 2.666-.14 2.121 2.121 0 0 1 .842.617 3.052 3.052 0 0 1 .515.935 4.757 4.757 0 0 1 .168 1.253v5.124h-1.413v-5.1a2.215 2.215 0 0 0-.421-1.421 1.553 1.553 0 0 0-1.272-.5 2.417 2.417 0 0 0-1.169.3 3.9 3.9 0 0 0-1.01.823v5.928z"
      ></path>
      <path
        data-name="Path 247"
        d="M218.236 32.018a.914.914 0 0 1-.084.383 1.089 1.089 0 0 1-.225.318.949.949 0 0 1-.327.215.916.916 0 0 1-.767 0 .834.834 0 0 1-.318-.215 1.09 1.09 0 0 1-.225-.318.9.9 0 0 1-.075-.383.947.947 0 0 1 .075-.393 1.133 1.133 0 0 1 .225-.327 1.09 1.09 0 0 1 .318-.224 1.021 1.021 0 0 1 .767 0 1.083 1.083 0 0 1 .552.552.957.957 0 0 1 .084.392zm-.318 2.525v8.046h-1.422v-8.046z"
      ></path>
      <path
        data-name="Path 248"
        d="M220.105 42.606v-8.051h.842a.367.367 0 0 1 .384.29l.112.879a4.52 4.52 0 0 1 1.169-.935 3.212 3.212 0 0 1 2.666-.14 2.12 2.12 0 0 1 .842.617 3.052 3.052 0 0 1 .515.935 4.757 4.757 0 0 1 .168 1.253v5.124h-1.413v-5.1a2.215 2.215 0 0 0-.421-1.421 1.553 1.553 0 0 0-1.272-.5 2.417 2.417 0 0 0-1.169.3 3.9 3.9 0 0 0-1.01.823v5.928z"
      ></path>
      <path
        data-name="Path 249"
        d="M231.528 34.425a3.873 3.873 0 0 1 .982.122 3.053 3.053 0 0 1 .833.337h2.189v.524q0 .266-.337.337l-.936.122a2.378 2.378 0 0 1 .271 1.159 2.433 2.433 0 0 1-.851 1.87 2.9 2.9 0 0 1-.935.524 3.841 3.841 0 0 1-1.216.187 4.071 4.071 0 0 1-1.066-.14 1.391 1.391 0 0 0-.384.355.717.717 0 0 0-.131.365.489.489 0 0 0 .234.449 1.6 1.6 0 0 0 .608.224 5.451 5.451 0 0 0 .87.065h.992a5.806 5.806 0 0 1 .982.084 2.891 2.891 0 0 1 .87.29 1.67 1.67 0 0 1 .617.552 1.712 1.712 0 0 1 .225.935 2.1 2.1 0 0 1-.253 1.01 2.785 2.785 0 0 1-.748.87 3.788 3.788 0 0 1-1.188.617 5.053 5.053 0 0 1-1.581.224 6.563 6.563 0 0 1-1.544-.168 3.871 3.871 0 0 1-1.1-.477 2.144 2.144 0 0 1-.655-.673 1.679 1.679 0 0 1-.215-.814 1.427 1.427 0 0 1 .374-1.01 2.6 2.6 0 0 1 1.038-.664 1.442 1.442 0 0 1-.543-.43 1.077 1.077 0 0 1-.206-.711.921.921 0 0 1 .065-.355 1.1 1.1 0 0 1 .2-.374 1.952 1.952 0 0 1 .767-.636 2.534 2.534 0 0 1-1.272-2.225 2.638 2.638 0 0 1 .225-1.075 2.346 2.346 0 0 1 .636-.814 2.713 2.713 0 0 1 .935-.524 3.8 3.8 0 0 1 1.254-.131zm2.517 8.6a.742.742 0 0 0-.168-.5 1.069 1.069 0 0 0-.458-.29 3.164 3.164 0 0 0-.655-.15 5.509 5.509 0 0 0-.786 0h-.851a3.067 3.067 0 0 1-.823-.112 2.543 2.543 0 0 0-.73.524 1.06 1.06 0 0 0-.29.748.956.956 0 0 0 .14.5 1.182 1.182 0 0 0 .43.4 2.487 2.487 0 0 0 .72.271 4.66 4.66 0 0 0 1.02.1 4.753 4.753 0 0 0 1.029-.112 2.474 2.474 0 0 0 .767-.3 1.4 1.4 0 0 0 .477-.458 1.092 1.092 0 0 0 .178-.636zm-2.517-4.349a2.3 2.3 0 0 0 .758-.122 1.39 1.39 0 0 0 .889-.842 1.925 1.925 0 0 0 .112-.655 1.569 1.569 0 0 0-.449-1.178 2.148 2.148 0 0 0-2.61 0 1.569 1.569 0 0 0-.449 1.178 1.73 1.73 0 0 0 .122.655 1.444 1.444 0 0 0 .337.514 1.343 1.343 0 0 0 .543.327 2.06 2.06 0 0 0 .748.122z"
      ></path>
      <path
        data-name="Path 250"
        d="M241.586 35.883a.335.335 0 0 1-.3.178.59.59 0 0 1-.271-.094l-.365-.2a5.025 5.025 0 0 0-.524-.2 2.6 2.6 0 0 0-.711-.094 2.157 2.157 0 0 0-.646.094 1.608 1.608 0 0 0-.486.252 1.082 1.082 0 0 0-.318.365 1.094 1.094 0 0 0-.1.458.825.825 0 0 0 .178.524 1.982 1.982 0 0 0 .477.355 3.383 3.383 0 0 0 .664.262l.767.252a7.6 7.6 0 0 1 .767.29 2.7 2.7 0 0 1 .674.393 1.889 1.889 0 0 1 .468.589 1.713 1.713 0 0 1 .178.832 2.756 2.756 0 0 1-.2 1.029 2.3 2.3 0 0 1-.589.814 2.758 2.758 0 0 1-.935.542 4.279 4.279 0 0 1-2.835-.075 3.668 3.668 0 0 1-1.16-.7l.337-.542a.6.6 0 0 1 .15-.159.507.507 0 0 1 .234-.056.492.492 0 0 1 .3.112l.384.252a4.127 4.127 0 0 0 .552.243 2.36 2.36 0 0 0 .8.112 2.164 2.164 0 0 0 .73-.112 1.407 1.407 0 0 0 .514-.29 1.143 1.143 0 0 0 .309-.421 1.342 1.342 0 0 0 .094-.5.848.848 0 0 0-.178-.552 1.5 1.5 0 0 0-.468-.374 3.5 3.5 0 0 0-.674-.271l-.776-.243a7.593 7.593 0 0 1-.767-.29 2.768 2.768 0 0 1-.674-.411 1.886 1.886 0 0 1-.477-.608 2.055 2.055 0 0 1-.178-.879 2.173 2.173 0 0 1 .748-1.627 2.548 2.548 0 0 1 .935-.514 3.385 3.385 0 0 1 1.216-.2 3.949 3.949 0 0 1 1.431.252 3.347 3.347 0 0 1 1.095.692z"
      ></path>
      <path
        data-name="Path 251"
        d="M243.64 45.346V34.565h.842a.358.358 0 0 1 .384.29l.122.935a4.36 4.36 0 0 1 1.179-1.01 3.1 3.1 0 0 1 1.525-.374 3.057 3.057 0 0 1 1.263.262 2.714 2.714 0 0 1 .935.785 3.806 3.806 0 0 1 .608 1.29 6.7 6.7 0 0 1 .215 1.777 5.808 5.808 0 0 1-.243 1.655 3.9 3.9 0 0 1-.683 1.328 3.039 3.039 0 0 1-1.095.879 3.109 3.109 0 0 1-1.45.327 3.159 3.159 0 0 1-1.282-.243 3 3 0 0 1-.936-.7v3.563zm3.583-9.781a2.288 2.288 0 0 0-1.207.318 3.7 3.7 0 0 0-.935.935v3.9a2.345 2.345 0 0 0 .861.739 2.492 2.492 0 0 0 1.038.215 2.048 2.048 0 0 0 1.721-.8 3.732 3.732 0 0 0 .608-2.291 6.01 6.01 0 0 0-.14-1.356 2.778 2.778 0 0 0-.4-.935 1.669 1.669 0 0 0-.646-.524 2.129 2.129 0 0 0-.9-.2z"
      ></path>
      <path
        data-name="Path 252"
        d="M252.367 42.605v-8.051h.814a.482.482 0 0 1 .318.084.619.619 0 0 1 .112.309l.1 1.253a3.609 3.609 0 0 1 1.02-1.318 2.3 2.3 0 0 1 1.422-.468 2.212 2.212 0 0 1 .608.075 1.917 1.917 0 0 1 .5.206l-.178 1.057a.242.242 0 0 1-.243.206.9.9 0 0 1-.346-.084 2.142 2.142 0 0 0-.645-.075 1.861 1.861 0 0 0-1.235.43 3.24 3.24 0 0 0-.833 1.253v5.124h-1.414z"
      ></path>
      <path
        data-name="Path 253"
        d="M261.726 34.424a4.227 4.227 0 0 1 1.6.3 3.489 3.489 0 0 1 1.207.832 3.752 3.752 0 0 1 .752 1.308 5.655 5.655 0 0 1 0 3.432 3.713 3.713 0 0 1-.758 1.3 3.367 3.367 0 0 1-1.207.832 4.253 4.253 0 0 1-1.6.29 4.205 4.205 0 0 1-1.59-.29 3.4 3.4 0 0 1-1.216-.832 3.6 3.6 0 0 1-.767-1.3 5.655 5.655 0 0 1 0-3.432 3.559 3.559 0 0 1 .767-1.309 3.367 3.367 0 0 1 1.244-.823 4.082 4.082 0 0 1 1.568-.308zm0 7.191a2.071 2.071 0 0 0 1.787-.8 3.738 3.738 0 0 0 .58-2.225 3.8 3.8 0 0 0-.58-2.244 2.071 2.071 0 0 0-1.787-.8 2.684 2.684 0 0 0-1.048.206 1.907 1.907 0 0 0-.739.6 2.546 2.546 0 0 0-.449.935 4.949 4.949 0 0 0-.15 1.281 5.389 5.389 0 0 0 .15 1.281 2.346 2.346 0 0 0 .449.935 1.907 1.907 0 0 0 .739.6 2.685 2.685 0 0 0 1.048.243z"
      ></path>
      <path
        data-name="Path 254"
        d="M272.994 42.597a.383.383 0 0 1-.384-.29l-.122-.935a4.36 4.36 0 0 1-1.179 1.01 3.1 3.1 0 0 1-1.525.374 2.923 2.923 0 0 1-1.263-.271 2.515 2.515 0 0 1-.935-.776 3.66 3.66 0 0 1-.617-1.29 6.7 6.7 0 0 1-.215-1.777 5.809 5.809 0 0 1 .243-1.655 4 4 0 0 1 .655-1.328 3.193 3.193 0 0 1 1.095-.935 3.287 3.287 0 0 1 1.459-.318 2.723 2.723 0 0 1 2.208.935v-4.454h1.41v11.71h-.833zm-2.741-1.038a2.3 2.3 0 0 0 1.216-.318 3.185 3.185 0 0 0 .935-.935v-3.855a2.316 2.316 0 0 0-.851-.739 2.483 2.483 0 0 0-1.038-.206 2.017 2.017 0 0 0-1.731.8 3.713 3.713 0 0 0-.6 2.282 5.438 5.438 0 0 0 .131 1.346 2.606 2.606 0 0 0 .4.935 1.472 1.472 0 0 0 .636.533 2.27 2.27 0 0 0 .9.15z"
      ></path>
      <path
        data-name="Path 255"
        d="M277.28 34.556v5.134a2.071 2.071 0 0 0 .421 1.412 1.565 1.565 0 0 0 1.272.5 2.417 2.417 0 0 0 1.169-.3 3.686 3.686 0 0 0 1.01-.814v-5.932h1.413v8.051h-.87a.383.383 0 0 1-.384-.29l-.1-.87a4.562 4.562 0 0 1-1.179.935 3.074 3.074 0 0 1-1.5.355 2.958 2.958 0 0 1-1.169-.224 2.12 2.12 0 0 1-.842-.617 2.545 2.545 0 0 1-.514-.935 4.289 4.289 0 0 1-.168-1.244v-5.161h1.441z"
      ></path>
      <path
        data-name="Path 256"
        d="M290.349 35.986a.865.865 0 0 1-.131.131.288.288 0 0 1-.178.056.458.458 0 0 1-.262-.1l-.355-.224a3.63 3.63 0 0 0-.524-.215 2.668 2.668 0 0 0-1.787.112 2 2 0 0 0-.758.608 2.748 2.748 0 0 0-.468.935 4.758 4.758 0 0 0-.15 1.262 4.677 4.677 0 0 0 .168 1.3 2.748 2.748 0 0 0 .468.935 2.117 2.117 0 0 0 .73.589 2.374 2.374 0 0 0 .982.206 2.614 2.614 0 0 0 .851-.131 2.332 2.332 0 0 0 .552-.271 3.7 3.7 0 0 0 .365-.271.411.411 0 0 1 .29-.122.309.309 0 0 1 .271.131l.393.514a3.049 3.049 0 0 1-1.31.935 4.811 4.811 0 0 1-1.665.3 3.581 3.581 0 0 1-1.4-.281 3.215 3.215 0 0 1-1.123-.8 3.748 3.748 0 0 1-.73-1.234 5.089 5.089 0 0 1-.281-1.758 5.61 5.61 0 0 1 .253-1.664 3.767 3.767 0 0 1 .73-1.309 3.386 3.386 0 0 1 1.169-.879 4.034 4.034 0 0 1 1.628-.309 3.858 3.858 0 0 1 1.487.281 3.374 3.374 0 0 1 1.16.776l-.374.5z"
      ></path>
      <path
        data-name="Path 257"
        d="M295.204 34.424a3.848 3.848 0 0 1 1.338.243 3.213 3.213 0 0 1 1.057.7 3.356 3.356 0 0 1 .692 1.131 4.208 4.208 0 0 1 .253 1.524.93.93 0 0 1-.075.449.3.3 0 0 1-.271.112h-5.36a4.573 4.573 0 0 0 .206 1.328 2.733 2.733 0 0 0 .533.935 2.088 2.088 0 0 0 .8.561 2.75 2.75 0 0 0 1.029.187 2.861 2.861 0 0 0 .935-.122 5.82 5.82 0 0 0 .664-.271 4.587 4.587 0 0 0 .468-.262.582.582 0 0 1 .318-.122.28.28 0 0 1 .271.131l.4.514a2.711 2.711 0 0 1-.627.552 3.858 3.858 0 0 1-.786.393 6.845 6.845 0 0 1-.861.224 5.606 5.606 0 0 1-.889.075 4.288 4.288 0 0 1-1.534-.281 3.4 3.4 0 0 1-1.216-.832 3.593 3.593 0 0 1-.8-1.346 5.741 5.741 0 0 1-.281-1.87 4.976 4.976 0 0 1 .253-1.571 3.805 3.805 0 0 1 .748-1.272 3.442 3.442 0 0 1 1.188-.842 3.836 3.836 0 0 1 1.553-.271zm0 1.047a2.168 2.168 0 0 0-1.609.589 2.775 2.775 0 0 0-.739 1.646h4.387a2.872 2.872 0 0 0-.14-.935 2.037 2.037 0 0 0-.393-.711 1.852 1.852 0 0 0-.646-.458 2.285 2.285 0 0 0-.833-.131z"
      ></path>
      <path
        data-name="Path 258"
        d="M300.181 42.606v-8.051h.842a.349.349 0 0 1 .374.29l.112.879a4.562 4.562 0 0 1 1.179-.935 2.982 2.982 0 0 1 1.5-.365 2.882 2.882 0 0 1 1.16.224 2.151 2.151 0 0 1 .851.617 2.667 2.667 0 0 1 .505.935 4.059 4.059 0 0 1 .178 1.253v5.124h-1.413v-5.1a2.215 2.215 0 0 0-.421-1.421 1.565 1.565 0 0 0-1.272-.5 2.48 2.48 0 0 0-1.179.3 4.019 4.019 0 0 0-1 .823v5.928h-1.416z"
      ></path>
      <path
        data-name="Path 259"
        d="M311.192 42.729a1.941 1.941 0 0 1-1.469-.533 2.127 2.127 0 0 1-.515-1.534v-4.927h-.934a.375.375 0 0 1-.215-.075.291.291 0 0 1-.084-.243v-.561l1.319-.168.327-2.487a.337.337 0 0 1 .1-.2.381.381 0 0 1 .224-.075h.711v2.805h2.318v1.029h-2.311v4.825a.975.975 0 0 0 .253.757.831.831 0 0 0 .627.243 1.425 1.425 0 0 0 .393-.056c.094-.037.187-.084.281-.131l.206-.131a.831.831 0 0 1 .14-.056.241.241 0 0 1 .206.131l.412.673a2.559 2.559 0 0 1-.889.542 2.887 2.887 0 0 1-1.1.172z"
      ></path>
      <path
        data-name="Path 260"
        d="M317.469 34.424a3.644 3.644 0 0 1 1.338.243 2.869 2.869 0 0 1 1.057.7 3.238 3.238 0 0 1 .7 1.131 4.207 4.207 0 0 1 .253 1.524.93.93 0 0 1-.075.449.3.3 0 0 1-.271.112h-5.36a4.572 4.572 0 0 0 .206 1.328 2.711 2.711 0 0 0 .524.935 2.088 2.088 0 0 0 .8.561 2.8 2.8 0 0 0 1.038.187 3.135 3.135 0 0 0 .935-.122 5.817 5.817 0 0 0 .664-.271 4.587 4.587 0 0 0 .468-.262.583.583 0 0 1 .318-.122.346.346 0 0 1 .271.131l.393.514a2.532 2.532 0 0 1-.627.552 4.036 4.036 0 0 1-.776.393 6.59 6.59 0 0 1-.87.224 5.582 5.582 0 0 1-.879.075 4.288 4.288 0 0 1-1.534-.281 3.358 3.358 0 0 1-1.226-.832 3.777 3.777 0 0 1-.8-1.346 5.508 5.508 0 0 1-.29-1.87 4.733 4.733 0 0 1 .262-1.571 3.573 3.573 0 0 1 .748-1.272 3.407 3.407 0 0 1 1.179-.842 3.836 3.836 0 0 1 1.553-.271zm0 1.047a2.166 2.166 0 0 0-1.618.589 2.789 2.789 0 0 0-.73 1.646h4.378a2.862 2.862 0 0 0-.131-.935 2.3 2.3 0 0 0-.393-.711 1.851 1.851 0 0 0-.646-.458 2.236 2.236 0 0 0-.823-.131h-.037z"
      ></path>
      <path
        data-name="Path 261"
        d="M322.446 42.605v-8.051h.814a.482.482 0 0 1 .318.084.632.632 0 0 1 .122.309l.094 1.253a3.77 3.77 0 0 1 1.01-1.318 2.324 2.324 0 0 1 1.431-.468 2.146 2.146 0 0 1 .6.075 2.386 2.386 0 0 1 .505.206l-.187 1.057a.242.242 0 0 1-.243.206 1.1 1.1 0 0 1-.346-.084 2.142 2.142 0 0 0-.645-.075 1.861 1.861 0 0 0-1.235.43 3.24 3.24 0 0 0-.833 1.253v5.124h-1.4z"
      ></path>
      <path
        data-name="Path 262"
        d="M341.52 42.595h-1.188a.533.533 0 0 1-.337-.1.572.572 0 0 1-.225-.29l-1.066-2.749h-5.117l-1.029 2.749a.561.561 0 0 1-.187.252.541.541 0 0 1-.337.112h-1.188l4.556-11.391h1.562l4.556 11.417zm-7.484-4.226h4.26l-1.787-4.675a8.472 8.472 0 0 1-.346-1.075c-.056.224-.112.421-.168.608s-.112.337-.168.477z"
      ></path>
      <path
        data-name="Path 263"
        d="M342.783 42.597v-11.71h1.422v4.816a4.282 4.282 0 0 1 1.151-.935 3.027 3.027 0 0 1 1.478-.355 3.132 3.132 0 0 1 1.272.262 2.539 2.539 0 0 1 .935.785 3.66 3.66 0 0 1 .617 1.29 6.628 6.628 0 0 1 .215 1.767 5.809 5.809 0 0 1-.243 1.655 3.9 3.9 0 0 1-.683 1.328 3.071 3.071 0 0 1-1.1.879 3.173 3.173 0 0 1-1.459.327 2.812 2.812 0 0 1-1.319-.3 3.125 3.125 0 0 1-.935-.851l-.065.739a.355.355 0 0 1-.365.3h-.917zm3.592-7.05a2.3 2.3 0 0 0-1.216.318 3.7 3.7 0 0 0-.935.935v3.9a2.167 2.167 0 0 0 .851.739 2.6 2.6 0 0 0 1.029.215 2.044 2.044 0 0 0 1.74-.8 3.757 3.757 0 0 0 .6-2.291 6.6 6.6 0 0 0-.131-1.356 2.78 2.78 0 0 0-.4-.935 1.669 1.669 0 0 0-.645-.524 2.087 2.087 0 0 0-.889-.2z"
      ></path>
      <path
        data-name="Path 264"
        d="M99.068 51.054v1.253h-5.473v3.983h4.677v1.253h-4.677v4.9h-1.553V51.054h7.026z"
      ></path>
      <path
        data-name="Path 265"
        d="M102.509 51.868a.914.914 0 0 1-.084.383 1.089 1.089 0 0 1-.225.318.949.949 0 0 1-.327.215.916.916 0 0 1-.767 0 .834.834 0 0 1-.318-.215 1.089 1.089 0 0 1-.225-.318.9.9 0 0 1-.075-.383.947.947 0 0 1 .075-.393 1.132 1.132 0 0 1 .225-.327 1.09 1.09 0 0 1 .318-.224 1.021 1.021 0 0 1 .767 0 1.083 1.083 0 0 1 .552.552.957.957 0 0 1 .084.392zm-.321 2.525v8.046h-1.422v-8.046h1.422z"
      ></path>
      <path
        data-name="Path 266"
        d="M104.376 62.444v-8.051h.842a.358.358 0 0 1 .384.29l.112.879a4.519 4.519 0 0 1 1.169-.935 3.212 3.212 0 0 1 2.666-.14 2.121 2.121 0 0 1 .842.617 3.051 3.051 0 0 1 .515.935 4.757 4.757 0 0 1 .168 1.253v5.124h-1.413v-5.1a2.207 2.207 0 0 0-.412-1.421 1.572 1.572 0 0 0-1.282-.5 2.417 2.417 0 0 0-1.169.3 3.9 3.9 0 0 0-1.01.823v5.928z"
      ></path>
      <path
        data-name="Path 267"
        d="M113.123 62.444v-8.051h.842a.358.358 0 0 1 .384.29l.112.879a4.563 4.563 0 0 1 1.179-.935 2.982 2.982 0 0 1 1.5-.365 2.882 2.882 0 0 1 1.16.224 2.121 2.121 0 0 1 .842.617 2.689 2.689 0 0 1 .515.935 4.06 4.06 0 0 1 .178 1.253v5.124h-1.413v-5.1a2.215 2.215 0 0 0-.421-1.421 1.565 1.565 0 0 0-1.272-.5 2.48 2.48 0 0 0-1.179.3 4.02 4.02 0 0 0-1 .823v5.928h-1.427z"
      ></path>
      <path
        data-name="Path 268"
        d="M123.571 51.868a.915.915 0 0 1-.084.383 1.089 1.089 0 0 1-.225.318.835.835 0 0 1-.318.215.958.958 0 0 1-.393.084 1.04 1.04 0 0 1-1-1 1.077 1.077 0 0 1 .3-.72 1.09 1.09 0 0 1 .318-.224 1.077 1.077 0 0 1 .384-.075 1.127 1.127 0 0 1 .393.075 1.09 1.09 0 0 1 .318.224 1.133 1.133 0 0 1 .225.327.957.957 0 0 1 .082.393zm-.318 2.525v8.046h-1.413v-8.046h1.413z"
      ></path>
      <path
        data-name="Path 269"
        d="M130.05 55.721a.335.335 0 0 1-.3.178.59.59 0 0 1-.271-.094l-.365-.2a5.021 5.021 0 0 0-.524-.2 2.605 2.605 0 0 0-.711-.094 2.157 2.157 0 0 0-.646.094 1.657 1.657 0 0 0-.5.252 1.148 1.148 0 0 0-.309.365 1.094 1.094 0 0 0-.1.458.825.825 0 0 0 .178.524 1.933 1.933 0 0 0 .468.355 3.467 3.467 0 0 0 .674.262l.767.252a7.593 7.593 0 0 1 .767.29 2.491 2.491 0 0 1 .664.393 1.947 1.947 0 0 1 .477.589 1.714 1.714 0 0 1 .178.832 2.755 2.755 0 0 1-.2 1.029 2.3 2.3 0 0 1-.589.814 2.758 2.758 0 0 1-.935.542 4.279 4.279 0 0 1-2.835-.075 3.668 3.668 0 0 1-1.16-.7l.337-.542a.408.408 0 0 1 .15-.159.507.507 0 0 1 .234-.056.492.492 0 0 1 .3.112l.384.252a4.125 4.125 0 0 0 .552.243 2.36 2.36 0 0 0 .8.112 2.425 2.425 0 0 0 .73-.112 1.407 1.407 0 0 0 .515-.29 1.12 1.12 0 0 0 .3-.421 1.163 1.163 0 0 0 .1-.5.848.848 0 0 0-.178-.552 1.439 1.439 0 0 0-.477-.374 3.417 3.417 0 0 0-.664-.271l-.776-.243a7.594 7.594 0 0 1-.767-.29 2.77 2.77 0 0 1-.674-.411 1.886 1.886 0 0 1-.477-.608 2.054 2.054 0 0 1-.178-.879 2.173 2.173 0 0 1 .748-1.627 2.548 2.548 0 0 1 .936-.514 3.385 3.385 0 0 1 1.216-.2 3.949 3.949 0 0 1 1.431.252 3.348 3.348 0 0 1 1.095.692l-.374.514z"
      ></path>
      <path
        data-name="Path 270"
        d="M132.107 62.447v-11.71h1.413v4.769a4.241 4.241 0 0 1 1.141-.935 3.007 3.007 0 0 1 1.45-.337 2.958 2.958 0 0 1 1.169.224 2.12 2.12 0 0 1 .842.617 3.051 3.051 0 0 1 .515.935 4.757 4.757 0 0 1 .168 1.253v5.124h-1.413V57.32a2.215 2.215 0 0 0-.421-1.421 1.553 1.553 0 0 0-1.272-.5 2.417 2.417 0 0 0-1.169.3 3.9 3.9 0 0 0-1.01.823v5.925z"
      ></path>
      <path
        data-name="Path 271"
        d="M145.948 58.18v4.264h-1.534V51.055h3.358a6.319 6.319 0 0 1 1.871.252 3.659 3.659 0 0 1 1.328.711 2.913 2.913 0 0 1 .786 1.113 3.85 3.85 0 0 1 .253 1.449 3.891 3.891 0 0 1-.281 1.459 2.975 2.975 0 0 1-.814 1.131 3.812 3.812 0 0 1-1.328.748 5.744 5.744 0 0 1-1.824.262h-1.815zm0-1.225h1.824a3.806 3.806 0 0 0 1.169-.168 2.45 2.45 0 0 0 .842-.486 2.247 2.247 0 0 0 .524-.757 2.666 2.666 0 0 0 .168-.935 2.194 2.194 0 0 0-.674-1.7 2.941 2.941 0 0 0-2.03-.608h-1.824v4.654z"
      ></path>
      <path
        data-name="Path 272"
        d="M159.426 62.443h-.627a.832.832 0 0 1-.337-.065.355.355 0 0 1-.168-.271l-.159-.748c-.215.2-.421.365-.617.514a3.384 3.384 0 0 1-.636.383 3.724 3.724 0 0 1-.711.234 4.286 4.286 0 0 1-.842.084 2.792 2.792 0 0 1-.879-.14 2.038 2.038 0 0 1-.711-.393 1.775 1.775 0 0 1-.477-.664 2.32 2.32 0 0 1-.187-.935 1.754 1.754 0 0 1 .271-.935 2.125 2.125 0 0 1 .842-.785 5.619 5.619 0 0 1 1.534-.57 10.458 10.458 0 0 1 2.348-.215v-.636a2.146 2.146 0 0 0-.4-1.421 1.467 1.467 0 0 0-1.188-.486 2.727 2.727 0 0 0-.87.131 3.717 3.717 0 0 0-.617.29l-.44.3a.7.7 0 0 1-.374.131.464.464 0 0 1-.243-.075.567.567 0 0 1-.178-.187l-.253-.458a4.719 4.719 0 0 1 1.441-.935 4.453 4.453 0 0 1 1.712-.318 3.27 3.27 0 0 1 1.2.215 2.445 2.445 0 0 1 .879.626 2.611 2.611 0 0 1 .543.935 3.952 3.952 0 0 1 .187 1.234l-.037 5.162zm-3.676-.87a2.85 2.85 0 0 0 1.272-.29 3.116 3.116 0 0 0 .524-.337 4.46 4.46 0 0 0 .486-.449v-1.663a9.908 9.908 0 0 0-1.665.122 5.057 5.057 0 0 0-1.1.29 1.6 1.6 0 0 0-.617.468 1.025 1.025 0 0 0-.2.608 1.408 1.408 0 0 0 .1.552.95.95 0 0 0 .281.383 1.026 1.026 0 0 0 .412.215 1.867 1.867 0 0 0 .505.1z"
      ></path>
      <path
        data-name="Path 273"
        d="M167.024 55.824a.808.808 0 0 1-.122.131.337.337 0 0 1-.187.056.458.458 0 0 1-.262-.1l-.346-.224a3.679 3.679 0 0 0-.543-.178 2.537 2.537 0 0 0-.748-.1 2.5 2.5 0 0 0-1.048.215 2.005 2.005 0 0 0-.758.608 2.725 2.725 0 0 0-.458.935 4.767 4.767 0 0 0-.159 1.262 4.678 4.678 0 0 0 .168 1.3 2.747 2.747 0 0 0 .468.935 2.066 2.066 0 0 0 .739.589 2.276 2.276 0 0 0 .935.206 2.614 2.614 0 0 0 .851-.131 2.021 2.021 0 0 0 .552-.271 3.693 3.693 0 0 0 .365-.271.454.454 0 0 1 .29-.122.28.28 0 0 1 .271.131l.393.514a3.05 3.05 0 0 1-1.31.935 4.8 4.8 0 0 1-1.656.3 3.581 3.581 0 0 1-1.4-.281 3.134 3.134 0 0 1-1.132-.8 3.929 3.929 0 0 1-.748-1.3 5.117 5.117 0 0 1-.271-1.758 5.6 5.6 0 0 1 .243-1.664 3.569 3.569 0 0 1 .739-1.309 3.36 3.36 0 0 1 1.244-.851 4.021 4.021 0 0 1 1.618-.309 3.736 3.736 0 0 1 1.5.281 3.427 3.427 0 0 1 1.151.776l-.374.5z"
      ></path>
      <path
        data-name="Path 274"
        d="M170.251 50.737v6.891h.365a.618.618 0 0 0 .262 0 .82.82 0 0 0 .225-.178l2.545-2.721a1.335 1.335 0 0 1 .243-.215.615.615 0 0 1 .318-.075h1.291l-2.966 3.151a1.82 1.82 0 0 1-.225.243.9.9 0 0 1-.243.178 1.509 1.509 0 0 1 .262.224 2.218 2.218 0 0 1 .215.281l3.153 3.974h-1.272a.666.666 0 0 1-.3-.065.856.856 0 0 1-.234-.215l-2.7-3.348a.48.48 0 0 0-.6-.215h-.337v3.843h-1.433V50.737h1.431z"
      ></path>
      <path
        data-name="Path 275"
        d="M182.739 62.443h-.636a.788.788 0 0 1-.327-.065.355.355 0 0 1-.168-.271l-.159-.748c-.215.2-.421.365-.617.514a3.962 3.962 0 0 1-.636.383 4.045 4.045 0 0 1-.72.234 4.123 4.123 0 0 1-.833.084 2.792 2.792 0 0 1-.879-.14 1.9 1.9 0 0 1-.711-.393 1.8 1.8 0 0 1-.486-.664 2.479 2.479 0 0 1-.178-.935 1.832 1.832 0 0 1 .262-.935 2.293 2.293 0 0 1 .851-.785 5.273 5.273 0 0 1 1.525-.57 10.774 10.774 0 0 1 2.32-.215v-.635a2.2 2.2 0 0 0-.4-1.421 1.467 1.467 0 0 0-1.188-.486 2.727 2.727 0 0 0-.87.131 3.718 3.718 0 0 0-.617.29l-.44.3a.784.784 0 0 1-.374.131.471.471 0 0 1-.253-.075.652.652 0 0 1-.168-.187l-.225-.477a4.718 4.718 0 0 1 1.441-.935 4.438 4.438 0 0 1 1.7-.318 3.319 3.319 0 0 1 1.207.215 2.445 2.445 0 0 1 .879.626 2.611 2.611 0 0 1 .543.935 3.952 3.952 0 0 1 .187 1.234l-.028 5.18zm-3.676-.87a2.781 2.781 0 0 0 .683-.075 2.543 2.543 0 0 0 .58-.215 3.194 3.194 0 0 0 .533-.337 5.531 5.531 0 0 0 .486-.449v-1.663a9.909 9.909 0 0 0-1.665.122 5.079 5.079 0 0 0-1.113.327 1.626 1.626 0 0 0-.627.468 1.083 1.083 0 0 0-.187.608 1.408 1.408 0 0 0 .1.552.929.929 0 0 0 .271.383 1.062 1.062 0 0 0 .421.215 1.894 1.894 0 0 0 .515.065z"
      ></path>
      <path
        data-name="Path 276"
        d="M187.462 54.252a3.873 3.873 0 0 1 .982.122 3.053 3.053 0 0 1 .833.337h2.189v.524q0 .266-.337.337l-.935.122a2.378 2.378 0 0 1 .271 1.159 2.433 2.433 0 0 1-.851 1.87 2.9 2.9 0 0 1-.936.524 3.841 3.841 0 0 1-1.216.187 4.071 4.071 0 0 1-1.066-.14 1.391 1.391 0 0 0-.384.355.717.717 0 0 0-.131.365.489.489 0 0 0 .234.449 1.6 1.6 0 0 0 .608.224 5.451 5.451 0 0 0 .87.065h.992a5.2 5.2 0 0 1 .936.084 2.891 2.891 0 0 1 .87.29 1.573 1.573 0 0 1 .608.552 1.642 1.642 0 0 1 .234.935 2.1 2.1 0 0 1-.253 1.01 2.785 2.785 0 0 1-.748.87 3.788 3.788 0 0 1-1.188.617 5.053 5.053 0 0 1-1.581.224 6.563 6.563 0 0 1-1.544-.168 3.871 3.871 0 0 1-1.1-.477 2.145 2.145 0 0 1-.655-.673 1.627 1.627 0 0 1-.215-.813 1.427 1.427 0 0 1 .374-1.01 2.6 2.6 0 0 1 1.038-.664 1.442 1.442 0 0 1-.543-.43 1.077 1.077 0 0 1-.206-.711.921.921 0 0 1 .066-.355 1.1 1.1 0 0 1 .2-.374 1.952 1.952 0 0 1 .767-.636 2.534 2.534 0 0 1-1.272-2.225 2.638 2.638 0 0 1 .225-1.075 2.346 2.346 0 0 1 .636-.813 2.712 2.712 0 0 1 .935-.524 4.052 4.052 0 0 1 1.3-.131zm2.516 8.6a.742.742 0 0 0-.168-.5 1.069 1.069 0 0 0-.458-.29 3.165 3.165 0 0 0-.655-.15 5.513 5.513 0 0 0-.786 0h-.8a3.066 3.066 0 0 1-.823-.112 2.724 2.724 0 0 0-.739.524 1.111 1.111 0 0 0-.281.748.956.956 0 0 0 .14.5 1.182 1.182 0 0 0 .43.4 2.487 2.487 0 0 0 .72.271 4.888 4.888 0 0 0 2.039-.009 2.263 2.263 0 0 0 .767-.3 1.427 1.427 0 0 0 .486-.458 1.149 1.149 0 0 0 .129-.621zm-2.516-4.349a2.3 2.3 0 0 0 .758-.122 1.39 1.39 0 0 0 .889-.842 1.926 1.926 0 0 0 .112-.655 1.569 1.569 0 0 0-.449-1.178 2.148 2.148 0 0 0-2.61 0 1.569 1.569 0 0 0-.449 1.178 1.925 1.925 0 0 0 .112.655 1.608 1.608 0 0 0 .346.514 1.344 1.344 0 0 0 .543.327 2.061 2.061 0 0 0 .748.123z"
      ></path>
      <path
        data-name="Path 277"
        d="M194.542 51.868a.914.914 0 0 1-.084.383 1.06 1.06 0 0 1-.215.318.949.949 0 0 1-.327.215.915.915 0 0 1-.384.084.958.958 0 0 1-.393-.084.913.913 0 0 1-.318-.215 1.061 1.061 0 0 1-.215-.318.914.914 0 0 1-.084-.383 1.077 1.077 0 0 1 .3-.72 1.09 1.09 0 0 1 .318-.224 1.127 1.127 0 0 1 .393-.075 1.077 1.077 0 0 1 .384.075 1.133 1.133 0 0 1 .327.224 1.077 1.077 0 0 1 .3.72zm-.318 2.525v8.046h-1.413v-8.046h1.413z"
      ></path>
      <path
        data-name="Path 278"
        d="M196.42 62.444v-8.051h.842a.349.349 0 0 1 .374.29l.112.879a4.563 4.563 0 0 1 1.179-.935 3.212 3.212 0 0 1 2.666-.14 2.121 2.121 0 0 1 .842.617 2.668 2.668 0 0 1 .505.935 4.061 4.061 0 0 1 .178 1.253v5.124h-1.413v-5.1a2.215 2.215 0 0 0-.421-1.421 1.565 1.565 0 0 0-1.272-.5 2.48 2.48 0 0 0-1.179.3 4.02 4.02 0 0 0-1 .823v5.928z"
      ></path>
      <path
        data-name="Path 279"
        d="M207.842 54.253a3.872 3.872 0 0 1 .982.122 3.053 3.053 0 0 1 .833.337h2.189v.524q0 .266-.337.337l-.935.122a2.489 2.489 0 0 1-.58 3.048 2.9 2.9 0 0 1-.936.524 3.841 3.841 0 0 1-1.216.187 4.071 4.071 0 0 1-1.066-.14 1.391 1.391 0 0 0-.384.355.717.717 0 0 0-.131.365.489.489 0 0 0 .234.449 1.6 1.6 0 0 0 .608.224 5.451 5.451 0 0 0 .87.065h.982a5.833 5.833 0 0 1 .992.084 2.891 2.891 0 0 1 .87.29 1.573 1.573 0 0 1 .608.552 1.642 1.642 0 0 1 .234.935 2.109 2.109 0 0 1-.262 1.01 2.64 2.64 0 0 1-.739.87 3.788 3.788 0 0 1-1.188.617 5.053 5.053 0 0 1-1.581.224 6.563 6.563 0 0 1-1.544-.168 3.871 3.871 0 0 1-1.1-.477 2.144 2.144 0 0 1-.655-.673 1.627 1.627 0 0 1-.215-.814 1.427 1.427 0 0 1 .374-1.01 2.6 2.6 0 0 1 1.038-.664 1.443 1.443 0 0 1-.543-.43 1.077 1.077 0 0 1-.206-.711.921.921 0 0 1 .065-.355 1.1 1.1 0 0 1 .2-.374 1.865 1.865 0 0 1 .318-.346 2.3 2.3 0 0 1 .449-.29 2.534 2.534 0 0 1-1.272-2.225 2.638 2.638 0 0 1 .225-1.075 2.532 2.532 0 0 1 .627-.814 2.9 2.9 0 0 1 .935-.524 3.837 3.837 0 0 1 1.263-.15zm2.516 8.6a.742.742 0 0 0-.168-.5 1.069 1.069 0 0 0-.458-.29 3.164 3.164 0 0 0-.655-.15 5.513 5.513 0 0 0-.786 0h-.84a3.067 3.067 0 0 1-.823-.112 2.587 2.587 0 0 0-.739.524 1.111 1.111 0 0 0-.281.748.956.956 0 0 0 .14.5 1.182 1.182 0 0 0 .43.4 2.725 2.725 0 0 0 .711.271 4.745 4.745 0 0 0 1.029.1 4.667 4.667 0 0 0 1.02-.112 2.263 2.263 0 0 0 .767-.3 1.427 1.427 0 0 0 .486-.458 1.084 1.084 0 0 0 .168-.636zm-2.516-4.35a2.3 2.3 0 0 0 .758-.122 1.39 1.39 0 0 0 .889-.842 1.925 1.925 0 0 0 .112-.655 1.569 1.569 0 0 0-.45-1.173 2.157 2.157 0 0 0-2.61 0 1.569 1.569 0 0 0-.449 1.178 1.925 1.925 0 0 0 .112.655 1.88 1.88 0 0 0 .337.514 1.444 1.444 0 0 0 .552.327 2.061 2.061 0 0 0 .749.118z"
      ></path>
      <path
        data-name="Path 280"
        d="M218.259 58.18v4.264h-1.534V51.055h3.358a6.319 6.319 0 0 1 1.871.252 3.659 3.659 0 0 1 1.328.711 2.913 2.913 0 0 1 .786 1.113 3.85 3.85 0 0 1 .253 1.449 3.891 3.891 0 0 1-.281 1.459 2.974 2.974 0 0 1-.814 1.131 3.812 3.812 0 0 1-1.328.748 5.745 5.745 0 0 1-1.824.262h-1.815zm0-1.225h1.871a3.806 3.806 0 0 0 1.169-.168 2.45 2.45 0 0 0 .842-.486 2.247 2.247 0 0 0 .524-.757 2.666 2.666 0 0 0 .168-.935 2.194 2.194 0 0 0-.674-1.7 2.941 2.941 0 0 0-2.03-.608h-1.871v4.654z"
      ></path>
      <path
        data-name="Path 281"
        d="M225.826 62.443v-8.051h.814a.482.482 0 0 1 .318.084.632.632 0 0 1 .122.309l.094 1.253a3.713 3.713 0 0 1 1.02-1.318 2.324 2.324 0 0 1 1.431-.468 2.146 2.146 0 0 1 .6.075 2.386 2.386 0 0 1 .505.206l-.187 1.057a.242.242 0 0 1-.243.206 1.1 1.1 0 0 1-.346-.084 2.142 2.142 0 0 0-.646-.075 1.861 1.861 0 0 0-1.235.43 3.24 3.24 0 0 0-.833 1.253v5.124h-1.414z"
      ></path>
      <path
        data-name="Path 282"
        d="M235.18 54.262a4.083 4.083 0 0 1 1.562.309 3.367 3.367 0 0 1 1.207.832 3.636 3.636 0 0 1 .767 1.309 5.8 5.8 0 0 1 0 3.432 3.885 3.885 0 0 1-.767 1.3 3.367 3.367 0 0 1-1.207.832 4.253 4.253 0 0 1-1.6.29 4.205 4.205 0 0 1-1.59-.29 3.4 3.4 0 0 1-1.216-.832 3.886 3.886 0 0 1-.767-1.3 5.655 5.655 0 0 1 0-3.432 3.635 3.635 0 0 1 .767-1.309 3.4 3.4 0 0 1 1.216-.832 4.035 4.035 0 0 1 1.628-.309zm0 7.191a2.071 2.071 0 0 0 1.787-.8 3.681 3.681 0 0 0 .589-2.225 3.72 3.72 0 0 0-.589-2.244 2.071 2.071 0 0 0-1.787-.8 2.683 2.683 0 0 0-1.048.206 1.907 1.907 0 0 0-.739.6 2.7 2.7 0 0 0-.449.935 5.556 5.556 0 0 0 0 2.562 2.546 2.546 0 0 0 .449.935 1.907 1.907 0 0 0 .739.6 2.685 2.685 0 0 0 1.048.243z"
      ></path>
      <path
        data-name="Path 283"
        d="M246.452 62.447a.383.383 0 0 1-.384-.29l-.122-.935a4.36 4.36 0 0 1-1.179 1.01 3.1 3.1 0 0 1-1.525.374 2.911 2.911 0 0 1-1.254-.271 2.515 2.515 0 0 1-.935-.776 3.66 3.66 0 0 1-.617-1.29 6.7 6.7 0 0 1-.215-1.777 5.808 5.808 0 0 1 .243-1.655 3.983 3.983 0 0 1 .683-1.328 3.143 3.143 0 0 1 2.563-1.206 3.264 3.264 0 0 1 1.263.243 2.891 2.891 0 0 1 .936.711v-4.52h1.413v11.71zm-2.741-1.038a2.3 2.3 0 0 0 1.216-.318 3.185 3.185 0 0 0 .936-.935v-3.855a2.316 2.316 0 0 0-.851-.739 2.44 2.44 0 0 0-1.029-.206 2.044 2.044 0 0 0-1.74.8 3.713 3.713 0 0 0-.6 2.282 5.435 5.435 0 0 0 .131 1.346 2.6 2.6 0 0 0 .4.935 1.472 1.472 0 0 0 .636.533 2.27 2.27 0 0 0 .9.15z"
      ></path>
      <path
        data-name="Path 284"
        d="M250.738 54.393v5.134a2.071 2.071 0 0 0 .421 1.412 1.565 1.565 0 0 0 1.272.5 2.345 2.345 0 0 0 1.169-.327 3.686 3.686 0 0 0 1.01-.814v-5.905h1.413v8.051h-.842a.363.363 0 0 1-.374-.29l-.112-.87a4.563 4.563 0 0 1-1.179.935 3.074 3.074 0 0 1-1.5.355 2.882 2.882 0 0 1-1.16-.224 2.151 2.151 0 0 1-.851-.617 2.547 2.547 0 0 1-.514-.935 4.292 4.292 0 0 1-.168-1.244v-5.161z"
      ></path>
      <path
        data-name="Path 285"
        d="M263.742 55.824a.866.866 0 0 1-.131.131.288.288 0 0 1-.178.056.458.458 0 0 1-.262-.1 2.841 2.841 0 0 0-.355-.224 3.521 3.521 0 0 0-.515-.215 2.6 2.6 0 0 0-.758-.1 2.491 2.491 0 0 0-1.038.215 2.005 2.005 0 0 0-.758.608 2.725 2.725 0 0 0-.458.935 4.766 4.766 0 0 0-.159 1.262 4.677 4.677 0 0 0 .168 1.3 2.747 2.747 0 0 0 .468.935 2.066 2.066 0 0 0 .739.589 2.276 2.276 0 0 0 .935.206 2.614 2.614 0 0 0 .851-.131 2.33 2.33 0 0 0 .552-.271 3.689 3.689 0 0 0 .365-.271.454.454 0 0 1 .29-.122.28.28 0 0 1 .271.131l.393.514a3.051 3.051 0 0 1-1.31.935 4.811 4.811 0 0 1-1.665.3 3.581 3.581 0 0 1-1.4-.281 3.215 3.215 0 0 1-1.123-.8 3.69 3.69 0 0 1-.748-1.3 5.091 5.091 0 0 1-.281-1.758 5.613 5.613 0 0 1 .253-1.664 3.767 3.767 0 0 1 .73-1.309 3.429 3.429 0 0 1 1.188-.87 4.035 4.035 0 0 1 1.628-.309 3.737 3.737 0 0 1 1.5.281 3.428 3.428 0 0 1 1.151.776l-.337.561z"
      ></path>
      <path
        data-name="Path 286"
        d="M268.586 54.262a3.848 3.848 0 0 1 1.338.243 3 3 0 0 1 1.749 1.833 4.208 4.208 0 0 1 .253 1.524.93.93 0 0 1-.075.449.283.283 0 0 1-.271.112h-5.36a4.288 4.288 0 0 0 .215 1.328 2.71 2.71 0 0 0 .524.935 2.089 2.089 0 0 0 .8.561 2.75 2.75 0 0 0 1.029.187 2.861 2.861 0 0 0 .936-.122 4.5 4.5 0 0 0 .664-.271 4.588 4.588 0 0 0 .468-.262.583.583 0 0 1 .318-.122.28.28 0 0 1 .271.131l.4.514a2.712 2.712 0 0 1-.627.552 3.858 3.858 0 0 1-.786.393 6.846 6.846 0 0 1-.861.224 5.606 5.606 0 0 1-.889.075 4.288 4.288 0 0 1-1.534-.281 3.4 3.4 0 0 1-1.216-.832 3.593 3.593 0 0 1-.8-1.346 5.741 5.741 0 0 1-.281-1.87 4.733 4.733 0 0 1 .262-1.571 3.692 3.692 0 0 1 .739-1.272 3.531 3.531 0 0 1 1.188-.842 3.835 3.835 0 0 1 1.553-.271zm0 1.047a2.168 2.168 0 0 0-1.609.589 2.789 2.789 0 0 0-.73 1.646h4.378a2.871 2.871 0 0 0-.14-.935 1.818 1.818 0 0 0-.964-1.1 2.41 2.41 0 0 0-.889-.2z"
      ></path>
      <path
        data-name="Path 287"
        d="M273.589 62.443v-8.051h.8a.483.483 0 0 1 .318.084.474.474 0 0 1 .122.309l.094 1.253a3.737 3.737 0 0 1 1.029-1.318 2.3 2.3 0 0 1 1.422-.468 2.212 2.212 0 0 1 .608.075 1.917 1.917 0 0 1 .5.206l-.178 1.057a.252.252 0 0 1-.253.206 1.05 1.05 0 0 1-.337-.084 2.176 2.176 0 0 0-.645-.075 1.861 1.861 0 0 0-1.235.43 3.24 3.24 0 0 0-.833 1.253v5.124h-1.412z"
      ></path>
      <path
        data-name="Path 288"
        d="M284.504 55.721a.335.335 0 0 1-.3.178.447.447 0 0 1-.262-.094l-.374-.2a5.018 5.018 0 0 0-.524-.2 2.605 2.605 0 0 0-.711-.094 2.157 2.157 0 0 0-.645.094 1.608 1.608 0 0 0-.486.252.9.9 0 0 0-.309.365.976.976 0 0 0-.112.458.825.825 0 0 0 .178.524 1.981 1.981 0 0 0 .477.355 4.452 4.452 0 0 0 .664.262l.767.252a7.6 7.6 0 0 1 .767.29 2.7 2.7 0 0 1 .674.393 1.789 1.789 0 0 1 .468.589 1.713 1.713 0 0 1 .178.832 2.755 2.755 0 0 1-.2 1.029 2.3 2.3 0 0 1-.589.814 2.759 2.759 0 0 1-.936.542 4.249 4.249 0 0 1-2.806-.075 3.5 3.5 0 0 1-1.16-.7l.327-.542a.6.6 0 0 1 .15-.159.507.507 0 0 1 .234-.056.492.492 0 0 1 .3.112l.393.252a4.011 4.011 0 0 0 .543.243 2.36 2.36 0 0 0 .8.112 2.163 2.163 0 0 0 .73-.112 1.407 1.407 0 0 0 .514-.29 1.143 1.143 0 0 0 .309-.421 1.342 1.342 0 0 0 .094-.5.848.848 0 0 0-.178-.552 1.3 1.3 0 0 0-.468-.374 3.5 3.5 0 0 0-.674-.271l-.776-.243a7.592 7.592 0 0 1-.767-.29 2.77 2.77 0 0 1-.674-.411 1.741 1.741 0 0 1-.468-.608 1.9 1.9 0 0 1-.178-.879 2.132 2.132 0 0 1 .739-1.627 2.548 2.548 0 0 1 .936-.514 3.385 3.385 0 0 1 1.216-.2 3.949 3.949 0 0 1 1.431.252 3.348 3.348 0 0 1 1.095.692l-.393.514z"
      ></path>
      <path data-name="Path 289" d="M291.687 61.116h4.93v1.3h-6.427V51.055h1.544z"></path>
      <path
        data-name="Path 290"
        d="M300.19 62.574a1.941 1.941 0 0 1-1.469-.533 2.134 2.134 0 0 1-.505-1.534V55.58h-.936a.375.375 0 0 1-.215-.075.331.331 0 0 1-.094-.243v-.561l1.328-.168.309-2.487a.4.4 0 0 1 .1-.2.381.381 0 0 1 .224-.072h.72v2.805h2.3v1.029h-2.3v4.825a1.058 1.058 0 0 0 .243.757.852.852 0 0 0 .636.243 1.312 1.312 0 0 0 .384-.056.964.964 0 0 0 .281-.131l.215-.131a.41.41 0 0 1 .15-.056.227.227 0 0 1 .2.131l.412.673a2.52 2.52 0 0 1-.879.542 3.168 3.168 0 0 1-1.1.168z"
      ></path>
      <path
        data-name="Path 291"
        d="M309.022 62.447a.367.367 0 0 1-.384-.29l-.122-.935a4.113 4.113 0 0 1-1.188 1.01 3.06 3.06 0 0 1-1.516.374 2.923 2.923 0 0 1-1.263-.271 2.515 2.515 0 0 1-.935-.776 3.659 3.659 0 0 1-.617-1.29 6.7 6.7 0 0 1-.215-1.777 5.808 5.808 0 0 1 .243-1.655 3.983 3.983 0 0 1 .683-1.328 3.193 3.193 0 0 1 1.095-.935 3.287 3.287 0 0 1 1.459-.318 2.723 2.723 0 0 1 2.208.935v-4.454h1.362v11.71zm-2.741-1.038a2.288 2.288 0 0 0 1.207-.318 3.184 3.184 0 0 0 .935-.935v-3.855a2.194 2.194 0 0 0-.861-.739 2.44 2.44 0 0 0-1.03-.206 2.017 2.017 0 0 0-1.731.8 3.783 3.783 0 0 0-.608 2.282 5.934 5.934 0 0 0 .14 1.346 2.842 2.842 0 0 0 .393.935 1.626 1.626 0 0 0 .645.533 2.157 2.157 0 0 0 .907.15z"
      ></path>
    </g>
  </svg>
);
