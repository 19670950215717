import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MaterialType, WoodRecyclingMethod } from '../../types/materialTypes';
import { PalletType } from '../../components/form/volume/volumeFormTypes';
import { API_URL } from '../../common/constants';

export type CreateVolumeType = {
  rows: Record<string, any>[];
};

interface Response<T> {
  message: string;
  error: boolean;
  body: T;
}

export interface UserData {
  name: string;
  partnerName: string;
  materials: MaterialType[];
  facilities: string[];
  hasTerminals: boolean;
  hasRecycling: boolean;
}

interface FormDataRow {
  facility: string;
  weight: number;
  itemType?: PalletType;
  amount?: number;
  recyclingMethod?: WoodRecyclingMethod;
}

export interface VolumeFormData {
  startDate: Date;
  endDate: Date;
  material: MaterialType;
  rows: FormDataRow[];
}

interface ItemWeight {
  name: PalletType;
  weight?: number;
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getUser: builder.query<Response<UserData>, void>({
      query: () => ({
        url: 'userData',
      }),
    }),
    getRecyclingMethods: builder.mutation<Response<WoodRecyclingMethod[]>, MaterialType>({
      query: (material: MaterialType) => ({
        url: 'recyclingMethods',
        method: 'GET',
        params: { material },
      }),
    }),
    getItemWeights: builder.mutation<Response<ItemWeight[]>, MaterialType>({
      query: (material: MaterialType) => ({
        url: 'itemWeights',
        params: { material },
      }),
    }),
    sendVolumeData: builder.mutation<Response<null>, FormData>({
      query: (formData: FormData) => ({
        url: 'importVolumeData',
        method: 'POST',
        body: formData,
      }),
    }),
    sendRecyclingData: builder.mutation<Response<null>, FormData>({
      query: (formData: FormData) => ({
        url: 'importRecyclingData',
        method: 'POST',
        body: formData,
      }),
    }),
    sendFormData: builder.mutation<Response<null>, VolumeFormData>({
      query: (recyclingData: VolumeFormData) => ({
        url: 'importFormData',
        method: 'POST',
        body: recyclingData,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetRecyclingMethodsMutation,
  useGetItemWeightsMutation,
  useSendVolumeDataMutation,
  useSendRecyclingDataMutation,
  useSendFormDataMutation,
} = userApi;
