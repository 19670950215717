import React from 'react';
import { styled } from '@mui/system';
import { FormControl, FormGroup } from '@mui/material';
import { Nullable } from '../../types/commonTypes';
import useDesktopMedia from '../../hooks/useDesktopMedia';

interface FormFieldProps {
  children: JSX.Element;
  sx?: any;
  gutterLeft?: boolean;
}

export const CustomizedFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'gutterLeft',
})<{ gutterLeft?: Nullable<boolean> }>(({ theme, gutterLeft }) => ({
  paddingLeft: theme.spacing(gutterLeft ? 3 : 0),
}));

export const FormField = ({ children, sx, gutterLeft }: FormFieldProps) => {
  const isDesktop = useDesktopMedia();
  return (
    <CustomizedFormControl sx={sx} gutterLeft={isDesktop && gutterLeft}>
      <FormGroup sx={{ width: 'min-content' }}>{children}</FormGroup>
    </CustomizedFormControl>
  );
};

export const formFieldVariant = 'standard';
