// These enum values have to match the values in our Powerapp admin
export enum MaterialType {
  CARD = 'Kuitu',
  GLASS = 'Lasi',
  PLASTIC = 'Muovi',
  METAL = 'Metalli',
  WOOD = 'Puu',
}

export enum WoodMaterialType {
  RECYCLING = 'RECYCLING',
  REPAIRING = 'REPAIRING',
}

// Hyötykäyttömenetelmä
export enum WoodRecyclingMethod {
  RECYCLING = 'Kierrätys materiaalina Puu',
  COMPOSTING = 'Kompostointi',
  LANDSCAPING = 'Maisemointi',
  REPAIRING = 'Korjaus',
  ENERGY = 'Energiahyödyntäminen',
}
