import * as yup from 'yup';

export const stringSchemaRequired = (requiredStr: string) =>
  yup.string().nonNullable().required(requiredStr);

export const stringSchemaNullable = yup.string().nullable();

export const optionSchemaRequired = (requiredStr: string) =>
  yup.object().shape({ label: yup.string(), id: yup.string() }).required(requiredStr);

export const dateSchemaRequired = (requiredStr: string) =>
  yup
    .date()
    .nullable()
    .transform((v: any) => (v && !isNaN(v) ? v : null))
    .required(requiredStr);

export const numberSchema = yup
  .number()
  .nullable()
  .transform((v) => (v === '' || Number.isNaN(v) ? null : v));

export const numberSchemaRequired = (requiredStr: string) =>
  yup
    .number()
    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
    .required(requiredStr);

export const positiveNumberSchema = yup
  .number()
  .positive('negativeValue')
  .nullable()
  .transform((v) => (v === '' || Number.isNaN(v) ? null : v));

export const positiveNumberSchemaRequired = (requiredStr: string) =>
  positiveNumberSchema.required(requiredStr);

// Last year allowed only until 1st of July
const currentDate = new Date();
const minYear =
  currentDate.getMonth() < 6 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();

export const yearSchemaRequired = (requiredStr: string) =>
  yup
    .number()
    .min(minYear, 'invalidYear')
    .max(2100, 'invalidYear')
    .nullable()
    .transform((v) => (v === '' || Number.isNaN(v) ? null : v))
    .required(requiredStr);
