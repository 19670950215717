import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import Frontpage from '../components/Frontpage';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import NotFound from '../components/NotFound';
import { RouteConfig } from './routeConfig';
import { setUserLoggedIn, setUserNotLoggedIn } from '../app/services/userSlice';
import { useAppDispatch } from '../app/hooks';
import { useGetUserQuery } from '../app/services/user';

const Router = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data, isLoading, error } = useGetUserQuery();

  /*
   * Set user data if user is proven to have a valid session on the backend, otherwise log out.
   *
   * Redirection to login page is done in <Layout> component purposefully, to keep a clean render
   * cycle, handle redirection of different pages differently and avoid 1-frame flash of whatever
   * underlying page we dropped into before loading spinner was shown.
   *
   */
  useEffect(() => {
    if (isLoading) {
      return;
    }
    const userData = data?.body;
    if (userData && !data.error) {
      dispatch(setUserLoggedIn(userData));
    } else if (error && 'status' in error && error.status === 401) {
      // Have to do a somewhat elaborate check due to RTK Query oddness with different error types
      dispatch(setUserNotLoggedIn);
    } else {
      // TODO: This unknown error case should be somehow differentiated from 401.
      dispatch(setUserNotLoggedIn);
    }
  }, [isLoading, data, error]);

  if (isLoading) {
    return (
      <Layout isLoginPage noBg>
        <Loading />
      </Layout>
    );
  }

  return (
    <Routes>
      {Object.values(RouteConfig.login).map((loginRoute, index: number) => (
        <Route
          path={`:lang?${loginRoute.href}`}
          key={`login-route-${index}`}
          element={
            <Layout isLoginPage noBg>
              {loginRoute.element}
            </Layout>
          }
        />
      ))}

      <Route
        path=":lang?"
        element={
          <Layout noBg>
            <Frontpage />
          </Layout>
        }
      />

      {Object.values(RouteConfig.dataInput).map((dataInputRoute, index: number) => (
        <Route
          path={`:lang?${dataInputRoute.href}`}
          key={`login-route-${index}`}
          element={<Layout title={t(dataInputRoute.header)}>{dataInputRoute.element}</Layout>}
        />
      ))}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
