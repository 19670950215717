import { Error, Logout } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Button,
  CircularProgress,
  Grid,
  Link as MaterialLink,
  Stack,
  useTheme,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import axios from 'axios';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectUserData,
  selectUserLoggedIn,
  setUserNotLoggedIn,
} from '../app/services/userSlice';
import { sptLogo } from '../common/svg/sptLogo';
import { CustomizedLink } from '../elements/CustomElements';
import useDesktopMedia from '../hooks/useDesktopMedia';
import { DataInput, RouteConfig, RouteType } from '../route/routeConfig';
import LanguageSelector from './LanguageSelector';
import { API_URL, isProd } from '../common/constants';
import { useTranslation } from 'react-i18next';

axios.defaults.withCredentials = true;

const CustomizedAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  'p, a, svg': {
    color: `${theme.palette.black.main} !important`,
  },
  'p, a': {
    fontFamily: "'Work Sans',sans-serif !important",
    fontSize: '.9rem !important',
    lineHeight: '.9rem !important',
    textDecoration: 'none !important',
  },
  backgroundColor: 'white',
  svg: {
    fontSize: '1rem',
  },
  '.spt-logo': {
    maxHeight: '3.5rem',
    width: 'auto',
  },
  boxShadow: '0 0 4px 0 rgba(0,0,0,.16)',
}));

const TestingTools = styled(Grid)(() => ({
  width: 'auto',
  p: {
    fontSize: '0.8rem!important',
    color: '#5E7590!important',
  },
  '.error-svg': {
    color: 'red!important',
  },
}));

const DesktopMenuWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  marginLeft: theme.spacing(3),
}));

const MobileMenuWrapper = styled(Box)(() => ({
  display: 'flex',
}));

const MenuContentWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
}));

const CustomizedContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const pages = R.map<DataInput, RouteType>(
  R.prop(R.__, RouteConfig.dataInput),
  R.values(DataInput),
);

const Header = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userLoggedIn = useAppSelector(selectUserLoggedIn);
  const userData = useAppSelector(selectUserData);
  const { lang } = useParams();
  const isDesktop = useDesktopMedia();
  const [anchorElMobileNav, setAnchorElMobileNav] = React.useState<null | HTMLElement>(null);
  const [testingToolsState, setTestingToolsState] = useState<any>({});
  const { t } = useTranslation();

  const handleOpenMobileNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMobileNav(event.currentTarget);
  };
  const handleCloseMobileNavMenu = () => {
    setAnchorElMobileNav(null);
  };

  useEffect(() => {
    if (anchorElMobileNav) {
      setAnchorElMobileNav(null);
    }
  }, [isDesktop]);

  const menuItems = (Component: any, isMobile?: boolean) =>
    R.map(
      (page) => (
        <Component
          key={page.href}
          component={CustomizedLink}
          to={!lang || lang === 'fi' ? `${page.href}` : `/${lang}${page.href}`}
          onClick={() => (isMobile ? handleCloseMobileNavMenu() : {})}
        >
          {t(page.title)}
        </Component>
      ),
      pages,
    );

  const mobileMenu = (
    <MobileMenuWrapper>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenMobileNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElMobileNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElMobileNav)}
        onClose={handleCloseMobileNavMenu}
        sx={{
          display: 'block',
        }}
      >
        {menuItems(MenuItem, true)}
      </Menu>
    </MobileMenuWrapper>
  );

  const desktopMenu = (
    <DesktopMenuWrapper display="flex">{menuItems(MaterialLink)}</DesktopMenuWrapper>
  );

  const menuContent = !userLoggedIn ? (
    <>
      <LanguageSelector />
    </>
  ) : (
    <>
      {isDesktop && desktopMenu}
      {!isDesktop && mobileMenu}
      {!isProd && (
        <TestingTools container flexDirection="column" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={() => {
                setTestingToolsState((state: any) => ({
                  ...state,
                  toteumaLoading: true,
                }));
                axios
                  .get(`${API_URL}/testing?command=updateToteuma`)
                  .catch(() =>
                    setTestingToolsState((state: any) => ({
                      ...state,
                      toteumaError: true,
                    })),
                  )
                  .finally(() =>
                    setTestingToolsState((state: any) => ({
                      ...state,
                      toteumaLoading: false,
                    })),
                  );
              }}
            >
              {testingToolsState.toteumaLoading && (
                <CircularProgress
                  sx={{ mr: 2, svg: { color: 'white!important' } }}
                  size={15}
                />
              )}
              {testingToolsState.toteumaError && (
                <Error className="error-svg" sx={{ mr: 2 }} />
              )}
              Toteuma
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={() => {
                setTestingToolsState((state: any) => ({
                  ...state,
                  arvioLoading: true,
                }));
                axios
                  .get(`${API_URL}/testing?command=updateArvio`)
                  .catch(() =>
                    setTestingToolsState((state: any) => ({
                      ...state,
                      arvioError: true,
                    })),
                  )
                  .finally(() =>
                    setTestingToolsState((state: any) => ({
                      ...state,
                      arvioLoading: false,
                    })),
                  );
              }}
            >
              {testingToolsState.arvioLoading && (
                <CircularProgress
                  sx={{ mr: 2, svg: { color: 'white!important' } }}
                  size={15}
                />
              )}
              {testingToolsState.arvioError && <Error className="error-svg" sx={{ mr: 2 }} />}
              Arvio
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              position: 'absolute',
              bottom: '-10px',
            }}
          >
            <Typography sx={{}} variant="body1">
              Vain testaukseen
            </Typography>
          </Grid>
        </TestingTools>
      )}

      <LanguageSelector />
      {userData && (
        <Stack mr={theme.spacing(1)}>
          <Typography mb={theme.spacing(0.5)}>{userData.name}</Typography>
          <Typography variant="body2">{userData.partnerName}</Typography>
        </Stack>
      )}
      <IconButton
        size="large"
        onClick={() => dispatch(setUserNotLoggedIn())}
        href={`${API_URL}/logout`}
      >
        <Logout />
      </IconButton>
    </>
  );

  const urlLang = !lang || lang === 'fi' ? '' : `${lang}`;
  const sptLogoLinkUrl = userLoggedIn ? `/${urlLang}` : `/${urlLang}login`;

  return (
    <CustomizedAppBar>
      <CustomizedContainer maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <MaterialLink component={RouterLink} to={sptLogoLinkUrl}>
            {sptLogo}
          </MaterialLink>
          <MenuContentWrapper justifyContent={!userLoggedIn ? 'flex-end' : 'space-around'}>
            {menuContent}
          </MenuContentWrapper>
        </Toolbar>
      </CustomizedContainer>
    </CustomizedAppBar>
  );
};
export default Header;
