import { DynamicFormOutlined } from '@mui/icons-material';
import { Typography, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { excelIcon } from '../common/svg/commonIcons';
import { RouteConfig } from '../route/routeConfig';
import ActionCard from './ActionCard';

const Frontpage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h3"
        sx={(theme) => ({ textAlign: 'center', marginBottom: theme.spacing(4) })}
      >
        {t('frontpage.title')}
      </Typography>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={6}>
        <Grid item>
          <ActionCard
            icon={<DynamicFormOutlined />}
            title={t('frontpage.form')}
            color="primary"
            href={RouteConfig.dataInput.FORM.href}
            disabled={false}
            minWidth="14rem"
          />
        </Grid>
        <Grid item>
          <ActionCard
            icon={excelIcon}
            title={t('frontpage.excel')}
            color="primary"
            href={RouteConfig.dataInput.EXCEL.href}
            disabled={false}
            minWidth="14rem"
          />
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        sx={(theme) => ({ textAlign: 'center', marginTop: theme.spacing(5) })}
      >
        <Link
          href="https://portaali.blob.core.windows.net/ohjeet/Käyttäjäohjeet.pdf"
          target="_blank"
        >
          {t('frontpage.instructions')}
        </Link>
      </Typography>
    </>
  );
};
export default Frontpage;
