import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../../common/constants';
import { Nullable } from '../../../types/commonTypes';
import { FormField } from '../../../types/formTypes';
import { formFieldVariant } from '../formUtil';

const DatePickerField = ({ name, label, control, disabled, required }: FormField) => {
  const { t } = useTranslation();
  const translateErrorMsg = (errorMsg: Nullable<string>) =>
    errorMsg ? t(`errorMessages.${errorMsg}`) : ' ';
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <>
          <DatePicker
            {...field}
            onChange={field.onChange}
            format={DATE_FORMAT}
            disabled={disabled}
            label={label}
            slotProps={{
              textField: {
                variant: formFieldVariant,
                required,
                error: Boolean(error),
                helperText: error ? translateErrorMsg(error.message) : ' ',
              },
            }}
          />
        </>
      )}
    />
  );
};

export default DatePickerField;
