import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './../../app/store';
import { UserData } from './user';

interface UserState {
  data: UserData | null;
}

const initialState: UserState = { data: null };

export const userSliceName = 'user';

const userSlice = createSlice({
  name: userSliceName,
  initialState,
  reducers: {
    setUserLoggedIn(state, action: PayloadAction<UserData>) {
      state.data = action.payload;
    },
    setUserNotLoggedIn(state) {
      state.data = null;
    },
  },
});

export const { setUserLoggedIn, setUserNotLoggedIn } = userSlice.actions;

export const selectUserLoggedIn = (state: RootState): boolean =>
  Boolean(state[userSliceName].data);

export const selectUserData = (state: RootState): UserData | null => state[userSliceName].data;

export default userSlice.reducer;
